var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "v-img",
        {
          staticClass: "brighten-v-img private ",
          attrs: {
            src: require("../assets/home_1280.webp"),
            srcset:
              require("../assets/home_1280.webp") +
              ", " +
              require("../assets/home_900.webp") +
              " 900w, " +
              require("../assets/home_400.webp") +
              " 400w"
          }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    "border-radius": "40px",
                    "background-color": "#00000080"
                  },
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      attrs: { "align-content": "center", justify: "center" }
                    },
                    [_c("div", { staticClass: "col" })]
                  ),
                  _c("div", { staticClass: "text-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: " white--text",
                        staticStyle: { visibility: "visible" }
                      },
                      [
                        _c("h1", {}, [
                          _vm._v(
                            " Are you a talented private chef starting out or looking to expand your business and reach more clients? "
                          )
                        ]),
                        _c("h3", {}, [
                          _vm._v(
                            " Our private chef platform offers a range of features and advantages that can help you take your business to the next level. "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "margin-top-bottom-big",
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        "!click": function($event) {
                          return _vm.openSignup(true)
                        }
                      }
                    },
                    [_vm._v("Signup as a chef")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        [
          _c(
            "v-container",
            {
              staticClass: "container margin-top-bottom-big",
              attrs: { fuild: "" }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "primary--text text-h5",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("div", { attrs: { dark: "" } }, [
                    _c("h3", { staticClass: "secondary-color-text" }, [
                      _vm._v(
                        "Bonus up to $200 for the first caterers to register *"
                      )
                    ]),
                    _c("br")
                  ])
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "primary--text text-h5",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [_c("div", [_c("h2", [_vm._v("Key features")]), _c("br")])]
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-h6 margin-top-bottom-big ",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-col", { staticClass: "col-md-6 col-12" }, [
                    _c("h2", { staticClass: "primary--text margin-bottom" }, [
                      _vm._v("Be you own boss !")
                    ]),
                    _c("div", { staticClass: "text-h6" }, [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "No price restrictions, you set your own rates and your minimum order price."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "No upfront or running costs, we simply take a commission on top of your rates."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "You create your own schedule and work when it suits you."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Manage your own business, with the support of our platform and resources."
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: " col-md-6 col-12",
                      staticStyle: { "align-self": "center" }
                    },
                    [
                      _c("v-img", {
                        staticClass: "round-corner",
                        staticStyle: { margin: "auto" },
                        attrs: {
                          height: "300px",
                          "max-width": "500px",
                          src: require("../assets/kootto_private_chef_plate_900.webp"),
                          srcset:
                            require("../assets/kootto_private_chef_plate_900.webp") +
                            ", " +
                            require("../assets/kootto_private_chef_plate_400.webp") +
                            " 500w"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "text-h6 margin-top-bottom-big flex-md-row-reverse",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-col", { staticClass: "col-md-6 col-12" }, [
                    _c("h2", { staticClass: "primary--text margin-bottom" }, [
                      _vm._v("Do what you love !")
                    ]),
                    _c("div", { staticClass: "text-h6" }, [
                      _c("ul", [
                        _c("li", [
                          _vm._v("You have complete control over your menus.")
                        ]),
                        _c("li", [
                          _vm._v(
                            "Express your creativity and culinary talents by creating unique and personalized menus."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Build relationships with clients and become a trusted chef for their culinary needs."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Gain personal satisfaction and fulfillment from sharing your passion for food with others."
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "col-md-6 col-12",
                      staticStyle: { "align-self": "center" }
                    },
                    [
                      _c("v-img", {
                        staticClass: "round-corner",
                        staticStyle: { margin: "auto" },
                        attrs: {
                          height: "300px",
                          "max-width": "500px",
                          src: require("../assets/creation_900.webp"),
                          srcset:
                            require("../assets/creation_900.webp") +
                            ", " +
                            require("../assets/creation_400.webp") +
                            " 500w"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-h6 margin-top-bottom-big ",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-col", { staticClass: "col-md-6 col-12" }, [
                    _c("h2", { staticClass: "primary--text margin-bottom" }, [
                      _vm._v("Enjoy the benefits !")
                    ]),
                    _c("div", { staticClass: "text-h6" }, [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "Clients can easily book and pay for your services."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Enjoy the convenience of using our platform to manage your bookings, scheduling, and payments."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Streamlines communication between clients and chefs, minimizing the need for multiple exchanges."
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Receive online exposure to a wide audience of potential clients."
                          )
                        ]),
                        _c("li", [_vm._v("Presence on social medias")]),
                        _c("li", [
                          _vm._v("Presence on google maps (coming soon)")
                        ]),
                        _c("li", [_vm._v("Share your public profile")])
                      ])
                    ])
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "col-md-6 col-12",
                      staticStyle: { "align-self": "center" }
                    },
                    [
                      _c("v-img", {
                        staticClass: "round-corner",
                        staticStyle: { margin: "auto" },
                        attrs: {
                          height: "300px",
                          "max-width": "500px",
                          src: require("../assets/table_900.webp"),
                          srcset:
                            require("../assets/table_900.webp") +
                            ", " +
                            require("../assets/table_400.webp") +
                            " 500w"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "round-corner",
                  staticStyle: { "background-color": "black" },
                  attrs: { dark: "" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "secondary-color-text text-h5 padding-top-bottom-default ",
                      attrs: {
                        "align-content": "center",
                        justify: "space-around"
                      }
                    },
                    [_c("div", [_c("h2", [_vm._v("How it works")])])]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "margin-top-bottom-big",
                      attrs: {
                        "align-content": "center",
                        justify: "space-around"
                      }
                    },
                    [
                      _c(
                        "v-timeline",
                        {
                          staticClass: "margin-default",
                          attrs: { dark: "", dense: _vm.$vuetify.breakpoint.xs }
                        },
                        [
                          _c(
                            "v-timeline-item",
                            {
                              attrs: {
                                color: "dark_primary",
                                icon: _vm.$vuetify.breakpoint.xs
                                  ? "mdi-numeric-1"
                                  : "",
                                small: !_vm.$vuetify.breakpoint.xs
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "opposite" },
                                  slot: "opposite"
                                },
                                [
                                  _c("b", { staticClass: "primary--text" }, [
                                    _vm._v("Step 1")
                                  ])
                                ]
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "elevation-2  padding-left-right-default"
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { color: "white" } },
                                    [
                                      _vm._v(
                                        " Complete your profile (Documents, Menu, Photos ...) "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-timeline-item",
                            {
                              attrs: {
                                color: "dark_primary",
                                icon: _vm.$vuetify.breakpoint.xs
                                  ? "mdi-numeric-2"
                                  : "",
                                small: !_vm.$vuetify.breakpoint.xs
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "opposite" },
                                  slot: "opposite"
                                },
                                [
                                  _c("b", { staticClass: "primary--text" }, [
                                    _vm._v("Step 2")
                                  ])
                                ]
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "elevation-2 padding-left-right-default"
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { color: "white" } },
                                    [
                                      _vm._v(
                                        " Once approved, set your availability, and wait for bookings "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-timeline-item",
                            {
                              attrs: {
                                color: "dark_primary",
                                icon: _vm.$vuetify.breakpoint.xs
                                  ? "mdi-numeric-3"
                                  : "",
                                small: !_vm.$vuetify.breakpoint.xs
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "opposite" },
                                  slot: "opposite"
                                },
                                [
                                  _c("b", { staticClass: "primary--text" }, [
                                    _vm._v("Step 3")
                                  ])
                                ]
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "elevation-2  padding-left-right-default"
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { color: "white" } },
                                    [
                                      _vm._v(
                                        " Accept or reject a booking within 48h "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-timeline-item",
                            {
                              attrs: {
                                color: "dark_primary",
                                icon: _vm.$vuetify.breakpoint.xs
                                  ? "mdi-numeric-4"
                                  : "",
                                small: !_vm.$vuetify.breakpoint.xs
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "opposite" },
                                  slot: "opposite"
                                },
                                [
                                  _c("b", { staticClass: "primary--text" }, [
                                    _vm._v("Step 4")
                                  ])
                                ]
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "elevation-2  padding-left-right-default",
                                  attrs: { dark: "" }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { color: "white" } },
                                    [
                                      _vm._v(
                                        " Provide the service and you will get paid automatically "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-h6 margin-default",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _vm._v(
                    " With our platform, you'll enjoy no price restrictions, complete control over your menu, and the ability to set your own minimum order. Plus, with flexible availability options, you can create your own schedule and work when it suits you. You can also enjoy increased exposure and build your reputation as a top-tier private chef, as well as convenient booking and payment options for clients. "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    " Imagine being able to attract more clients and increase your revenue while showcasing your talent and unique culinary style. By joining our private chef platform, you can achieve all this and more. "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    " Don't miss out on this opportunity to grow your private chef business and achieve greater success. Join our platform today and start enjoying the benefits of increased exposure, and convenient booking options. We don't require any upfront costs, and simply take a small commission on top of your rates when you get bookings through our platform "
                  ),
                  _c("br"),
                  _c("br")
                ]
              ),
              _c(
                "v-row",
                { attrs: { "align-content": "center", justify: "center" } },
                [
                  _c("br"),
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", href: "/search", target: "" },
                      on: {
                        "!click": function($event) {
                          return _vm.openSignup(true)
                        }
                      }
                    },
                    [_vm._v("Signup as a chef")]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "caption margin-left-right-default",
                  attrs: { "align-content": "start" }
                },
                [
                  _vm._v(
                    " We're here to support you every step of the way, so if you have any questions or need assistance with your application, please don't hesitate to contact us. "
                  )
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "caption margin-left-right-default",
                  attrs: { "align-content": "start", justify: "" }
                },
                [
                  _c("div", [
                    _vm._v("(*) Please see the details on our "),
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-none",
                        attrs: { href: "/terms" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$router.push({ path: "/terms" })
                          }
                        }
                      },
                      [_vm._v("Terms and Conditions")]
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }