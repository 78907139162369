var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.rejectDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "500px" },
              model: {
                value: _vm.rejectDialog,
                callback: function($$v) {
                  _vm.rejectDialog = $$v
                },
                expression: "rejectDialog"
              }
            },
            [
              _c(
                "v-form",
                {
                  ref: "formReject",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.validReject,
                    callback: function($$v) {
                      _vm.validReject = $$v
                    },
                    expression: "validReject"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v(
                            "Are you sure you want to " +
                              _vm._s(
                                _vm.rejectMode !== "reject"
                                  ? "cancel"
                                  : "reject"
                              ) +
                              " this booking? "
                          )
                        ])
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-textarea", {
                            staticClass: "margin-top-default",
                            attrs: {
                              id: "reason",
                              label: "Reason of the cancellation",
                              hint: "Please describe the reason",
                              placeholder: "Please describe the reason",
                              rules: [
                                function(v) {
                                  return (
                                    (!!v && v.length !== 0) ||
                                    "You must enter a reason !"
                                  )
                                },
                                function(v) {
                                  return (
                                    (!!v && v.length >= 25) ||
                                    "Min 25 characters"
                                  )
                                }
                              ],
                              required: "",
                              maxlength: 255
                            },
                            model: {
                              value: _vm.reason,
                              callback: function($$v) {
                                _vm.reason = $$v
                              },
                              expression: "reason"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "margin-left-right-default",
                              attrs: { id: "btn_no", outlined: "" },
                              on: {
                                click: function($event) {
                                  _vm.rejectDialog = false
                                }
                              }
                            },
                            [_vm._v("No")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "margin-right-default",
                              attrs: {
                                id: "btn_yes",
                                outlined: "",
                                disabled: !_vm.validReject,
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.validateReject() != true
                                    ? null
                                    : _vm.rejectAction()
                                }
                              }
                            },
                            [_vm._v("Yes")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("h2", [_vm._v("Bookings")]),
      _c(
        "v-row",
        {
          staticClass: "no-margin-top-bottom",
          staticStyle: { "align-items": "baseline" }
        },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "no-margin-top-bottom",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  "hide-details": "",
                  dense: ""
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchBookings($event)
                  }
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _vm.$store.state.bookingStatus
                ? _c("v-select", {
                    staticClass: "no-margin-top-bottom",
                    attrs: {
                      items: _vm.$store.state.bookingStatus,
                      "item-text": "displayName",
                      "return-object": true,
                      label: "Status",
                      dense: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "margin-left-right-default",
              attrs: { align: "center", outlined: "" },
              on: { click: _vm.searchBookings }
            },
            [_vm._v("Search")]
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 pointer",
        attrs: {
          "multi-sort": true,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.bookings,
          "hide-default-footer": false,
          expanded: _vm.expanded,
          "item-key": "id",
          "show-expand": false,
          "server-items-length": _vm.totalItems,
          options: _vm.options,
          "footer-props": {
            "items-per-page-options": [1, 2, 3, 10, 20, 30, 40, 50]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          },
          "click:row": _vm.openBooking
        },
        scopedSlots: _vm._u([
          {
            key: "item.status.displayName",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "pointer",
                    attrs: { color: _vm.getColor(item.status) },
                    on: { "click:row": _vm.openBooking }
                  },
                  [_vm._v(" " + _vm._s(item.status.displayName) + " ")]
                )
              ]
            }
          },
          {
            key: "item.totalWithCommission",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.roundToXDigits(item.totalWithCommission, 2)) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.total",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(_vm.roundToXDigits(item.total, 2)) + " ")
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-col",
                  { staticStyle: { padding: "0px" } },
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" } },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openBooking(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-eye-outline ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      { attrs: { icon: "" } },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteBooking(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-delete-outline ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("div", { staticClass: "text--secondary" }, [
        _vm._v("Click on a row to see the details of the booking")
      ]),
      _c(
        "div",
        { staticClass: "margin-top-default booking" },
        [
          _vm.booking &&
          (_vm.booking.status.name === "REQUESTED" ||
            _vm.booking.status.name === "DATE_REQUESTED") &&
          !_vm.$router.currentRoute.path.includes("/account")
            ? _c(
                "v-row",
                { staticClass: "margin-top-bottom" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "col margin-left-right-default",
                      attrs: {
                        id: "btn_accept",
                        align: "center",
                        color: "primary",
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.acceptBooking()
                        }
                      }
                    },
                    [_vm._v("Accept")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col margin-left-right-default",
                      attrs: {
                        id: "btn_reject",
                        align: "center",
                        color: "error",
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          _vm.rejectDialog = true
                          _vm.rejectMode = "reject"
                          _vm.rejectAction = _vm.rejectBooking
                        }
                      }
                    },
                    [
                      _vm._v(
                        "Reject (Auto in " +
                          _vm._s(_vm.hours) +
                          ":" +
                          _vm._s(_vm.minutes) +
                          ":" +
                          _vm._s(_vm.seconds) +
                          ") "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isLoadingBooking
            ? _c("v-progress-linear", {
                attrs: { indeterminate: "", height: "6" }
              })
            : _vm._e(),
          _vm.booking && !_vm.isLoadingBooking
            ? _c(
                "v-container",
                { attrs: { id: "booking_details" } },
                [
                  _c("v-row", [
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-md-7" },
                      [
                        _c("v-card", [
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    "font-size": "medium",
                                    "font-weight": "700",
                                    padding: "12px",
                                    "border-radius": "4px",
                                    "background-color": "#f7f3f3"
                                  }
                                },
                                [
                                  _vm._v(
                                    " Your Booking with " +
                                      _vm._s(
                                        _vm.isAdmin || _vm.isChef
                                          ? _vm.booking.clientName
                                          : _vm.booking.chefProfileName
                                      )
                                  )
                                ]
                              ),
                              _c("v-col", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { display: "flex" },
                                    attrs: { id: "booking_reference" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "",
                                        dense: "",
                                        value: _vm.booking.reference,
                                        label: "Reference",
                                        "prepend-icon": "mdi-receipt",
                                        readonly: ""
                                      }
                                    }),
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          id: "booking_status",
                                          color: _vm.getColor(
                                            _vm.booking.status
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.booking.status.displayName
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      value: _vm.booking.clientName,
                                      label: "Name",
                                      "prepend-icon": "mdi-account",
                                      readonly: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      readonly: "",
                                      "hide-details": "",
                                      dense: "",
                                      value: _vm.booking.clientPhone,
                                      label: "Phone",
                                      "prepend-icon": "mdi-phone",
                                      rules: [
                                        function(v) {
                                          return !!v || "Phone is required"
                                        }
                                      ]
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      "no-filter": true,
                                      readonly: "",
                                      "hide-details":
                                        !_vm.isChef && !_vm.isAdmin,
                                      items: _vm.locationItems,
                                      loading: _vm.loadingAddress,
                                      "search-input": _vm.lookupAddress,
                                      "item-text": "place_name",
                                      label: "Location",
                                      placeholder: "Start typing your address",
                                      "prepend-icon": "mdi-map-marker",
                                      "append-icon": "",
                                      "return-object": "",
                                      "auto-select-first": "",
                                      "hide-no-data": "",
                                      dense: "",
                                      messages: _vm.distanceMessage
                                    },
                                    on: {
                                      "update:searchInput": function($event) {
                                        _vm.lookupAddress = $event
                                      },
                                      "update:search-input": function($event) {
                                        _vm.lookupAddress = $event
                                      },
                                      change: _vm.getLocation
                                    },
                                    model: {
                                      value: _vm.booking.location,
                                      callback: function($$v) {
                                        _vm.$set(_vm.booking, "location", $$v)
                                      },
                                      expression: "booking.location"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      label: "Date",
                                      "prepend-icon": "mdi-calendar-range",
                                      readonly: "",
                                      rules: [
                                        function(v) {
                                          return !!v || "Date is required"
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.booking.date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.booking, "date", $$v)
                                      },
                                      expression: "booking.date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      readonly: "",
                                      "hide-details": "",
                                      dense: "",
                                      "prepend-icon": "mdi-clock",
                                      items: _vm.availableTimes,
                                      label: "Time",
                                      rules: [
                                        function(v) {
                                          return !!v || "Time is required"
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.booking.time,
                                      callback: function($$v) {
                                        _vm.$set(_vm.booking, "time", $$v)
                                      },
                                      expression: "booking.time"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      readonly: "",
                                      "hide-details": "",
                                      dense: "",
                                      "prepend-icon": "mdi-account-multiple",
                                      label: "Guests",
                                      type: "number",
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v ||
                                            "Number of guests is required"
                                          )
                                        },
                                        function(v) {
                                          return (
                                            _vm.booking.serviceSerialized
                                              .minGuests === undefined ||
                                            v >=
                                              _vm.booking.serviceSerialized
                                                .minGuests ||
                                            "Minimum required is " +
                                              _vm.booking.serviceSerialized
                                                .minGuests
                                          )
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.booking.nbGuests,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.booking,
                                          "nbGuests",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "booking.nbGuests"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.showNbKid
                                ? _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          "prepend-icon":
                                            "mdi-account-multiple",
                                          label: "Kids",
                                          type: "number",
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v ||
                                                "Number of guests is required"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.booking.nbKids,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.booking,
                                              "nbKids",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "booking.nbKids"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      "hide-details": "",
                                      readonly: "",
                                      "prepend-icon": "mdi-comment",
                                      dense: "",
                                      label: "Comment",
                                      hint:
                                        "Tell the chef any allergy requirement or any additional information",
                                      placeholder:
                                        "Tell the chef any allergy requirement or any additional information"
                                    },
                                    model: {
                                      value: _vm.booking.comment,
                                      callback: function($$v) {
                                        _vm.$set(_vm.booking, "comment", $$v)
                                      },
                                      expression: "booking.comment"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _c("v-divider", {
                          staticStyle: {
                            "margin-top": "12px",
                            "margin-bottom": "12px"
                          }
                        }),
                        _c(
                          "v-card",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "medium",
                                  "font-weight": "700",
                                  padding: "12px",
                                  "border-radius": "4px",
                                  "background-color": "#f7f3f3"
                                }
                              },
                              [_vm._v(" Location Details")]
                            ),
                            _c(
                              "v-col",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "primary--text",
                                    staticStyle: { "font-size": "12px" }
                                  },
                                  [
                                    _vm._v(
                                      "Kitchen Equipments (Please select all the Kitchen Equipments available)"
                                    )
                                  ]
                                ),
                                _c(
                                  "v-input",
                                  {
                                    attrs: {
                                      disabled: "",
                                      readonly: "",
                                      rules: [
                                        function(v) {
                                          return (
                                            v.length > 0 ||
                                            "This field is required!"
                                          )
                                        },
                                        this.validateEquipmentsKitchen() ===
                                          undefined ||
                                          this.validateEquipmentsKitchen()
                                      ]
                                    },
                                    model: {
                                      value:
                                        _vm.booking.address.addressDetails
                                          .equipments,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.booking.address.addressDetails,
                                          "equipments",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "booking.address.addressDetails.equipments"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-item-group",
                                      {
                                        attrs: { multiple: "" },
                                        model: {
                                          value:
                                            _vm.booking.address.addressDetails
                                              .equipments,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.booking.address
                                                .addressDetails,
                                              "equipments",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "booking.address.addressDetails.equipments"
                                        }
                                      },
                                      _vm._l(_vm.equipmentsKitchen, function(
                                        tag
                                      ) {
                                        return _c("v-item", {
                                          key: tag.name,
                                          attrs: {
                                            value: tag,
                                            color: "primary"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  var toggle = ref.toggle
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          "active-class":
                                                            "primary--text",
                                                          "input-value": _vm.checkChip(
                                                            _vm.booking.address
                                                              .addressDetails
                                                              .equipments,
                                                            tag
                                                          ),
                                                          filter: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            !toggle
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tag.displayName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "container" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticStyle: {
                                      "align-self": "center",
                                      padding: "0px 12px 0px 12px"
                                    },
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "primary--text",
                                        staticStyle: { "font-size": "12px" }
                                      },
                                      [
                                        _vm._v(
                                          "Cooktop Type (Please select the cooktop type available)"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-input",
                                      {
                                        attrs: {
                                          readonly: "",
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v || "This field is required!"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value:
                                            _vm.booking.address.addressDetails
                                              .cooktopType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.booking.address
                                                .addressDetails,
                                              "cooktopType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "booking.address.addressDetails.cooktopType"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-item-group",
                                          {
                                            attrs: { readonly: "" },
                                            model: {
                                              value:
                                                _vm.booking.address
                                                  .addressDetails.cooktopType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.booking.address
                                                    .addressDetails,
                                                  "cooktopType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "booking.address.addressDetails.cooktopType"
                                            }
                                          },
                                          _vm._l(_vm.hobType, function(tag) {
                                            return _c("v-item", {
                                              key: tag.name,
                                              attrs: {
                                                value: tag,
                                                color: "primary"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var active = ref.active
                                                      var toggle = ref.toggle
                                                      return [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            attrs: {
                                                              "active-class":
                                                                "primary--text",
                                                              "input-value":
                                                                _vm.booking
                                                                  .address
                                                                  .addressDetails
                                                                  .cooktopType !==
                                                                  undefined &&
                                                                tag.name ===
                                                                  _vm.booking
                                                                    .address
                                                                    .addressDetails
                                                                    .cooktopType
                                                                    .name,
                                                              filter: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                !toggle
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  tag.displayName
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "align-self": "center",
                                  padding: "0px 12px 0px 12px"
                                },
                                attrs: { cols: "auto" }
                              },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: {
                                      readonly: "",
                                      color: "primary--text",
                                      row: "",
                                      label: "Is the event at your home ?",
                                      rules: [
                                        function(v) {
                                          return (
                                            v !== undefined ||
                                            "This field is required!"
                                          )
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.booking.hostHouse,
                                      callback: function($$v) {
                                        _vm.$set(_vm.booking, "hostHouse", $$v)
                                      },
                                      expression: "booking.hostHouse"
                                    }
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: { label: "Yes", value: true }
                                    }),
                                    _c("v-radio", {
                                      attrs: { label: "No", value: false }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            !_vm.booking.hostHouse
                              ? _c(
                                  "v-col",
                                  {
                                    staticStyle: {
                                      "align-self": "center",
                                      padding: "0px 12px 0px 12px"
                                    },
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        readonly: "",
                                        items: _vm.$store.state.locationType,
                                        "item-text": "displayName",
                                        "item-value": "name",
                                        attach: "",
                                        chips: "",
                                        label: "Location type",
                                        color: "#00695C",
                                        rules: [
                                          function(v) {
                                            return (
                                              (!!v && v.length !== 0) ||
                                              "You must select one location type!"
                                            )
                                          }
                                        ],
                                        required: "",
                                        "return-object": ""
                                      },
                                      model: {
                                        value:
                                          _vm.booking.address.addressDetails
                                            .locationType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking.address.addressDetails,
                                            "locationType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "booking.address.addressDetails.locationType"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              { staticClass: "container" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticStyle: {
                                      "align-self": "center",
                                      padding: "0px 12px 0px 12px"
                                    },
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: {
                                          readonly: "",
                                          color: "primary--text",
                                          row: "",
                                          label:
                                            "Do you have the following equipments ?",
                                          rules: [
                                            function(v) {
                                              return (
                                                v !== undefined ||
                                                "This field is required!"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value:
                                            _vm.booking
                                              .haveAllServiceEquipments,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.booking,
                                              "haveAllServiceEquipments",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "booking.haveAllServiceEquipments"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "Yes", value: true }
                                        }),
                                        _c("v-radio", {
                                          attrs: { label: "No", value: false }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.getServiceCutlery.equipmentsService,
                                      function(value, keyService) {
                                        return _c(
                                          "ul",
                                          {
                                            key: keyService,
                                            staticClass: "margin-left-default"
                                          },
                                          [
                                            _vm.equipmentsService.find(function(
                                              e
                                            ) {
                                              return e.name === keyService
                                            }) !== undefined
                                              ? _c("li", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(value) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.equipmentsService.find(
                                                          function(e) {
                                                            return (
                                                              e.name ===
                                                              keyService
                                                            )
                                                          }
                                                        ).displayName
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "container" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: {
                                          "align-self": "center",
                                          padding: "0px 12px 0px 12px"
                                        },
                                        attrs: { cols: "auto" }
                                      },
                                      [
                                        _c(
                                          "v-radio-group",
                                          {
                                            attrs: {
                                              readonly: "",
                                              color: "primary--text",
                                              row: "",
                                              label:
                                                "Do you meet the requirements ?",
                                              rules: [
                                                function(v) {
                                                  return (
                                                    v !== undefined ||
                                                    "This field is required!"
                                                  )
                                                }
                                              ]
                                            },
                                            model: {
                                              value:
                                                _vm.booking.hasRequirements,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.booking,
                                                  "hasRequirements",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "booking.hasRequirements"
                                            }
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Yes",
                                                value: true
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "No",
                                                value: false
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: {
                                          "align-self": "center",
                                          padding: "0px 12px 0px 12px"
                                        },
                                        attrs: { cols: "auto" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.booking.requirements) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-divider", {
                          staticStyle: {
                            "margin-top": "12px",
                            "margin-bottom": "12px"
                          }
                        }),
                        _c(
                          "v-card",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "medium",
                                  "font-weight": "700",
                                  padding: "12px",
                                  "border-radius": "4px",
                                  "background-color": "#f7f3f3"
                                }
                              },
                              [_vm._v(" Service Description ")]
                            ),
                            _c("v-col", [
                              _c("div", [
                                _vm._v(
                                  "The chef brings all ingredients, prepares the meal in your kitchen, serves each dish, and does the washing up before leaving"
                                )
                              ]),
                              _vm.getServiceCutlery.description !== undefined
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.getServiceCutlery.description)
                                    )
                                  ])
                                : _vm._e(),
                              _vm.getServiceCutlery.warning !== undefined
                                ? _c("div", { staticClass: "warning--text" }, [
                                    _vm._v(
                                      _vm._s(_vm.getServiceCutlery.warning)
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-md-5" },
                      [
                        _c(
                          "v-card",
                          { attrs: { dark: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { width: "100%", margin: "0px" }
                              },
                              [
                                _c("v-col", {}, [
                                  _c("h2", [_vm._v("Price Details")])
                                ]),
                                _vm.$router.currentRoute.path.includes(
                                  "/dashboard"
                                )
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "col-3 caption",
                                        staticStyle: {
                                          "text-align": "end",
                                          "max-width": "unset"
                                        },
                                        attrs: { "align-self": "center" }
                                      },
                                      [_vm._v(" Net $ ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "col-3 caption",
                                    staticStyle: {
                                      "text-align": "end",
                                      "max-width": "unset"
                                    },
                                    attrs: { "align-self": "center" }
                                  },
                                  [_vm._v(" Total $ ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "background-black-light" },
                              [
                                _vm._l(_vm.filteredItems, function(
                                  item,
                                  indexItem
                                ) {
                                  return _c("div", { key: indexItem }, [
                                    item.itemType.name !== "MINIMUM_REMAINING"
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "row",
                                              staticStyle: {
                                                width: "100%",
                                                margin: "0px"
                                              }
                                            },
                                            [
                                              _vm.$router.currentRoute.path.includes(
                                                "/account"
                                              )
                                                ? _c("v-col", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getCategory(item)
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          item.description
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          item.unitPriceWithComm !=
                                                            undefined
                                                            ? "($" +
                                                                _vm.roundToXDigits(
                                                                  item.unitPriceWithComm,
                                                                  2
                                                                ) +
                                                                ")"
                                                            : ""
                                                        ) +
                                                        " x " +
                                                        _vm._s(item.qty)
                                                    )
                                                  ])
                                                : _c("v-col", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getCategory(item)
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          item.description
                                                        ) +
                                                        " x " +
                                                        _vm._s(item.qty) +
                                                        " "
                                                    )
                                                  ]),
                                              _vm.$router.currentRoute.path.includes(
                                                "/dashboard"
                                              )
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "col-3",
                                                      staticStyle: {
                                                        "text-align": "end",
                                                        "max-width": "unset"
                                                      },
                                                      attrs: {
                                                        "align-self": "center"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.unitPrice *
                                                              item.qty >
                                                              0
                                                              ? _vm.formatPrice(
                                                                  _vm.roundToXDigits(
                                                                    item.unitPrice *
                                                                      item.qty,
                                                                    2
                                                                  )
                                                                )
                                                              : ""
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "col-3",
                                                  staticStyle: {
                                                    "text-align": "end",
                                                    "max-width": "unset"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.unitPrice *
                                                          item.qty >
                                                          0
                                                          ? _vm.formatPrice(
                                                              _vm.roundToXDigits(
                                                                item.unitPriceWithComm *
                                                                  item.qty,
                                                                2
                                                              )
                                                            )
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    item.itemType.name === "MINIMUM_REMAINING"
                                      ? _c(
                                          "div",
                                          [
                                            _c("v-divider"),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "row",
                                                staticStyle: { margin: "0px" }
                                              },
                                              [
                                                _c("v-col", [
                                                  _vm._v(
                                                    "Minimum order remaining"
                                                  )
                                                ]),
                                                _vm.$router.currentRoute.path.includes(
                                                  "/dashboard"
                                                )
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "col-3",
                                                        staticStyle: {
                                                          "text-align": "end",
                                                          "max-width": "unset"
                                                        },
                                                        attrs: {
                                                          "align-self": "center"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                _vm.roundToXDigits(
                                                                  item.unitPrice,
                                                                  2
                                                                )
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "col-3",
                                                    staticStyle: {
                                                      "text-align": "end",
                                                      "max-width": "unset"
                                                    },
                                                    attrs: {
                                                      "align-self": "center"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            _vm.roundToXDigits(
                                                              item.unitPriceWithComm,
                                                              2
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "row",
                                                staticStyle: { margin: "0px" }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "caption",
                                                    attrs: { align: "center" }
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "The chef requires a minimum order amount of " +
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              _vm.getPriceWithCommission(
                                                                _vm.booking
                                                                  .serviceSerialized
                                                                  .minPrice
                                                              )
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                }),
                                _vm.errorDishes ? _c("v-divider") : _vm._e(),
                                _vm.errorDishes
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "error--text",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "Select a Starter or a Desert"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c("v-divider"),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: {
                                  margin: "0px",
                                  "font-size": "18px"
                                }
                              },
                              [
                                _c("v-col", [_vm._v("Total")]),
                                _vm.$router.currentRoute.path.includes(
                                  "/dashboard"
                                )
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "col-3",
                                        staticStyle: {
                                          "text-align": "end",
                                          "max-width": "unset"
                                        },
                                        attrs: { "align-self": "center" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatPrice(_vm.total().total)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "col-3",
                                    staticStyle: {
                                      "text-align": "end",
                                      "max-width": "unset"
                                    },
                                    attrs: {
                                      "align-self": "center",
                                      id: "booking_total"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatPrice(
                                            _vm.total().totalRetail
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { align: "center" } },
                                  [
                                    _vm.isAdmin || _vm.isChef
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "margin-default",
                                            attrs: {
                                              outlined: "",
                                              color: "primary",
                                              disabled: !_vm.valid
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.bookingItemsDialog = true
                                              }
                                            }
                                          },
                                          [_vm._v("Edit")]
                                        )
                                      : _vm._e(),
                                    !_vm.valid
                                      ? _c(
                                          "div",
                                          { staticClass: "error--text " },
                                          [_vm._v("some errors are present")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "margin-default" },
                                      [
                                        _vm.booking &&
                                        _vm.$router.currentRoute.path.includes(
                                          "/dashboard"
                                        ) &&
                                        (_vm.booking.status.name ===
                                          "CONFIRMED" ||
                                          _vm.booking.status.name ===
                                            "AWAITING_PAYMENT")
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "primary--text caption",
                                                attrs: {
                                                  id: "btn_reject_chef",
                                                  loading: _vm.loading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.rejectDialog = true
                                                    _vm.rejectMode = "chef"
                                                    _vm.rejectAction =
                                                      _vm.cancelBooking
                                                  }
                                                }
                                              },
                                              [_vm._v("Cancel")]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "margin-default" },
                                      [
                                        _vm.booking &&
                                        (_vm.booking.status.name ===
                                          "CONFIRMED" ||
                                          _vm.booking.status.name ===
                                            "REQUESTED" ||
                                          _vm.booking.status.name ===
                                            "DATE_REQUESTED" ||
                                          _vm.booking.status.name ===
                                            "AWAITING_PAYMENT") &&
                                        _vm.$router.currentRoute.path.includes(
                                          "/account"
                                        )
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "primary--text caption",
                                                attrs: {
                                                  id: "btn_reject_client",
                                                  loading: _vm.loading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.rejectDialog = true
                                                    _vm.rejectMode = "client"
                                                    _vm.rejectAction =
                                                      _vm.cancelBooking
                                                  }
                                                }
                                              },
                                              [_vm._v("Cancel")]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm.$router.currentRoute.path.includes(
                                      "/account"
                                    ) &&
                                    _vm.booking.status.name ===
                                      "AWAITING_PAYMENT" &&
                                    _vm.booking.balance !=
                                      _vm.booking.totalWithCommission
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "row",
                                            staticStyle: { margin: "0px" }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { align: "center" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "caption" },
                                                  [
                                                    _vm._v(
                                                      " By clicking “Confirm and Pay” below, you agree to our "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "/terms",
                                                          target: "_blank"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Terms and Conditions"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" and our "),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "/privacy",
                                                          target: "_blank"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Privacy Policy")]
                                                    ),
                                                    _vm._v(". ")
                                                  ]
                                                ),
                                                _c("stripe-element-card", {
                                                  ref: "elementRef",
                                                  staticClass: "margin-default",
                                                  attrs: { pk: _vm.stripeKey },
                                                  on: {
                                                    token: _vm.handlePayment
                                                  }
                                                }),
                                                _vm.env !== "prod"
                                                  ? _c("div", [
                                                      _vm._v(
                                                        " 4242 4242 4242 4242 12/34 123 94107 //success 4000 0025 0000 3155 12/34 123 94107 //authorisationrequired 4000 0000 0000 9995 12/34 123 94107 //fail "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "margin-default",
                                                    attrs: {
                                                      id: "btn_pay",
                                                      outlined: "",
                                                      color: "primary",
                                                      disabled:
                                                        !_vm.valid ||
                                                        _vm.chef.profile.status
                                                          .name !== "APPROVED",
                                                      loading:
                                                        _vm.isLoadingPayment
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startPaymentProcess()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Confirm And Pay")]
                                                ),
                                                _vm.error
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error--text "
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.error)
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.chef.profile.status.name !==
                                                "APPROVED"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error--text "
                                                      },
                                                      [
                                                        _vm._v(
                                                          "This provider is not available for bookings"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.bookingItemsDialog
                    ? _c("popup-booking-items", {
                        attrs: {
                          bookingItemsDialog: _vm.bookingItemsDialog,
                          booking: JSON.parse(JSON.stringify(this.booking)),
                          "close-action": _vm.closeBookingItems,
                          "edit-action": _vm.modifyBookingItems
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }