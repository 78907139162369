var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state
    ? _c(
        "v-container",
        [
          _c("h2", [_vm._v("Parameters")]),
          _vm._l(_vm.$store.state.parameter, function(parameter, index) {
            return _c(
              "v-row",
              { key: index },
              [
                _c(
                  "v-col",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: parameter.displayName,
                        type: "number",
                        suffix: _vm.getSuffix(parameter),
                        value: _vm.findParameter(parameter).value
                      },
                      on: {
                        change: function($event) {
                          return _vm.setParameter($event, parameter)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "v-btn",
            {
              staticClass: "margin-right-default",
              attrs: { outlined: "", color: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.saveParameter(_vm.parameters)
                }
              }
            },
            [_vm._v("Save")]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }