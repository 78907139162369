import { render, staticRenderFns } from "./Overview.vue?vue&type=template&id=726c4ac4&scoped=true&"
import script from "./Overview.vue?vue&type=script&lang=js&"
export * from "./Overview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726c4ac4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCalendar,VCard,VCol,VContainer,VDivider,VIcon,VRadio,VRadioGroup,VRow,VSpacer,VSwitch,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Dev\\projects\\kootto\\kootto-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('726c4ac4')) {
      api.createRecord('726c4ac4', component.options)
    } else {
      api.reload('726c4ac4', component.options)
    }
    module.hot.accept("./Overview.vue?vue&type=template&id=726c4ac4&scoped=true&", function () {
      api.rerender('726c4ac4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/Overview.vue"
export default component.exports