var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1200px" },
      model: {
        value: _vm.bookingItemsDialog,
        callback: function($$v) {
          _vm.bookingItemsDialog = $$v
        },
        expression: "bookingItemsDialog"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "formBookingItems",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", { staticClass: "headline" }, [
                  _vm._v("Booking Items")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _vm.filteredItems
                        ? _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              "multi-sort": true,
                              loading: _vm.loading,
                              headers: _vm.headers,
                              items: _vm.filteredItems,
                              "hide-default-footer": true,
                              expanded: _vm.expanded,
                              "item-key": "id",
                              "show-expand": false,
                              "item-class": _vm.getClass
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-toolbar-title", [
                                            _vm._v("Items")
                                          ]),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _c("v-switch", {
                                            staticClass:
                                              "caption no-margin-padding",
                                            attrs: {
                                              "hide-details": "",
                                              label: "Show cancelled items"
                                            },
                                            model: {
                                              value: _vm.showCancelledItems,
                                              callback: function($$v) {
                                                _vm.showCancelledItems = $$v
                                              },
                                              expression: "showCancelledItems"
                                            }
                                          }),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _c("v-spacer"),
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: { "max-width": "500px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mb-2",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  dark: ""
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [_vm._v(" Add Item ")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3485626214
                                              ),
                                              model: {
                                                value: _vm.dialog,
                                                callback: function($$v) {
                                                  _vm.dialog = $$v
                                                },
                                                expression: "dialog"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-form",
                                                    {
                                                      ref: "formItem",
                                                      attrs: {
                                                        "lazy-validation": ""
                                                      },
                                                      model: {
                                                        value: _vm.validItem,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.validItem = $$v
                                                        },
                                                        expression: "validItem"
                                                      }
                                                    },
                                                    [
                                                      _c("v-card-title", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "headline"
                                                          },
                                                          [_vm._v("Item")]
                                                        )
                                                      ]),
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c(
                                                            "v-container",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "6"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "item_description",
                                                                            label:
                                                                              "Description"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedItem
                                                                                .description,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedItem,
                                                                                "description",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedItem.description"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "6"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "item_type",
                                                                            items: _vm.itemTypes(),
                                                                            "item-text":
                                                                              "displayName",
                                                                            "item-value":
                                                                              "name",
                                                                            attach:
                                                                              "",
                                                                            label:
                                                                              "Type",
                                                                            color:
                                                                              "#00695C",
                                                                            rules: [
                                                                              function(
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  (!!v &&
                                                                                    v.length !==
                                                                                      0) ||
                                                                                  "You must select a type!"
                                                                                )
                                                                              }
                                                                            ],
                                                                            required:
                                                                              "",
                                                                            "return-object":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedItem
                                                                                .itemType,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedItem,
                                                                                "itemType",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedItem.itemType"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "4"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "unit_price",
                                                                            label:
                                                                              "Unit Price",
                                                                            type:
                                                                              "number",
                                                                            rules: [
                                                                              function(
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  v !==
                                                                                    undefined ||
                                                                                  "Unit Price is required"
                                                                                )
                                                                              }
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            input:
                                                                              _vm.updatePriceWithComm
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedItem
                                                                                .unitPrice,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedItem,
                                                                                "unitPrice",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedItem.unitPrice"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "4"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "retail_price",
                                                                            label:
                                                                              "Retail Price",
                                                                            type:
                                                                              "number",
                                                                            rules: [
                                                                              function(
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  v !==
                                                                                    undefined ||
                                                                                  "Retail Price is required"
                                                                                )
                                                                              }
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            input:
                                                                              _vm.updatePrice
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedItem
                                                                                .unitPriceWithComm,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedItem,
                                                                                "unitPriceWithComm",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedItem.unitPriceWithComm"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "4"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "qty",
                                                                            label:
                                                                              "Qty",
                                                                            type:
                                                                              "number",
                                                                            min: 1,
                                                                            "aria-valuemin":
                                                                              "1",
                                                                            rules: [
                                                                              function(
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  !!v ||
                                                                                  "Qty is required"
                                                                                )
                                                                              }
                                                                            ]
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedItem
                                                                                .qty,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedItem,
                                                                                "qty",
                                                                                _vm._n(
                                                                                  $$v
                                                                                )
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedItem.qty"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm:
                                                                          "12",
                                                                        md: "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-textarea",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "comment",
                                                                            label:
                                                                              "Comment"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedItem
                                                                                .comment,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedItem,
                                                                                "comment",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedItem.comment"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "btn_close_item",
                                                                outlined: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeItem
                                                              }
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "btn_save_item",
                                                                outlined: "",
                                                                color:
                                                                  "primary",
                                                                disabled: !_vm.validItem,
                                                                loading:
                                                                  _vm.loadingModify
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.validateItem() !=
                                                                  true
                                                                    ? null
                                                                    : _vm.saveItem()
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Save")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: { "max-width": "500px" },
                                              model: {
                                                value: _vm.dialogDeleteItem,
                                                callback: function($$v) {
                                                  _vm.dialogDeleteItem = $$v
                                                },
                                                expression: "dialogDeleteItem"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "headline" },
                                                    [
                                                      _vm._v(
                                                        "Are you sure you want to delete this item?"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            id:
                                                              "btn_close_delete_item",
                                                            outlined: "",
                                                            text: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.closeDeleteItem
                                                          }
                                                        },
                                                        [_vm._v("Cancel")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            id:
                                                              "btn_confirm_delete_item",
                                                            outlined: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.deleteItemConfirm
                                                          }
                                                        },
                                                        [_vm._v("OK")]
                                                      ),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "item.unitPrice",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.unitPrice !== undefined &&
                                              item.unitPrice !== null
                                              ? _vm.roundToXDigits(
                                                  item.unitPrice,
                                                  2
                                                )
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.unitPriceWithComm",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.unitPriceWithComm !==
                                              undefined &&
                                              item.unitPriceWithComm !== null
                                              ? _vm.roundToXDigits(
                                                  item.unitPriceWithComm,
                                                  2
                                                )
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "expanded-item",
                                  fn: function(ref) {
                                    var headers = ref.headers
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "  caption",
                                          staticStyle: {
                                            "white-space": "pre-wrap"
                                          },
                                          attrs: { colspan: headers.length }
                                        },
                                        [_vm._v(_vm._s(item.comment))]
                                      )
                                    ]
                                  }
                                },
                                _vm.isAdmin
                                  ? {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-col",
                                            { staticStyle: { padding: "0px" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    id: "btn_delete",
                                                    icon: ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        "!click": function(
                                                          $event
                                                        ) {
                                                          return _vm.editItem(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" mdi-pencil ")]
                                                  )
                                                ],
                                                1
                                              ),
                                              !item.cancelled
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        id: "btn_cancelled",
                                                        icon: "",
                                                        al: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                          on: {
                                                            "!click": function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteItem(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" mdi-delete ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  : null,
                                {
                                  key: "body.append",
                                  fn: function(ref) {
                                    return [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "class",
                                            staticStyle: {
                                              "white-space": "pre-wrap"
                                            },
                                            attrs: { colspan: 2 }
                                          },
                                          [_c("b", [_vm._v("Total")])]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "class text-end",
                                            staticStyle: {
                                              "white-space": "pre-wrap"
                                            }
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(_vm._s(_vm.getTotal.total))
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "class text-end",
                                            staticStyle: {
                                              "white-space": "pre-wrap"
                                            }
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.getTotal.totalRetail)
                                              )
                                            ])
                                          ]
                                        ),
                                        _c("td", {
                                          staticClass: "class",
                                          attrs: { colspan: 3 }
                                        })
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e(),
                      _vm.waiterRemove
                        ? _c("div", [
                            _vm._v(" A waiter is not needed anymore ")
                          ])
                        : _vm._e(),
                      _vm.waiterNeeded
                        ? _c("div", [
                            _vm._v(
                              " A waiter is needed with this booking (Unit Price:$" +
                                _vm._s(_vm.waiterPrice) +
                                ", Retail Price:$" +
                                _vm._s(
                                  this.getPriceWithCommission2(
                                    _vm.waiterPrice,
                                    this.booking.commissionPct
                                  )
                                ) +
                                ") "
                            )
                          ])
                        : _vm._e(),
                      _vm.minPriceRemove
                        ? _c("div", [
                            _vm._v(
                              " Minimum order remaining is not needed anymore "
                            )
                          ])
                        : _vm._e(),
                      _vm.minPriceSuggestion
                        ? _c("div", [
                            _vm._v(
                              " A minimum order is needed with this booking (Unit Price: $" +
                                _vm._s(
                                  this.getPriceWithCommission2(
                                    _vm.minPriceSuggestion,
                                    this.booking.commissionPct
                                  )
                                ) +
                                ", Retail Price :$" +
                                _vm._s(_vm.minPriceSuggestion) +
                                ") "
                            )
                          ])
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c("v-data-table", {
                            staticClass: "elevation-1 margin-top-default",
                            attrs: {
                              "multi-sort": true,
                              loading: _vm.loading,
                              headers: _vm.headersTransaction,
                              items: _vm.filteredTransactions,
                              "hide-default-footer": true,
                              expanded: _vm.expandedTransaction,
                              "item-key": "id",
                              "show-expand": false,
                              "item-class": _vm.getClass
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-toolbar-title", [
                                            _vm._v("Transactions")
                                          ]),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _c("v-switch", {
                                            staticClass:
                                              "caption no-margin-padding",
                                            attrs: {
                                              id: "swt_cancelled_transaction",
                                              "hide-details": "",
                                              label:
                                                "Show cancelled transactions"
                                            },
                                            model: {
                                              value:
                                                _vm.showCancelledTransactions,
                                              callback: function($$v) {
                                                _vm.showCancelledTransactions = $$v
                                              },
                                              expression:
                                                "showCancelledTransactions"
                                            }
                                          }),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _c("v-spacer"),
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: { "max-width": "500px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  _vm.isAdmin
                                                    ? {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mb-2",
                                                                    attrs: {
                                                                      id:
                                                                        "btn_add_transaction",
                                                                      color:
                                                                        "primary",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " Add Transaction "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    : null
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.dialogTransactions,
                                                callback: function($$v) {
                                                  _vm.dialogTransactions = $$v
                                                },
                                                expression: "dialogTransactions"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-form",
                                                    {
                                                      ref: "formTransaction",
                                                      attrs: {
                                                        "lazy-validation": ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.validTransaction,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.validTransaction = $$v
                                                        },
                                                        expression:
                                                          "validTransaction"
                                                      }
                                                    },
                                                    [
                                                      _c("v-card-title", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "headline"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Transaction"
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c(
                                                            "v-container",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "6"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "transaction_description",
                                                                            label:
                                                                              "Description"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedTransaction
                                                                                .description,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedTransaction,
                                                                                "description",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedTransaction.description"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "6"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "transaction_type",
                                                                            items: _vm.transactionTypes(),
                                                                            "item-text":
                                                                              "displayName",
                                                                            "item-value":
                                                                              "name",
                                                                            attach:
                                                                              "",
                                                                            label:
                                                                              "Type",
                                                                            color:
                                                                              "#00695C",
                                                                            rules: [
                                                                              function(
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  (!!v &&
                                                                                    v.length !==
                                                                                      0) ||
                                                                                  "You must select a type!"
                                                                                )
                                                                              }
                                                                            ],
                                                                            required:
                                                                              "",
                                                                            "return-object":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedTransaction
                                                                                .transactionType,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedTransaction,
                                                                                "transactionType",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedTransaction.transactionType"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                        md: "4"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "transaction_amount",
                                                                            label:
                                                                              "Amount",
                                                                            type:
                                                                              "number",
                                                                            rules: [
                                                                              function(
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  v !==
                                                                                    undefined ||
                                                                                  "Amount is required"
                                                                                )
                                                                              }
                                                                            ]
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedTransaction
                                                                                .amount,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedTransaction,
                                                                                "amount",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedTransaction.amount"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c("v-col", {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      sm: "6",
                                                                      md: "4"
                                                                    }
                                                                  }),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm:
                                                                          "12",
                                                                        md: "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-textarea",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "transaction_comment",
                                                                            label:
                                                                              "Comment"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .editedTransaction
                                                                                .comment,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.editedTransaction,
                                                                                "comment",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "editedTransaction.comment"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                outlined: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeTransaction
                                                              }
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                outlined: "",
                                                                color:
                                                                  "primary",
                                                                disabled: !_vm.validTransaction,
                                                                loading:
                                                                  _vm.loadingModify
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.validateTransaction() !=
                                                                  true
                                                                    ? null
                                                                    : _vm.saveTransaction()
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Save")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: { "max-width": "500px" },
                                              model: {
                                                value:
                                                  _vm.dialogDeleteTransaction,
                                                callback: function($$v) {
                                                  _vm.dialogDeleteTransaction = $$v
                                                },
                                                expression:
                                                  "dialogDeleteTransaction"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "headline" },
                                                    [
                                                      _vm._v(
                                                        "Are you sure you want to delete this transaction?"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            id:
                                                              "transaction_close_delete",
                                                            outlined: "",
                                                            text: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.closeDeleteTransaction
                                                          }
                                                        },
                                                        [_vm._v("Cancel")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            id:
                                                              "transaction_confirm_delete",
                                                            outlined: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.deleteTransactionConfirm
                                                          }
                                                        },
                                                        [_vm._v("OK")]
                                                      ),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "expanded-item",
                                  fn: function(ref) {
                                    var headers = ref.headers
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "  caption",
                                          staticStyle: {
                                            "white-space": "pre-wrap"
                                          },
                                          attrs: { colspan: headers.length }
                                        },
                                        [_vm._v(_vm._s(item.comment))]
                                      )
                                    ]
                                  }
                                },
                                _vm.isAdmin
                                  ? {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-col",
                                            { staticStyle: { padding: "0px" } },
                                            [
                                              _c(
                                                "v-btn",
                                                { attrs: { icon: "" } },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        "!click": function(
                                                          $event
                                                        ) {
                                                          return _vm.editTransaction(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" mdi-pencil ")]
                                                  )
                                                ],
                                                1
                                              ),
                                              !item.cancelled
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "btn_transaction_delete",
                                                        icon: "",
                                                        al: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                          on: {
                                                            "!click": function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteTransaction(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" mdi-delete ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  : null,
                                {
                                  key: "body.append",
                                  fn: function(ref) {
                                    return [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "class",
                                            staticStyle: {
                                              "white-space": "pre-wrap"
                                            },
                                            attrs: { colspan: 1 }
                                          },
                                          [_c("b", [_vm._v("Total")])]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "class text-end",
                                            staticStyle: {
                                              "white-space": "pre-wrap"
                                            },
                                            attrs: { colspan: 2 }
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.getBalance.balance)
                                              )
                                            ])
                                          ]
                                        ),
                                        _c("td", {
                                          staticClass: "class",
                                          attrs: { colspan: 3 }
                                        })
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e(),
                      _vm.isAdmin && _vm.bookingHistory != undefined
                        ? _c("v-data-table", {
                            staticClass: "elevation-1 margin-top-default",
                            attrs: {
                              "multi-sort": true,
                              loading: _vm.loading,
                              headers: _vm.headersHistory,
                              items: _vm.bookingHistory,
                              "hide-default-footer": false,
                              expanded: _vm.expandedHistory,
                              "item-key": "id",
                              "show-expand": false,
                              "item-class": _vm.getClass
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-toolbar-title", [
                                            _vm._v("History")
                                          ]),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "expanded-item",
                                  fn: function(ref) {
                                    var headers = ref.headers
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "  caption",
                                          staticStyle: {
                                            "white-space": "pre-wrap"
                                          },
                                          attrs: { colspan: headers.length }
                                        },
                                        [_vm._v(_vm._s(item.comment))]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.creationDate",
                                  fn: function(ref) {
                                    var headers = ref.headers
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "  caption",
                                          staticStyle: {
                                            "white-space": "pre-wrap"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(new Date(item.creationDate))
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3086067175
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { btn: "btn_close", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeAction()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        btn: "btn_save",
                        outlined: "",
                        color: "primary",
                        disabled: !_vm.valid,
                        loading: _vm.loadingModify
                      },
                      on: {
                        click: function($event) {
                          return _vm.editItemsTransactions()
                        }
                      }
                    },
                    [_vm._v("save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }