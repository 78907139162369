var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("h2", [_vm._v("Overview")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "margin-left-right-default",
              attrs: { id: "btn_cancel", outlined: "" },
              on: { click: _vm.cancel }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "margin-right-default",
              attrs: { id: "btn_save", color: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v("Save")]
          )
        ],
        1
      ),
      _vm.user &&
      _vm.user.validationMessages &&
      Object.entries(_vm.user.validationMessages).length > 0
        ? _c("div", [
            _c("h3", { staticClass: "margin-top-bottom primary--text" }, [
              _vm._v(
                " In Order to get your profile Approved, please follow the instruction below:"
              )
            ]),
            _c(
              "ul",
              _vm._l(Object.entries(_vm.user.validationMessages), function(
                message,
                index
              ) {
                return _c("li", { key: index }, [
                  _vm._v(" " + _vm._s(message[1]) + " ")
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.user &&
      _vm.user.profile.comments &&
      _vm.user.profile.status &&
      _vm.user.profile.status.name === "PENDING"
        ? _c("div", [
            _c("h3", { staticClass: "margin-top-bottom primary--text" }, [
              _vm._v(" We will review your profile shortly.")
            ])
          ])
        : _vm._e(),
      _vm.user &&
      _vm.user.profile.comments &&
      _vm.user.profile.status &&
      _vm.user.profile.status.name === "REVIEW"
        ? _c(
            "div",
            [
              _c("h3", { staticClass: "margin-top-bottom primary--text" }, [
                _vm._v(
                  " In Order to get your profile Approved, please follow the instruction below. "
                ),
                _c("br"),
                _vm._v(
                  ' When the changes has been made please click on "Done":'
                )
              ]),
              _c("div", { staticClass: "margin-top-bottom" }, [
                _vm._v(" " + _vm._s(_vm.user.profile.comments) + " ")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "margin-top-bottom",
                  attrs: {
                    id: "btn_done",
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.done()
                    }
                  }
                },
                [_vm._v("Done")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.user &&
      _vm.user.profile.status &&
      _vm.user.profile.status.name === "APPROVED" &&
      _vm.user.service &&
      _vm.user.service.length > 0 &&
      _vm.user.service.filter(function(ts) {
        return ts.enabled === false
      }).length === _vm.user.service.length
        ? _c("div", [
            _c(
              "h3",
              { staticClass: "margin-top-bottom primary--text" },
              [
                _vm._v(" You are now Approved. "),
                _c("br"),
                _vm._v(
                  " You can now make your Menus visible to the public by clicking on: "
                ),
                _c("v-switch", {
                  staticClass: "caption no-margin-padding ",
                  staticStyle: { display: "inline-flex" },
                  attrs: {
                    "hide-details": "",
                    disabled: "",
                    label: "Visible to public: Hidden"
                  }
                }),
                _vm._v(' inside your "Menus" Section')
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.isAvailable
        ? _c("div", [
            _c("h3", { staticClass: "margin-top-bottom primary--text" }, [
              _vm._v(" Please add some available dates in the calendar")
            ])
          ])
        : _vm._e(),
      _c("v-divider", { staticClass: "margin-top-bottom" }),
      _c(
        "v-row",
        [
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#e6d6ff" },
              attrs: { id: "date_request" },
              on: {
                click: function($event) {
                  return _vm.goToBookings("DATE_REQUESTED")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.date_requests))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Date Request")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#96e8e2" },
              attrs: { id: "awaiting_payment" },
              on: {
                click: function($event) {
                  return _vm.goToBookings("AWAITING_PAYMENT")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.awaiting_payments))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Awaiting Payment")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#bfe9fc" },
              attrs: { id: "requested" },
              on: {
                click: function($event) {
                  return _vm.goToBookings("REQUESTED")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.requests))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Request")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#b3efcb" },
              attrs: { id: "confirmed" },
              on: {
                click: function($event) {
                  return _vm.goToBookings("CONFIRMED")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.confirmations))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Booking")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#fcc7c3" },
              attrs: { id: "cancelled" },
              on: {
                click: function($event) {
                  return _vm.goToBookings("CANCELLED_CLIENT")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v("0")]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Cancellation")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#fff4b3" },
              attrs: { id: "messages" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v("0")]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Messages")
              ])
            ]
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "margin-top-bottom" }),
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$refs.calendar.prev()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
            1
          ),
          _vm.$refs.calendar
            ? _c(
                "v-toolbar-title",
                {
                  staticClass: "no-margin-padding",
                  staticStyle: { display: "inline", "padding-left": "0px" }
                },
                [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$refs.calendar.next()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
            1
          ),
          _c(
            "div",
            {
              staticClass: "unavailable margin-left-right-default",
              staticStyle: { padding: "2px 5px 2px 5px" }
            },
            [_vm._v("Unavailable")]
          ),
          _c(
            "div",
            {
              staticClass: "available margin-left-right-default",
              staticStyle: { padding: "2px 5px 2px 5px" }
            },
            [_vm._v("Available")]
          ),
          _c(
            "div",
            {
              staticClass: "date_requested margin-left-right-default",
              staticStyle: { padding: "2px 5px 2px 5px" }
            },
            [_vm._v("Date Requested")]
          ),
          _c(
            "div",
            {
              staticClass: "awaiting_payment margin-left-right-default",
              staticStyle: { padding: "2px 5px 2px 5px" }
            },
            [_vm._v("Awaiting Payment")]
          ),
          _c(
            "div",
            {
              staticClass: "requested margin-left-right-default",
              staticStyle: { padding: "2px 5px 2px 5px" }
            },
            [_vm._v("Requested")]
          ),
          _c(
            "div",
            {
              staticClass: "confirmed margin-left-right-default",
              staticStyle: { padding: "2px 5px 2px 5px" }
            },
            [_vm._v("Confirmed")]
          )
        ],
        1
      ),
      _c("v-calendar", {
        ref: "calendar",
        staticClass: "test",
        attrs: {
          weekdays: _vm.weekday,
          events: _vm.userLocal.events,
          type: "month",
          "event-overlap-mode": "column",
          "event-overlap-threshold": 30,
          "event-color": _vm.getEventColor,
          "event-text-color": _vm.getEventTextColor,
          color: "primary"
        },
        on: {
          "click:date": _vm.checkEvents,
          "click:day": _vm.checkEvents,
          "click:event": _vm.changeEventStatus
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c(
        "div",
        [
          _vm.value !== undefined &&
          _vm.eventOfTheDay() &&
          _vm.eventOfTheDay().length > 0
            ? _c(
                "v-row",
                _vm._l(_vm.eventTime, function(eventTime, index) {
                  return _c(
                    "v-col",
                    { key: index, staticClass: "padding-default" },
                    [
                      _vm._v(" " + _vm._s(eventTime.displayName) + " "),
                      _vm.eventOfTheDay() &&
                      _vm.eventOfTheDay().find(function(e) {
                        return e.eventTime.name === eventTime.name
                      })
                        ? _c(
                            "v-radio-group",
                            {
                              attrs: {
                                disabled: _vm.eventOfTheDayDisabled(
                                  eventTime.name
                                )
                              },
                              on: {
                                change: function($event) {
                                  _vm.changeStatus(
                                    _vm.eventOfTheDay().find(function(e) {
                                      return e.eventTime.name === eventTime.name
                                    })
                                  )
                                }
                              },
                              model: {
                                value: _vm.eventOfTheDay().find(function(e) {
                                  return e.eventTime.name === eventTime.name
                                }).statusValue,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.eventOfTheDay().find(function(e) {
                                      return e.eventTime.name === eventTime.name
                                    }),
                                    "statusValue",
                                    $$v
                                  )
                                },
                                expression:
                                  "eventOfTheDay().find(e=>e.eventTime.name===eventTime.name).statusValue"
                              }
                            },
                            _vm._l(_vm.eventStatus, function(status, index) {
                              return _c("v-radio", {
                                key: index,
                                attrs: {
                                  value: status.name,
                                  disabled:
                                    status.name !== "AVAILABLE" &&
                                    status.name !== "UNAVAILABLE"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                unavailable:
                                                  status.name === "UNAVAILABLE",
                                                available:
                                                  status.name === "AVAILABLE",
                                                date_requested:
                                                  status.name ===
                                                  "DATE_REQUESTED",
                                                awaiting_payment:
                                                  status.name ===
                                                  "AWAITING_PAYMENT",
                                                requested:
                                                  status.name === "REQUESTED",
                                                confirmed:
                                                  status.name === "CONFIRMED",
                                                cancelled:
                                                  status.name === "CANCELLED"
                                              },
                                              staticStyle: {
                                                padding: "2px 5px 2px 5px"
                                              }
                                            },
                                            [_vm._v(_vm._s(status.displayName))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }