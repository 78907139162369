var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "terms" },
      [
        _c("v-row", [_c("h1", [_vm._v("Privacy policy")])]),
        _vm._v(
          " This policy explains how we can collect, use, hold and disclose your personal information, as well as ensuring the quality, integrity and security of your personal information under applicable Privacy Laws. We want to assure you that your information and data is safe with us. We employ the highest standard of physical and electronic measures to protect the safety, security and secrecy of your personal information. We are committed to safeguarding the privacy of the persons that use the website www.kootto.com "
        ),
        _c("br"),
        _c("h2", [_vm._v("1. Definition and nature of personal data")]),
        _vm._v(
          " When you visit the www.kootto.com site (hereinafter the “Site” ), we may have good reason to ask you to transmit your personal data to enable you to reserve catering services on the Site. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " In this confidentiality policy, the term “personal data” refers to all data that are relevant to your identification, in particular your surname, given names, postal and email addresses, photograph, telephone number, as well as data related to your transactions on the Site, e details of your purchases and subscriptions, and any other items of information about you that you may choose to give us. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("2. Collection of personal data")]),
        _vm._v(
          " The legal bases for collecting your personal data are the followings:"
        ),
        _c("br"),
        _vm._v(
          " (i) When you voluntarily provide us with your personal data during your visit to our Site, we have a legitimate interest in collecting such data to enable us to satisfy your request for information regarding our services as expeditiously and as fully as possible. "
        ),
        _c("br"),
        _vm._v(
          " (ii) This collection is necessary for execution of the contract that is concluded when you use our Services on our Site. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(" Your personal data are collected for the following purposes:"),
        _c("br"),
        _vm._v(
          " (i) Management of your access to certain services available on the Site and the use thereof."
        ),
        _c("br"),
        _vm._v(
          " (ii) Carrying out of the operations for the management of customers regarding contracts, orders, delivery, invoices, loyalty programmes, and monitoring of customer relationships."
        ),
        _c("br"),
        _vm._v(
          " (iii) Creation of a file of registered members, users, customers and prospects."
        ),
        _c("br"),
        _vm._v(
          " (iv) Sending of newsletters, solicitations and promotional messages."
        ),
        _c("br"),
        _vm._v(
          " (v) Development of commercial statistics and those regarding the frequency of our services."
        ),
        _c("br"),
        _vm._v(" (vi) Organization of promotional activities."),
        _c("br"),
        _vm._v(
          " (vii) Management of the opinions of persons regarding products, services or contents."
        ),
        _c("br"),
        _vm._v(
          " (viii) Management of unpaid invoices and litigious matters regarding the use of our products and services."
        ),
        _c("br"),
        _vm._v(
          " (ix) Personalization of the responses to your requests for information."
        ),
        _c("br"),
        _vm._v(" (x) Compliance with legal and regulatory obligations."),
        _c("br"),
        _c("br"),
        _vm._v(
          " During the collection of your personal data, we inform you of the mandatory or optional character of some data. The mandatory data are necessary for the functioning of our Services. You are completely free to provide or not provide data that are optional. We also advise you of the possible consequences of a lack of response."
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("3. Recipients of collected data")]),
        _vm._v(" The persons who have access to your personal data are:"),
        _c("br"),
        _vm._v(
          " (i) The employees of our company, the supervisory services (such as auditors) and our subcontractors / Service Providers."
        ),
        _c("br"),
        _vm._v(
          " (ii)Public authorities - but only for the purpose of complying with our legal obligations - such as public officers, ministerial officials, and entities responsible for collection of claims, may also receive your personal data."
        ),
        _c("br"),
        _c("br"),
        _c("h2", [
          _vm._v(
            "4. Purposes of collection, hold, use and disclosure of personal information"
          )
        ]),
        _vm._v(
          " The main reason we collect, use, hold and disclose personal information is to provide you with product and service and to help us run our business, this include :"
        ),
        _c("br"),
        _vm._v(
          " (i) Assisting you where online applications are not completed."
        ),
        _c("br"),
        _vm._v(" (ii) Providing the product or service."),
        _c("br"),
        _vm._v(" (iii) Helping manage the product or service"),
        _c("br"),
        _vm._v(
          " (iv) Helping us develop insights and conduct data analysis to improve the delivery of products, services, enhance our customer relationships and to effectively manage risks and understanding your interests and preferences so we can tailor digital content"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " We may use or disclose your information to comply with our legislative or regulatory requirements in any jurisdiction and to prevent fraud, criminal or other activity that may cause you, us or others harm including in relation to products or services. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("5. Period of data retention")]),
        _vm._v(
          " (i) Regarding data related to the management of customers and prospects:"
        ),
        _c("br"),
        _vm._v(
          " Your personal data are retained only for the period that is necessary for managing your commercial relationship with us. However, data establishing proof of a right or contract, the retention of which is required by virtue of a legal obligation, will be retained for a period that is specified by the applicable law."
        ),
        _c("br"),
        _c("br"),
        _vm._v(" (ii) Regarding data related to bank cards:"),
        _c("br"),
        _vm._v(
          " Financial transactions related to payment of purchases and costs via the Site are assigned to a third party payment provider (Stripe) that is responsible for the proper carrying out and security thereof.(https://stripe.com/au/privacy)"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " As needed, this provider of payment services may receive your personal data regarding your bank card numbers, which it collects and retains in your name and on your behalf."
        ),
        _c("br"),
        _vm._v(" We do not have access to these data."),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("6. Security")]),
        _vm._v(
          " We inform you that we take all appropriate precautions and organizational and technical measures to preserve the security, integrity and confidentiality of your personal data and, particularly, to prevent them from being distorted or damaged, as well as to prevent unauthorized third parties from having access to your personal data. We also use state-of-the-art secured payment systems that comply with the applicable regulations."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The information stored within our computer systems can only be accessed by those entrusted with authority and computer network sanctions."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " No data transmission over the internet can be guaranteed to be 100 percent secure. As a result, while we strive to protect user’s personal information, www.kootto.com cannot ensure or warrant the security of any information transmitted to it or from its online services, and do so at their own risk. Once www.kootto.com receives your transmission, it makes every effort to ensure its security on its systems."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Ultimately, you are solely responsible for keeping your password and/or account information secret. You should be careful and responsible whenever you are online."
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("7. Links")]),
        _vm._v(
          " Our website may contain links to other websites and those third party websites may collect personal information about you. We are not responsible for the privacy practices of other businesses or the content for websites that are linked to our website. www.kootto.com encourages users to be aware when they leave the site and to read the privacy statements of each and every website that collects personally identifiable information."
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("8. Hosting")]),
        _vm._v(
          " We inform you that your data are retained and stored for the entire period that they are retained on the servers of Amazon Web Services."
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("9. Cookies")]),
        _vm._v(
          " Cookies are text files that are often encrypted and stored in your browsers. They are created when a surfer’s browser loads a particular website: The site sends information to the surfer, which then creates a text file. Whenever the surfer returns to the same site, the browser recovers this file and sends it to the website’s server."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " There are different types of cookies that have different purposes:"
        ),
        _c("br"),
        _vm._v(
          " (i) Technical cookies are used throughout the time of your browsing to facilitate and execute certain functions. For example, a technical cookie may be used to memorize the user’s answers entered in a form or even his preferences regarding the language or presentation of a website, when such options are available."
        ),
        _c("br"),
        _c("br"),
        _vm._v(" We use technical cookies."),
        _c("br"),
        _c("br"),
        _vm._v(
          " (ii) Social network cookies may be created by social platforms to enable website designers to share the content of their site on said platforms. These cookies may be used by social platforms especially for the purpose of tracing the surfers’ browsing on the website involved, whether or not they use these cookies."
        ),
        _c("br"),
        _c("br"),
        _vm._v(" We do not use social network cookies."),
        _c("br"),
        _c("br"),
        _vm._v(
          " (iii) Advertising cookies may be created not only by the website on which the surfer browses, but also by other websites displaying advertising, announcements, widgets or other items on the displayed page. These cookies may be used especially for the purpose of effectuating targeted advertising – i.e. advertising that is determined as a function the user’s browsing."
        ),
        _c("br"),
        _c("br"),
        _vm._v(" We do not use advertising cookies."),
        _c("br"),
        _c("br"),
        _vm._v(
          " (iv) We use Google Analytics, which is a statistical tool that analyzes the audience and generates a cookie measuring the number of visits to the Site, the number of pages seen and the visitors’ activity. Your IP address is also collected to determine the city from which you are connected. This cookie is loaded only if you agree thereto. You may accept or refuse it."
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("10. Modifications")]),
        _vm._v(
          " We reserve the right to change all or any part of this confidentiality policy at any time at our discretion. Any such changes will take effect upon the publication of the new confidentiality policy. Your use of the Site after the entry into effect of these changes will be deemed to be your acknowledgement and acceptance of the new confidentiality policy. If this new confidentiality policy does not suit you, do not use the Site."
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("12. Contact us")]),
        _vm._v(
          ' If you have any questions or complaints about this privacy policy, or the practices at Kootto, contact our team using the contact form ("Contact US")'
        ),
        _c("br")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }