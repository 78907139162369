var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "terms" },
      [
        _c("v-row", [_c("h1", [_vm._v("Terms and Conditions")])]),
        _c("h2", [_vm._v("1. Description and definitions")]),
        _vm._v(
          ' The Company Kootto Pty Ltd (the "Company") has designed the Website www.kootto.com (the "Website"), a platform to connect : individual or collective, private or professional users (the "User(s)") with external agents who perform cooking services (the "Chef(s)"). '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          ' The purpose of the Company is to connect the Users and the Chefs via the Website in order to contract cooking services (the "Services"). '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          ' The term “use" within the website includes browsing, registering, booking or any other functionality. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          ' The Services can be carried out at home or within a business, for individual or collective, private or professional Users. Through the Website, the Company offers Users a non-exhaustive range of Services, which can be booked (“Booking") and paid online. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          ' The Company does not intend to carry out the Services itself. These are carried out directly by external Chefs, outside the Company, who have no subordination relationship with the Company, and under their full and direct responsibility. The role of the Company is limited to the presentation and promotion of these Chefs on the Website. Each Chef is required to complete a “Chef Profile" and to upload information related to his user profile, including both text and Images, and which will be visible both in his public chef profile and every time the user browse their menus. The following provisions govern your use of the Website and all other services made available to you through the Website. Please read these terms and conditions carefully before using the Website. By using the Website, you agree to be bound by these Terms and Conditions which constitute a contract between you and the Company ABN 88 642 355 708 Australia ("we" or "us"). If you do not agree to all of the provisions contained in these Terms and Conditions, do not use the Website. We reserve the right to revise and update these Terms and Conditions at any time. Please periodically review these Terms and Conditions. Your continued use of the Website constitutes your acceptance of and agreement to any revised Terms and Conditions. The company reserves the right to change any of the Terms and Conditions or any policies or guidelines governing the Website or Service, at any time and in its sole discretion. Any changes will be effective upon posting of the revisions on the Website. All notice of changes to these Terms and Conditions will be posted on the Website. Please also see our Privacy Policy. '
        ),
        _c("br"),
        _c("br"),
        _c("h2", [
          _vm._v("2. Registration and Management of the Personal Account")
        ]),
        _c("h3", [_vm._v("2.1 Registration")]),
        _vm._v(
          ' By registering on the Website, the Chef and/or the User expressly acknowledges and accepts the present General Terms and Conditions of Use ("Terms"). Any subsequent connection by a User and/or Chef to his / her Personal Account constitutes the express acceptance of the Terms. '
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("2.2 Registration requirements")]),
        _vm._v(
          " The access to the Website is restricted to Users and Chefs, a natural person of at least 18 years of age, who has full legal capacity in accordance with the Australian law, for a personal use, and to any legal entity registered in the Trade and companies registered in Australia. The use of the Website by a minor will remain the sole responsibility of its legal representative. "
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("2.3 Creating an account")]),
        _vm._v(
          ' The registration for a Chef is compulsory before any use. The registration for a user is compulsory before finalizing a booking. The User and/or Chef must proceed with the creation of his Personal Account by means of the registration form available on the Website, under the page "Sign Up". A User or Chef may only have one Personal Account. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User and/or Chef undertakes to fill in all mandatory fields of the form and to provide the Company with accurate, complete and up-to-date information in order to enable the Company to validate the creation of the Personal Account. The User and/or Chef acknowledges that the data communicated is accurate and is a proof of their identity, the veracity of this information not being controlled by the Company. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          ' At any time, the User may modify the information on his Personal Account. The User and/or Chef is invited to consult the section "Privacy policy" for more information on the use made by the Company of his personal data and the modalities of the exercise of his rights. '
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("2.4 Security of Login ID and Password")]),
        _vm._v(
          " Upon registration, each User and/or Chef is given a Personal Account. It is accessible through confidential, secret and personal codes, which he/she has defined itself. The company can not retrieve the User’s and/or Chef’s personal codes. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Confidential Codes are confidential and reserved for personal use to the exclusion of any other use made by third parties. Thus, the User undertakes to preserve the confidentiality of his Confidential Codes and in no case to transmit them to third parties. The user is presumed to be responsible for any action resulting from a connection to the Website with his Confidential Codes. In no event will the Company be liable for the consequences of such use. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company invites Users and/or Chefs to inform of any fraudulent use of which it may be aware. In case of unauthorized use of the password by a third party, the User and/or Chef is obliged to change his / her password via the dedicated page of the Website. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company reserves the right to ask the User to modify and/or change all or some of his Confidential Codes, including for regulatory, technical or security reasons, without the user being able to object and/or claim any compensation in respect thereof. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User and/or Chef acknowledges that the provision of any incorrect, incomplete, false or outdated information at the time of registration makes him responsible for liability to other Users and Chefs as well as to the Company. The Company is not responsible for the content of the information provided by the User and/or Chef. The Company may decide to suspend and/or cancel the registration of any User and/or Chef with immediate effect and without notice. The suspension and/or the removal of any User and/or Chef from the Website will entail the retention of any amount due by the User and/or Chef to the Company with respect to the Services or, where applicable, the payment by the Company of any excess amount to the User and/or Chef. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company may terminate the suspension or authorize the re-registration of the User and/or Chef upon rectification of the erroneous information according to its discretion without possible claim or objection. This decision belongs to the Company and can not be called into question by the User and/or Chef. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User is responsible for updating his personal information. In the event of a change, he will modify the information in his Personal Account within a reasonable time. Any omission or negligence in this respect is the sole responsibility of the User and/or Chef. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("3. Using the Website")]),
        _c("h3", [_vm._v("3.1 Use")]),
        _vm._v(
          " It is forbidden to violate or attempt to violate the security or integrity of the Website and in particular to: "
        ),
        _c("ul", [
          _c("li", [
            _vm._v(
              "implement any action that would endanger or interfere with the proper functioning of the Website or any purchase made on the Website,"
            )
          ]),
          _c("li", [
            _vm._v(
              "Implement any action requiring unreasonable or disproportionately large loading on the Website's infrastructure,"
            )
          ]),
          _c("li", [
            _vm._v(
              "access, or attempt to access, data that is not intended for viewing by the User and/or Chef or,"
            )
          ]),
          _c("li", [
            _vm._v(
              "penetrate, or attempt to penetrate, into a server or Personal Account to which the User and/or Chef is not authorized to access."
            )
          ])
        ]),
        _c("br"),
        _vm._v(
          ' It is forbidden to make any "framing" operation (incorporation of a web page from another Website within one’s own Website) or to create a "mirror Website" that virtually replicates the Website, without the express authorization of the Company, or to include, in any offer of Service or any profile, a hypertext link referring to a third party Website. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " In general, all elements relating to the respect of certain moral and regulatory values contained in article 3 below apply to the use of the whole Website and the following provisions are expressly brought to the attention of the User and/or the Chef. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " By using this Website, the User and/or Chef shall refrain from: "
        ),
        _c("ul", [
          _c("li", [
            _vm._v(
              "disseminating content that is harmful, defamatory, unauthorized, malicious, that infringes privacy or image rights, encourages violence, racial or ethnic hatred;"
            )
          ]),
          _c("li", [
            _vm._v(
              "using the Website for political, propaganda or proselytism purposes; publishing advertising or promotional content"
            )
          ]),
          _c("li", [
            _vm._v(
              "disseminating information or content likely to offend the sensitivity of the youngest"
            )
          ]),
          _c("li", [
            _vm._v(
              "carrying out illegal activities, in particular infringing the rights of third party software, marks, photographs, images, texts, videos etc."
            )
          ]),
          _c("li", [
            _vm._v(
              "using a defamatory, malicious log-in that infringes privacy laws, that encourages violence, racial or ethnic hatred."
            )
          ])
        ]),
        _c("br"),
        _vm._v(
          " The Company shall not be held liable for any damage whatsoever, whether direct or indirect, resulting from the use of this Website. "
        ),
        _c("br"),
        _c("br"),
        _c("h4", [_vm._v("3.1.1 Specific conditions for the chef")]),
        _vm._v(
          " When you accept a Booking , you will be signing a legally binding contract with the latter, which forces you to provide the Service in accordance with the conditions described in your offer. You also agree to pay the corresponding Management Expenses (Commissions) and Applicable Taxes. The company recommends that Chefs take out adequate insurance for the Service provided to the User. Please review the corresponding insurance policy carefully, and make sure in particular to know and understand any exceptions and reductions that may apply to said insurance policy, including, without limitation, whether or not it covers the actions or inactions of the Users, during the Service. It is a requirement that all Chefs carry with them to all Services a small first aid kit in case of minor injury whilst cooking. The images used in your Chef Profile must accurately reflect the quality and condition of your Services. The company reserves the right to require that your Chef Profile has a minimum number of Images of a certain format, size and resolution. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " When submitting Chef Profile through the Website, you must provide complete and accurate information about your Service (such as the additional services included, etc.). You are responsible for keeping the information of your Service updated at all times, and cancel it if no longer available. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Chefs wanting to prepare food prior the Service will need to contact their local council for advice to ensure they are compliant with any food safety guidelines, requirements and/or licenses applicable in that area.please contact your local council’s food safety department for further information. You are exclusively responsible for setting the price (including GST) in your Service. When setting the price, and before submitting your Service, the Website will always indicate the percentage corresponding to Management Expenses. This way, you can set the price you deem appropriate, and for greater clarity you will have a calculation table that will show you the breakdown of a booking including the Management Expenses (Commissions). Once a User reserves your Service, you will not be able to demand that the User pays a price higher than the one set in your Service. "
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("3.2 Liability")]),
        _vm._v(
          " The performance of a Service is solely the result of an agreement between a User and a Chef. The latter shall act under their sole and absolute responsibility. As such, the performance of a Service does not bind the Company in any way whatsoever. The role of the Company is exclusively the connection of a User and a Chef. We advise the chef to have an insurance against any damage that may occur in the provision of private chef services, concerning to eventual food poisoning, as well as damage to movable and immovable property used during the provision of services, as well as damages to any property that is in the domicile of the User and that could suffer damage during the time you are managing the Service. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company shall use its best efforts to assist the User and/or the Chef with finding an amicable solution to the dispute, this obligation being a simple best efforts obligation. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company can not be held liable for any direct or indirect damage resulting from the use of the Website (including loss of data, opportunity, reputation) or the impossibility of accessing it, the use of any content on the Website, including content posted by other Users, any failure, error or interruption of the Website, or any computer virus transmitted through the network. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Furthermore, the Company can not be held responsible for the consequences resulting from the violation by a User and/or Chef of these Terms. Thus, the User and/or Chef undertakes to indemnify the Company in the event of action brought by third parties, or the Company's conviction resulting in fact from the User and/or Chef. "
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("3.3 Exchanges between Users and Chefs")]),
        _vm._v(
          ' This article applies to all the spaces of exchange between Users and/or Chefs and the spaces of comments by the Users and/or Chef, present on the Website, and in particular (without limitation) the "Exchange Spaces". Any comments made or other data in any form whatsoever posted by the User and/or Chef on the Website are their sole responsibility. As such, the Company reserves the right to remove any content, data, information or offer that is manifestly illegal or inappropriate from the Website (and in particular from the Exchange Spaces) of which it is aware, to suspend or cancel the registration of the User and/or Chef concerned definitively and without notice. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The suspension and/or removal of any User and/or Chef from the Website will result in the retention of the amount owed by the User and/or Chef to the Company in respect of the Services reserved and the payment by the Company of any excess amount to the User. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " In particular, the Company will moderate Exchange Spaces retrospectively and may choose to remove from these Exchange Spaces the contents that it will deem unreasonable, inappropriate, or presenting features as described below. Any comments posted online by a User on the Website (and in particular on an Exchange Space) may therefore be deleted by the Company retrospectively according to the aforementioned criteria. The Company will not make any prior moderation of the contents published by the User and/or the Chef on these Exchange Spaces. The control, if any, shall be carried out only after the event. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User agrees to abide by the usual principles of use of discussion systems, and in particular to prohibit any item in the following list (non exhaustive list): "
        ),
        _c("br"),
        _c("br"),
        _c("ul", [
          _c("li", [_vm._v("contrary to public order and good morals,")]),
          _c("li", [
            _vm._v(
              "abusive, defamatory, racist, xenophobic, homophobic, revisionist, or damaging to the honor or reputation of others,"
            )
          ]),
          _c("li", [
            _vm._v(
              "inciting discrimination, hatred of a person or a group of persons because of their origin, sexual orientation, membership or non-membership to an ethnic group, nation, race or a determined religion,"
            )
          ]),
          _c("li", [_vm._v("threatening a person or group of people,")]),
          _c("li", [_vm._v("unlawful content,")]),
          _c("li", [
            _vm._v(
              "content degrading or harmful to the human person or to his integrity,"
            )
          ]),
          _c("li", [
            _vm._v(
              "inciting to commit an offense, a crime or an act of terrorism or defending war crimes or crimes against humanity,"
            )
          ]),
          _c("li", [_vm._v("inciting suicide,")]),
          _c("li", [
            _vm._v(
              "allowing third parties to obtain, directly or indirectly, pirated software, software serial numbers, computer software for hacking and intrusion into computer and telecommunication systems, viruses and other logical bombs and in general any software or other tool allowing to infringe the rights of others and the security of persons and property in violation of the privacy of correspondence,"
            )
          ]),
          _c("li", [
            _vm._v(
              "confidential by virtue of a legislative measure or legal act relating to data processing, files and freedoms (including internal, privileged, insider trading or trade secret information) , or"
            )
          ]),
          _c("li", [
            _vm._v(
              "concerning other Users for inappropriate or unlawful purposes such as unsolicited solicitation, sending proposals to participate in lotteries or contests, chains of letters, or any other matter that is not reasonably related to the use of the Website activity of the Company."
            )
          ])
        ]),
        _c("br"),
        _vm._v(
          " The User and/or the Chef undertakes to inform the Company of any abuse or presence on the Website (and in particular on the Exchange Spaces) of the elements mentioned above. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company reserves the right to keep records of the exchanges made on the Website (and in particular the Exchange Spaces) between Users and/or Chefs for further examination. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company is not responsible for the content of the Websites accessible by links inserted by Users on its Website (and especially on the Exchange Spaces). It is the responsibility of Users and/or Chefs to take all necessary precautions to ensure that the components that it chooses to use are free from viruses, worms, Trojan horses or any other elements that may damage its equipment or to any one of its components. In no event shall the Company be liable to any person for any direct, indirect, special or other damages resulting from the use of third party Websites. It is also up to Users and/or Chefs to take the necessary precautions to ensure that no link to another Website is inserted. Otherwise, and without prejudice to the legal provisions mentioned above, the Company reserves the right to remove any link or content that contravenes the provisions of this article. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("4. Booking a service")]),
        _c("h3", [_vm._v("4.1 Booking service procedure")]),
        _vm._v(
          " The company has established a system to book services through the Website. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company does not intervene in any phase of the Booking process, leaving the parties free to confirm each and every element of the Service. From the selection to the confirmation of the Booking, each and every one of the phases of this Booking process are approved by the parties including the price and conditions of the Service. Once the Booking has been made, automatically both User and Chef will receive an email notification confirming the Booking and detailing the contact details of the other party, in order to communicate more comfortably by telephone or email. Any use of the Platform, both as a User and as a Chef, is related to a specific name. The identity of the Chef and the User must correspond to the identity communicated by them to the Company. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("5. Payments terms of service")]),
        _c("h3", [_vm._v("5.1 Offer and Rates")]),
        _vm._v(
          " Each Chef has his own pricing per service, the price should include the gst (if applicable). The price as described above includes the commission of the Company, according to a method of calculation determined in agreement with the Chef. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " In the event that it is impossible for the Chef to honor the reserved Service, the Company and the Chef will make their best efforts to propose an alternative time or an alternative Chef to the User as soon as possible. If no alternative solution has been found the company will refund the booking fee. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Payment of the Service is made by credit card, by secure payment made available to the Users on the Website. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " In no case shall a Service be carried out in the absence of payment of the price of the Service. "
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("5.2 Transaction")]),
        _vm._v(
          " Payment of the Service is made by credit card, by secure payment made available to the Users on the Website "
        ),
        _c("br"),
        _c("br"),
        _vm._v(" Transaction security is provided by Stripe. "),
        _c("br"),
        _c("br"),
        _vm._v(
          " The deposit of the price of the Service is made on a dedicated account opened in the name of the Company with a bank of its choice. Once the payment of the Service has been recorded on the dedicated account, the Company alone is authorized to order the bank to pay it to the Chef, after deducting the commission due to the Company for the use of its services. "
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("5.3 Responsibility")]),
        _vm._v(
          " The Company shall not contractually participate in the supply of the Services. The contract is formed directly between the Chef and the User according to the terms described in the article above. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Consequently, the Company does not incur any liability in connection with the discussions, conclusion and performance of the Services - subject to its responsibilities as supplier of the services to Chefs - and does not guarantee the quality and the smooth running of the Services performed by the Chefs. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " In particular, the Company exercises no systematic control and assumes no liability in connection with: "
        ),
        _c("ul", [
          _c("li", [
            _vm._v(
              "the quality, the conformity of the offer with the law or the adequacy of the Services proposed by the Chefs"
            )
          ]),
          _c("li", [
            _vm._v(
              "the reliability and accuracy of any information relating to the Services or the identification of the Users"
            )
          ]),
          _c("li", [_vm._v("the professional qualification of the Chefs")]),
          _c("li", [_vm._v("the solvency of the Users.")])
        ]),
        _c("br"),
        _vm._v(
          ' In the event of a dispute between Users and Chefs concerning a Service, the Users are invited to contact the Company via the contact form on the Website ("contact us") to inform them of the existence of a dispute. The Company will make its best efforts to assist in an amicable resolution. However, the resolution of this dispute will be the sole responsibility of the User and the Chef concerned. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Company shall not be held responsible for any liability other than with respect to its responsibility as an online broker. The Company makes no warranty as to the availability or performance of the Website and the User and/or the Chef may not seek the Company's liability for any lost profits, loss of opportunity or business. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("6. Cancellation policy and refund")]),
        _c("h3", [
          _vm._v(
            "6.1 Condition of reimbursement of payment in case of cancellation"
          )
        ]),
        _vm._v(" Cancellation of a Service will be subject to the following: "),
        _c("ul", [
          _c("li", [
            _vm._v(
              "If the cancellation is made by the Chef, the total amount paid will be refunded to the guest. (We reserve the right to penalize the chef on the platform if too many cancellations)"
            )
          ]),
          _c("li", [
            _vm._v("In case of cancellation by the Guest: "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "If the Guest cancels the Service with more than 10 days notice, the guest is entitled to full refund minus the transactions fees (3%)"
                )
              ]),
              _c("li", [
                _vm._v(
                  "If the Guest cancels the service less than 10 days before the time set for the service, no refund will be made to the guest. The Chef will receive the Service Fee fixed in his Offer, and Take a Chef Kootto will keep the Management Fees."
                )
              ])
            ])
          ])
        ]),
        _c("br"),
        _vm._v(
          " In the event that a refund applies, The Company will retain 3% of the total service covered by the non-refundable transactional fees. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("7. Personal Data")]),
        _vm._v(
          " In the context of your use of the Website, Kootto will collect and process your personal information. By using the Website and registering as a User and/or Chef, you acknowledge and accept the processing of your personal data by the Company in accordance with applicable legislation and the provisions of the Privacy Policy. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("8. Intellectual property")]),
        _c("h3", [_vm._v("8.1 Intellectual property")]),
        _vm._v(
          " The Website and each of its components (such as trademarks, images, texts, videos, etc.) are protected under intellectual property, including but not limited to: "
        ),
        _c("br"),
        _c("br"),
        _c("ul", [
          _c("li", [
            _vm._v(
              'Trademarks and logos: “Kootto" as well as logos and the brands of its partners are protected by appropriate registrations with the competent authorities.'
            )
          ]),
          _c("li", [
            _vm._v(
              "Websites: All the icons, drawings, graphics, photographs, texts, images, programs and other components of the Website are the property of the Company or its partners and are protected by Australian copyright law."
            )
          ]),
          _c("li", [
            _vm._v(
              "Any non-commercial or commercial use of icons, drawings, graphics, photographs, texts, images, programs and other components of the Website without the prior written consent of the Company or its partners is strictly prohibited."
            )
          ])
        ]),
        _c("br"),
        _c("br"),
        _vm._v(
          ' The Website\'s architecture,domain names, databases, software, trademarks, content and all other elements composing the Website, without this list being exhaustive (hereinafter the "Protected Elements"), are the exclusive property of the Company. These Terms do not entail any assignment of intellectual property rights attached to the Website or the Protected Elements. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User and/or Chef shall refrain from any action likely to infringe, directly or indirectly, the Company's intellectual property rights or, as the case may be, the rights of third parties on the Protected Elements. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The use, transmission, marketing, reproduction, or creation of derivative works on the basis of the Protected Elements without the prior written consent of the Company is strictly prohibited and may be sanctioned for infringement. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User further agrees not to access or attempt to access the Website by any means other than the Website interface provided by the Company, nor to undertake any activity that would hinder or disrupt the provision of the Services by the Company. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The Website is for a strictly personal and private use is authorized by the Company. Any reproduction, use or representation of the Website (in part or in whole) or the elements that compose it, on any medium whatsoever, for other purposes, and in particular commercial purposes, is not authorized. Competitors are not allowed to use the website, or they will be fully responsible for any damages or loss it will cause to the Company and they will be held accountable for all profit they will make from this breach. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " In the case of any content disseminated by Users and/or Chefs (notably on Exchange Spaces), any act of infringement or likely to be qualified as such by a third party and for which the User and/or Chef would have knowledge shall be denounced to the Company which will take steps for prosecution. The Company declines all responsibility in the event of an infringement action between the Users or more generally in the event of any action for infringement by a third party against a User. "
        ),
        _c("br"),
        _c("br"),
        _c("h3", [_vm._v("8.2 Content")]),
        _vm._v(
          ' For the purposes of these Terms, the "Content" means any information, data or other element posted on the Website by a User or a Chef. In particular, the User and Chefs identification elements and the notes and comments left by the Users will be considered Contents. '
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User and/or Chef is solely responsible for the Contents that he places on the Website. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User acknowledges to grant to the Company a non-exclusive and free license on the Contents, as and when they are put online, for the whole world and for the duration of protection of the Contents by the applicable laws, for the purpose of their reproduction, representation, exploitation or deletion from the Website, by any means, whatever the contents, the features, the versions and the methods of diffusion of the Website (for example on mobile equipment), it being understood that this license includes for the Company the right to modify or delete the Content, including for technical reasons, for the purposes of the provision of the Services by the Company and in accordance with the provisions of these Terms. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The User and/or Chef irrevocably assigns to the Company all publications and/or photographs concerning him which may be published by the Company on the Website or which have been transmitted to the Company during the registration on the Website or during the use of the Website. The Company has the right to disseminate and reproduce, adapt these Contents even if the User and/or the Chef closes his account on the Website or is removed from the Website by the Company. "
        ),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("9. Promotions")]),
        _vm._v(
          " Promotion: Bonus up to $200 for the First Caterers to Register "
        ),
        _c("ul", [
          _c("li", [
            _vm._v(
              "Eligibility: This promotion is available to caterers who get approved on our platform during the promotional period."
            )
          ]),
          _c("li", [
            _vm._v(
              "Bonus Calculation: Qualified caterers will be eligible to receive a bonus of $50 or 10% of the total amount of the booking, whichever is lower, for the first four bookings completed through our platform."
            )
          ]),
          _c("li", [
            _vm._v(
              "Promotional Period: The promotion will remain active until the specified number of registrations per city/area is reached. Once the maximum registrations for a particular city/area are met, the promotion will no longer be available in that specific location."
            )
          ]),
          _c("li", [
            _vm._v(
              'Display of City/Area: The list of cities/areas where the promotion is no longer available will be displayed on the "Key Feature Page" on our website.'
            )
          ]),
          _c("li", [
            _vm._v(
              "Bonus Payment: The bonus amount will be credited to the caterer's account upon the completion of each eligible booking. The bonus will be calculated based on the booking amount, and the lower of $50 or 10% of the total amount will be awarded."
            )
          ]),
          _c("li", [
            _vm._v(
              "Terms Modification: We reserve the right to modify or terminate this promotion at any time without prior notice."
            )
          ]),
          _c("li", [
            _vm._v(
              "Non-transferable: The bonus is non-transferable and cannot be exchanged for cash or any other form of compensation."
            )
          ])
        ]),
        _c("br"),
        _c("br"),
        _c("h2", [_vm._v("10. Modification of the Terms and Conditions")]),
        _vm._v(
          " These Terms and Conditions and the documents integrated by reference form the complete agreement between you and the Company regarding the use of the Website. Any other document mentioned on the Website (Help, for example), is for indicative purposes only. The Company may modify these Terms and Conditions to adapt them to your technological and commercial environment, and to comply with current legislation. Any modification made in the Terms and Conditions will be published on the Website indicating the date of entry into force. "
        ),
        _c("br"),
        _c("br")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }