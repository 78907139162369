var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-container",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "margin-bottom" },
                [_c("h2", [_vm._v("Photos")]), _c("v-spacer")],
                1
              ),
              _vm.serviceTypes.length === 0
                ? _c("div", [_vm._v("Please add a Menu first")])
                : _vm._e(),
              _vm.serviceTypes.length !== 0
                ? _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "primary"
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.serviceTypes, function(serviceType) {
                      return _c("v-tab", { key: serviceType.name }, [
                        serviceType
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(serviceType.displayName) + " "
                              )
                            ])
                          : _vm._e()
                      ])
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.serviceTypes, function(serviceType) {
                  return _c(
                    "v-tab-item",
                    { key: serviceType.name },
                    [
                      _c(
                        "v-row",
                        { staticClass: "row", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "5" } },
                            [
                              _c("v-file-input", {
                                attrs: {
                                  id: "file",
                                  label: "File input",
                                  "show-size": true,
                                  accept: "image/jpeg,image/png",
                                  rules: _vm.inputFileRule,
                                  counter: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.photo.file,
                                  callback: function($$v) {
                                    _vm.$set(_vm.photo, "file", $$v)
                                  },
                                  expression: "photo.file"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "5" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  id: "description",
                                  label: "Description"
                                },
                                model: {
                                  value: _vm.photo.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.photo, "description", $$v)
                                  },
                                  expression: "photo.description"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2", align: "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "btn_photo_add",
                                    outlined: "",
                                    small: "",
                                    disabled: !_vm.valid,
                                    loading: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addPhoto()
                                    }
                                  }
                                },
                                [_vm._v("Upload")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-card", [
                        _vm.photos
                          ? _c(
                              "div",
                              { staticClass: "gallery" },
                              _vm._l(_vm.photos, function(photo, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "pic" },
                                  [
                                    _c("img", {
                                      staticClass: "gallery-pic",
                                      attrs: {
                                        src:
                                          photo.photoPath +
                                          "low." +
                                          photo.photoFilename,
                                        alt: "photo.description"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.photoDetails = true
                                          _vm.photoIndex = index
                                        }
                                      }
                                    }),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          !photo.description
                                            ? photo.description
                                            : ""
                                        )
                                      )
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          id: "btn_photo_delete",
                                          icon: "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deletePhoto(photo)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-delete-outline")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            fullscreen: "",
                            "content-class": "caroussel-dialog-fullscreen",
                            "hide-overlay": ""
                          },
                          model: {
                            value: _vm.photoDetails,
                            callback: function($$v) {
                              _vm.photoDetails = $$v
                            },
                            expression: "photoDetails"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: {
                                position: "absolute",
                                "z-index": "1"
                              },
                              attrs: {
                                id: "btn_carousel_close",
                                icon: "",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.photoDetails = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _vm.photos
                            ? _c(
                                "v-carousel",
                                {
                                  staticClass: "caroussel-fullscreen",
                                  attrs: {
                                    "show-arrows": "",
                                    "hide-delimiters": "",
                                    height: "100%"
                                  },
                                  model: {
                                    value: _vm.photoIndex,
                                    callback: function($$v) {
                                      _vm.photoIndex = $$v
                                    },
                                    expression: "photoIndex"
                                  }
                                },
                                _vm._l(_vm.photos, function(photo) {
                                  return _c("v-carousel-item", {
                                    key: photo.id,
                                    attrs: {
                                      contain: "",
                                      src:
                                        photo.photoPath +
                                        "high." +
                                        photo.photoFilename
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }