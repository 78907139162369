var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { "align-self": "center" } }, [
            _c(
              "div",
              {
                staticStyle: { "text-align": "center", "font-weight": "bold" }
              },
              [_vm._v(" About the Chef ")]
            ),
            _c(
              "div",
              {
                staticClass: "container",
                staticStyle: { "text-align": "left" }
              },
              [_vm._v(" " + _vm._s(_vm.chef.profile.about) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { "align-self": "stretch" } }, [
            _c(
              "div",
              {
                staticStyle: { "text-align": "center", "font-weight": "bold" }
              },
              [_vm._v(" Location ")]
            ),
            _vm.chef.userDetails && _vm.chef.userDetails.city
              ? _c(
                  "div",
                  {
                    staticClass: "container",
                    attrs: { "align-content": "center", align: "center" }
                  },
                  [_vm._v(" " + _vm._s(_vm.chef.userDetails.city) + " ")]
                )
              : _vm._e()
          ]),
          _c("v-col", { attrs: { "align-self": "center" } }, [
            _c(
              "div",
              {
                staticStyle: { "text-align": "center", "font-weight": "bold" }
              },
              [_vm._v(" Spoken Language ")]
            ),
            _c(
              "div",
              {
                staticClass: "container",
                attrs: { "align-content": "center", align: "center" }
              },
              _vm._l(_vm.chef.profile.languages, function(language) {
                return _c(
                  "div",
                  {
                    key: language.name,
                    staticClass: "container",
                    staticStyle: { display: "inline", "text-align": "left" }
                  },
                  [_vm._v(" " + _vm._s(language.displayName) + " ")]
                )
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }