var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.chefselected
      ? _c(
          "div",
          _vm._l(_vm.serviceOrdered, function(service, index) {
            return _c(
              "div",
              { key: index },
              [
                index != 0
                  ? _c("v-divider", {
                      staticStyle: {
                        "margin-top": "12px",
                        "margin-bottom": "12px"
                      },
                      attrs: { dark: "" }
                    })
                  : _vm._e(),
                service.enabled
                  ? _c(
                      "v-card",
                      [
                        _c(
                          "v-col",
                          { attrs: { "align-self": "center" } },
                          [
                            _c(
                              "v-row",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "medium",
                                  "font-weight": "700",
                                  margin: "-12px -12px 0px -12px",
                                  padding: "12px",
                                  "border-radius": "4px",
                                  "background-color": "#f7f3f3"
                                }
                              },
                              [
                                _c("v-col", {
                                  attrs: { cols: "1", align: "start" }
                                }),
                                _c("v-col", { attrs: { cols: "10" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "medium",
                                        "font-weight": "700",
                                        margin: "-12px -12px 0px -12px",
                                        padding: "12px",
                                        "border-radius": "4px",
                                        "background-color": "#f7f3f3"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(service.serviceType.displayName)
                                      )
                                    ]
                                  )
                                ]),
                                _c("v-col", {
                                  attrs: { cols: "1", align: "end" }
                                })
                              ],
                              1
                            ),
                            _vm._l(service.itemCategories, function(
                              category,
                              indexCat
                            ) {
                              return _c(
                                "div",
                                {
                                  key: category.name,
                                  staticClass: "margin-top-default"
                                },
                                [
                                  _vm.filteredDishesByCategory(
                                    service,
                                    category
                                  ).length > 0
                                    ? _c(
                                        "div",
                                        [
                                          indexCat != 0
                                            ? _c("v-divider", {
                                                staticStyle: {
                                                  "margin-top": "12px",
                                                  "margin-bottom": "12px"
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: { align: "start" }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "primary--text",
                                                          staticStyle: {
                                                            display: "inline",
                                                            "text-align":
                                                              "left",
                                                            "font-weight":
                                                              "bold"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                category.name
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        "align-self": "center"
                                                      },
                                                      attrs: {
                                                        align: "end",
                                                        cols: "2"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " $ " +
                                                          _vm._s(
                                                            category.pricingType !=
                                                              undefined &&
                                                              category.pricingType !==
                                                                null
                                                              ? category
                                                                  .pricingType
                                                                  .displayName
                                                              : ""
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.filteredDishesByCategory(
                                              service,
                                              category
                                            ),
                                            function(dish, indexDish) {
                                              return _c(
                                                "v-row",
                                                {
                                                  key: indexDish,
                                                  staticStyle: {
                                                    "text-align": "left"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        "align-self": "center",
                                                        "margin-left": "12px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "align-self":
                                                              "center"
                                                          },
                                                          attrs: {
                                                            "no-gutters": ""
                                                          }
                                                        },
                                                        [
                                                          _c("v-col", [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  dish.description
                                                                )
                                                              )
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption restrictions"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatItemRestrictions(
                                                                      dish
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "align-self":
                                                              "center"
                                                          },
                                                          attrs: {
                                                            "no-gutters": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticStyle: {
                                                                "align-self":
                                                                  "center"
                                                              },
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            _vm._l(
                                                              dish.styleTags,
                                                              function(tag) {
                                                                return _c(
                                                                  "v-chip",
                                                                  {
                                                                    key:
                                                                      tag.name,
                                                                    attrs: {
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        tag.displayName
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticStyle: {
                                                                "align-self":
                                                                  "center"
                                                              },
                                                              attrs: {
                                                                cols: "auto"
                                                              }
                                                            },
                                                            _vm._l(
                                                              dish.dietaryTags,
                                                              function(tag) {
                                                                return _c(
                                                                  "v-chip",
                                                                  {
                                                                    key:
                                                                      tag.name,
                                                                    attrs: {
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        tag.displayName
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "col-auto",
                                                      staticStyle: {
                                                        "align-self": "center"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getPriceWithCommission(
                                                              dish.price
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            _c(
                              "v-row",
                              {
                                staticStyle: {
                                  "background-color": "#f7f3f3",
                                  margin: "12px -12px -12px -12px",
                                  "border-radius": "4px"
                                },
                                attrs: { align: "center", justify: "center" }
                              },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "col-auto" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.book(
                                              service.serviceType,
                                              _vm.chefselected
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Book")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(service.packages, function(pack) {
                  return _c(
                    "div",
                    { key: pack.id, staticClass: "margin-top-default" },
                    [
                      pack.enabled
                        ? _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                { attrs: { "align-self": "center" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "medium",
                                        "font-weight": "700",
                                        margin: "-12px -12px 0px -12px",
                                        padding: "12px",
                                        "border-radius": "4px",
                                        "background-color": "#f7f3f3"
                                      }
                                    },
                                    [
                                      _c("v-col", {
                                        attrs: { cols: "1", align: "start" }
                                      }),
                                      _c("v-col", { attrs: { cols: "10" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              "font-size": "medium",
                                              "font-weight": "700",
                                              margin: "-12px -12px 0px -12px",
                                              padding: "12px",
                                              "border-radius": "4px",
                                              "background-color": "#f7f3f3"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                service.serviceType.displayName
                                              ) +
                                                ": " +
                                                _vm._s(pack.name) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatPackageMinMaxGuests(
                                                    pack
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1", align: "end" } },
                                        [
                                          _vm._v(
                                            " $" +
                                              _vm._s(pack.price) +
                                              " per person "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.packageCategoriesSorted(pack),
                                    function(packageCategory, indexCat) {
                                      return _c(
                                        "div",
                                        {
                                          key: packageCategory.name,
                                          staticClass: "margin-top-default"
                                        },
                                        [
                                          _vm.filteredDishesByCategory(
                                            service,
                                            packageCategory.itemCategory
                                          ).length > 0
                                            ? _c(
                                                "div",
                                                [
                                                  indexCat != 0
                                                    ? _c("v-divider", {
                                                        staticStyle: {
                                                          "margin-top": "12px",
                                                          "margin-bottom":
                                                            "12px"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                align: "start"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "primary--text",
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline",
                                                                    "text-align":
                                                                      "left",
                                                                    "font-weight":
                                                                      "bold"
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        packageCategory
                                                                          .itemCategory
                                                                          .name
                                                                      ) + " "
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "caption restrictions"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.formatPackageCategorySelection(
                                                                            packageCategory
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c("v-col", {
                                                            staticStyle: {
                                                              "align-self":
                                                                "center"
                                                            },
                                                            attrs: { cols: "2" }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._l(
                                                    _vm.filteredDishesByCategoryForPackages(
                                                      service,
                                                      packageCategory
                                                    ),
                                                    function(dish, indexDish) {
                                                      return _c(
                                                        "v-row",
                                                        {
                                                          key: indexDish,
                                                          staticStyle: {
                                                            "text-align": "left"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticStyle: {
                                                                "align-self":
                                                                  "center",
                                                                "margin-left":
                                                                  "12px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticStyle: {
                                                                    "align-self":
                                                                      "center"
                                                                  },
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-col", [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          dish.description
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "caption restrictions"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatItemRestrictions(
                                                                              dish
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticStyle: {
                                                                    "align-self":
                                                                      "center"
                                                                  },
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticStyle: {
                                                                        "align-self":
                                                                          "center"
                                                                      },
                                                                      attrs: {
                                                                        cols:
                                                                          "auto"
                                                                      }
                                                                    },
                                                                    _vm._l(
                                                                      dish.styleTags,
                                                                      function(
                                                                        tag
                                                                      ) {
                                                                        return _c(
                                                                          "v-chip",
                                                                          {
                                                                            key:
                                                                              tag.name,
                                                                            attrs: {
                                                                              "x-small":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                tag.displayName
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticStyle: {
                                                                        "align-self":
                                                                          "center"
                                                                      },
                                                                      attrs: {
                                                                        cols:
                                                                          "auto"
                                                                      }
                                                                    },
                                                                    _vm._l(
                                                                      dish.dietaryTags,
                                                                      function(
                                                                        tag
                                                                      ) {
                                                                        return _c(
                                                                          "v-chip",
                                                                          {
                                                                            key:
                                                                              tag.name,
                                                                            attrs: {
                                                                              "x-small":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                tag.displayName
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          packageCategory.comment
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        "align-self": "center",
                                                        "margin-left": "12px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "padding-default comment"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                packageCategory.comment
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        "background-color": "#f7f3f3",
                                        margin: "12px -12px -12px -12px",
                                        "border-radius": "4px"
                                      },
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "col-auto" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.book(
                                                    service.serviceType,
                                                    _vm.chefselected,
                                                    pack
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Book")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }