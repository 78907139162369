var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h2", [_vm._v("Clients")]),
      _c(
        "v-row",
        {
          staticStyle: { "align-items": "baseline" },
          attrs: { "no-gutters": "" }
        },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search by email, firstname, lastname, phone or address",
              "single-line": "",
              "hide-details": ""
            },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchClients($event)
              }
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "margin-left-right-default",
              attrs: { align: "center", outlined: "" },
              on: { click: _vm.searchClients }
            },
            [_vm._v("Search")]
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          loading: _vm.loading,
          "multi-sort": true,
          items: _vm.clients,
          "hide-default-footer": false,
          expanded: _vm.expanded,
          "item-key": "id",
          "show-expand": "",
          "server-items-length": _vm.totalItems,
          options: _vm.options,
          "footer-props": {
            "items-per-page-options": [1, 2, 3, 10, 20, 30, 40, 50]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-col",
                  { staticStyle: { padding: "0px" } },
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" } },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  path: "/" + item.id + "/account/overview"
                                })
                              }
                            }
                          },
                          [_vm._v(" mdi-pencil ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: item.loading,
                          disabled: item.loading,
                          icon: ""
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteUser(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-delete ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "expanded-item",
            fn: function(ref) {
              var headers = ref.headers
              var item = ref.item
              return [
                _c(
                  "td",
                  {
                    staticClass: " v-data-table__mobile-row caption",
                    staticStyle: { "white-space": "pre-wrap" }
                  },
                  [_vm._v(_vm._s(item.details))]
                ),
                _c(
                  "td",
                  {
                    staticClass: "v-data-table__mobile-row caption",
                    staticStyle: { "white-space": "pre-wrap" },
                    attrs: { colspan: headers.length - 1 }
                  },
                  [_vm._v(_vm._s(item.details2))]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }