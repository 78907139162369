var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chef && _vm.serviceSelectedCd !== undefined
    ? _c(
        "div",
        { staticClass: "menu" },
        [
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.data.gallery,
                callback: function($$v) {
                  _vm.$set(_vm.data, "gallery", $$v)
                },
                expression: "data.gallery"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.data.gallery = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "gallery" },
                    _vm._l(_vm.chef.photos, function(src, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "pic",
                          on: {
                            click: function($event) {
                              _vm.data.photoDetails = true
                              _vm.data.photoIndex = index
                              _vm.$log(_vm.data.photoIndex)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "gallery-pic",
                            attrs: { src: src.photoPath + src.photoFilename }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                "content-class": "caroussel-dialog-fullscreen",
                "hide-overlay": ""
              },
              model: {
                value: _vm.data.photoDetails,
                callback: function($$v) {
                  _vm.$set(_vm.data, "photoDetails", $$v)
                },
                expression: "data.photoDetails"
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { position: "absolute", "z-index": "1" },
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.data.photoDetails = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _vm.chef
                ? _c(
                    "v-carousel",
                    {
                      staticClass: "caroussel-fullscreen",
                      attrs: {
                        "show-arrows": "",
                        "hide-delimiters": "",
                        height: "100%"
                      },
                      model: {
                        value: _vm.data.photoIndex,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "photoIndex", $$v)
                        },
                        expression: "data.photoIndex"
                      }
                    },
                    _vm._l(_vm.chef.photos, function(photo, index) {
                      return _c("v-carousel-item", {
                        key: index,
                        attrs: {
                          contain: "",
                          src: photo.photoPath + photo.photoFilename
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.chef
            ? _c(
                "v-carousel",
                {
                  staticClass: "caroussel",
                  attrs: {
                    "show-arrows": "",
                    "hide-delimiters": "",
                    height: "250px"
                  }
                },
                [
                  _vm._l(_vm.chef.photos, function(photo, index) {
                    return _c("v-carousel-item", {
                      key: index,
                      attrs: { src: photo.photoPath + photo.photoFilename },
                      on: {
                        click: function($event) {
                          _vm.data.gallery = true
                        }
                      }
                    })
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "chefSearchTop",
                      staticStyle: { width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.data.gallery = true
                        }
                      }
                    },
                    [
                      _c("v-col", [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-md-12 white--text",
                              staticStyle: { visibility: "visible" }
                            },
                            [
                              _vm.chef
                                ? _c(
                                    "h2",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _vm._v(
                                        " Select your dishes from " +
                                          _vm._s(_vm.chef.profile.profileName) +
                                          "´s menu "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "chefSearchBottom ",
                      staticStyle: { padding: "0px" },
                      attrs: { align: "end", justify: "end", "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-black-30",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  _vm.data.gallery = true
                                }
                              }
                            },
                            [_vm._v(" Gallery ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-container",
                {
                  staticStyle: { "margin-top": "-48px" },
                  attrs: { fuild: "" }
                },
                [
                  _vm.chef
                    ? _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                "margin-left": "12px",
                                "margin-right": "12px"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "col-auto",
                                  attrs: { align: "start" }
                                },
                                [
                                  _vm.chef.profile.photoPath &&
                                  _vm.chef.profile.photoFilename
                                    ? _c(
                                        "v-avatar",
                                        { attrs: { size: "124" } },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              "object-fit": "cover"
                                            },
                                            attrs: {
                                              src:
                                                _vm.chef.profile.photoPath +
                                                _vm.chef.profile.photoFilename,
                                              alt: _vm.chef.profile.profileName
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    "align-self": "center",
                                    justify: "start"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-row",
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        "font-size": "x-large"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.chef.profile.profileName) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-row",
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        "font-size": "large"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.chef.location) + " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "col-auto",
                                  attrs: {
                                    align: "end",
                                    "align-self": "center"
                                  }
                                },
                                [
                                  _c("star-rating", {
                                    attrs: {
                                      "read-only": "",
                                      increment: 0.5,
                                      "star-size": 35,
                                      align: "end",
                                      "align-self": "center",
                                      inline: ""
                                    },
                                    model: {
                                      value: _vm.rating,
                                      callback: function($$v) {
                                        _vm.rating = $$v
                                      },
                                      expression: "rating"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                "margin-left": "12px",
                                "margin-right": "12px"
                              },
                              attrs: {
                                "align-content": "center",
                                align: "center",
                                justify: "center"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: { click: _vm.toggleChef }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.data.aboutChef ? "less" : "more"
                                      ) +
                                      " about the chef "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.chef && _vm.data.aboutChef
                ? _c(
                    "v-container",
                    [_c("about-chef", { attrs: { chef: _vm.chef } })],
                    1
                  )
                : _vm._e(),
              _c(
                "v-container",
                [
                  _c("v-row", [
                    _vm.packageSelectedCd !== undefined &&
                    _vm.serviceSelectedCd !== undefined
                      ? _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-8" },
                          [
                            _c(
                              "v-card",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.serviceSelectedCd !== undefined,
                                    expression: "serviceSelectedCd!==undefined"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { "align-self": "center" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticStyle: {
                                          "text-align": "center",
                                          "font-size": "medium",
                                          "font-weight": "700",
                                          margin: "-12px -12px 0px -12px",
                                          padding: "12px",
                                          "border-radius": "4px",
                                          "background-color": "#f7f3f3"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: { cols: "8", align: "start" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                  "font-size": "medium",
                                                  "font-weight": "700",
                                                  margin:
                                                    "-12px -12px 0px -12px",
                                                  padding: "12px",
                                                  "border-radius": "4px",
                                                  "background-color": "#f7f3f3"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.serviceSelectedCd
                                                      .serviceType.displayName
                                                  ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm.packageSelectedCd.name
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatPackageMinMaxGuests(
                                                        _vm.packageSelectedCd
                                                      )
                                                    )
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: { cols: "4", align: "end" }
                                          },
                                          [
                                            _vm._v(
                                              " $" +
                                                _vm._s(
                                                  _vm.packageSelectedCd.price
                                                ) +
                                                " per person "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.packageCategoriesSorted(
                                        _vm.packageSelectedCd
                                      ),
                                      function(packageCategory) {
                                        return _c(
                                          "div",
                                          {
                                            key: packageCategory.name,
                                            staticClass: "margin-top-default"
                                          },
                                          [
                                            _vm.filteredDishesByCategoryForPackages(
                                              _vm.serviceSelectedCd,
                                              packageCategory
                                            ).length > 0
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "margin-bottom",
                                                                attrs: {
                                                                  align: "start"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text ",
                                                                    staticStyle: {
                                                                      display:
                                                                        "inline",
                                                                      "text-align":
                                                                        "left",
                                                                      "font-weight":
                                                                        "bold"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          packageCategory
                                                                            .itemCategory
                                                                            .name
                                                                        ) + " "
                                                                      )
                                                                    ]),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "caption restrictions"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatPackageCategorySelection(
                                                                              packageCategory
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c("v-col", {
                                                              attrs: {
                                                                align: "end"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "no-margin-padding margin-bottom"
                                                      },
                                                      _vm._l(
                                                        _vm.filteredDishesByCategoryForPackages(
                                                          _vm.serviceSelectedCd,
                                                          packageCategory
                                                        ),
                                                        function(
                                                          dish,
                                                          indexDish
                                                        ) {
                                                          return _c(
                                                            "v-row",
                                                            {
                                                              key: indexDish,
                                                              staticStyle: {
                                                                "text-align":
                                                                  "left"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "no-margin-padding",
                                                                      attrs: {
                                                                        disabled: _vm.checkAllInclusive(
                                                                          _vm.serviceSelectedCd,
                                                                          packageCategory
                                                                        ),
                                                                        value: dish,
                                                                        multiple: true,
                                                                        "hide-details":
                                                                          "auto",
                                                                        label:
                                                                          dish.description
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectItem(
                                                                            _vm.serviceSelectedCd,
                                                                            packageCategory
                                                                          )
                                                                        }
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "label",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        staticStyle: {
                                                                                          "align-self":
                                                                                            "center"
                                                                                        },
                                                                                        attrs: {
                                                                                          "no-gutters":
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-col",
                                                                                          {
                                                                                            staticClass:
                                                                                              "black--text"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  dish.description
                                                                                                ) +
                                                                                                " "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        staticStyle: {
                                                                                          "align-self":
                                                                                            "center"
                                                                                        },
                                                                                        attrs: {
                                                                                          "no-gutters":
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-col",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "align-self":
                                                                                                "center"
                                                                                            },
                                                                                            attrs: {
                                                                                              cols:
                                                                                                "auto"
                                                                                            }
                                                                                          },
                                                                                          _vm._l(
                                                                                            dish.styleTags,
                                                                                            function(
                                                                                              tag
                                                                                            ) {
                                                                                              return _c(
                                                                                                "v-chip",
                                                                                                {
                                                                                                  key:
                                                                                                    tag.name,
                                                                                                  attrs: {
                                                                                                    "x-small":
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      tag.displayName
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "v-col",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "align-self":
                                                                                                "center"
                                                                                            },
                                                                                            attrs: {
                                                                                              cols:
                                                                                                "auto"
                                                                                            }
                                                                                          },
                                                                                          _vm._l(
                                                                                            dish.dietaryTags,
                                                                                            function(
                                                                                              tag
                                                                                            ) {
                                                                                              return _c(
                                                                                                "v-chip",
                                                                                                {
                                                                                                  key:
                                                                                                    tag.name,
                                                                                                  attrs: {
                                                                                                    "x-small":
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      tag.displayName
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .$root
                                                                            .dishesSelected[
                                                                            packageCategory
                                                                              .itemCategory
                                                                              .name
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .$root
                                                                              .dishesSelected,
                                                                            packageCategory
                                                                              .itemCategory
                                                                              .name,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "$root.dishesSelected[packageCategory.itemCategory.name]"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-col", {
                                                                staticClass:
                                                                  "col-auto",
                                                                attrs: {
                                                                  "align-self":
                                                                    "center"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    packageCategory.comment
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "left"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticStyle: {
                                                                  "align-self":
                                                                    "center"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      " comment"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          packageCategory.comment
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors[
                                                              packageCategory
                                                                .itemCategory
                                                                .name
                                                            ]
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-divider")
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _vm.serviceSelectedCd.packages &&
                            _vm.serviceSelectedCd.packages.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "container" },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "inline" } },
                                      [_vm._v("See package : ")]
                                    ),
                                    _vm._l(_vm.chef.service, function(
                                      service,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticStyle: { display: "inline" }
                                        },
                                        _vm._l(service.packages, function(
                                          pack,
                                          index2
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index2,
                                              staticStyle: { display: "inline" }
                                            },
                                            [
                                              _vm.chef &&
                                              _vm.$root.serviceSelected !=
                                                null &&
                                              _vm.$root.serviceSelected.name ===
                                                service.serviceType.name &&
                                              _vm.$root.packageSelected.id !==
                                                pack.id
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass: "container",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changePackage(
                                                            pack
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(pack.name))]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.chef.service.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "container" },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "inline" } },
                                      [_vm._v("Change type of service : ")]
                                    ),
                                    _vm._l(_vm.chef.service, function(
                                      service,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticStyle: { display: "inline" }
                                        },
                                        [
                                          (_vm.chef &&
                                            _vm.$root.serviceSelected != null &&
                                            _vm.$root.serviceSelected.name !==
                                              service.serviceType.name) ||
                                          _vm.$root.packageSelected !==
                                            undefined
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "container",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.changeservice(
                                                        service.serviceType
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        service.serviceType
                                                          .displayName
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-8" },
                          [
                            _c(
                              "v-card",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.serviceSelectedCd !== undefined,
                                    expression: "serviceSelectedCd!==undefined"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { "align-self": "center" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "center",
                                          "font-size": "medium",
                                          "font-weight": "700",
                                          margin: "-12px -12px 0px -12px",
                                          padding: "12px",
                                          "border-radius": "4px",
                                          "background-color": "#f7f3f3"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.serviceSelectedCd.serviceType
                                              .displayName
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.serviceSelectedCd.itemCategories,
                                      function(category) {
                                        return _c(
                                          "div",
                                          {
                                            key: category.name,
                                            staticClass: "margin-top-default"
                                          },
                                          [
                                            _vm.filteredDishesByCategory(
                                              _vm.serviceSelectedCd,
                                              category
                                            ).length > 0
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "margin-bottom",
                                                                attrs: {
                                                                  align: "start"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text ",
                                                                    staticStyle: {
                                                                      display:
                                                                        "inline",
                                                                      "text-align":
                                                                        "left",
                                                                      "font-weight":
                                                                        "bold"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          category.name
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm.$root
                                                                  .dishesSelected[
                                                                  category.name
                                                                ] !== undefined
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.clearSelection(
                                                                              category
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            category
                                                              .categoryType
                                                              .name == "KID"
                                                              ? _c(
                                                                  "v-col",
                                                                  {
                                                                    staticStyle: {
                                                                      "align-self":
                                                                        "center"
                                                                    },
                                                                    attrs: {
                                                                      align:
                                                                        "end",
                                                                      cols: "2"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " $ " +
                                                                        _vm._s(
                                                                          category.pricingType ==
                                                                            undefined ||
                                                                            category
                                                                              .pricingType
                                                                              .displayName !=
                                                                              "UNIT"
                                                                            ? "Per Kids"
                                                                            : category
                                                                                .pricingType
                                                                                .displayName
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-col",
                                                                  {
                                                                    staticStyle: {
                                                                      "align-self":
                                                                        "center"
                                                                    },
                                                                    attrs: {
                                                                      align:
                                                                        "end",
                                                                      cols: "2"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " $ " +
                                                                        _vm._s(
                                                                          category.pricingType !=
                                                                            undefined &&
                                                                            category.pricingType !==
                                                                              null
                                                                            ? category
                                                                                .pricingType
                                                                                .displayName
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    category.multiSelect !==
                                                    true
                                                      ? _c(
                                                          "v-radio-group",
                                                          {
                                                            staticClass:
                                                              "no-margin-padding",
                                                            model: {
                                                              value:
                                                                _vm.$root
                                                                  .dishesSelected[
                                                                  category.name
                                                                ],
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.$root
                                                                    .dishesSelected,
                                                                  category.name,
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "$root.dishesSelected[category.name]"
                                                            }
                                                          },
                                                          _vm._l(
                                                            _vm.filteredDishesByCategory(
                                                              _vm.serviceSelectedCd,
                                                              category
                                                            ),
                                                            function(
                                                              dish,
                                                              indexDish
                                                            ) {
                                                              return _c(
                                                                "v-row",
                                                                {
                                                                  key: indexDish,
                                                                  staticStyle: {
                                                                    "text-align":
                                                                      "left"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        "align-self":
                                                                          "center"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          staticClass:
                                                                            "no-margin-padding",
                                                                          staticStyle: {
                                                                            border:
                                                                              "0px"
                                                                          },
                                                                          attrs: {
                                                                            value: dish,
                                                                            "hide-details":
                                                                              "auto",
                                                                            label:
                                                                              dish.description
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeSelected(
                                                                                category,
                                                                                dish
                                                                              )
                                                                            }
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "label",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "v-row",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "align-self":
                                                                                                "center"
                                                                                            },
                                                                                            attrs: {
                                                                                              "no-gutters":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "black--text"
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        dish.description
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "caption restrictions"
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.formatItemRestrictions(
                                                                                                          dish
                                                                                                        )
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "v-row",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "align-self":
                                                                                                "center"
                                                                                            },
                                                                                            attrs: {
                                                                                              "no-gutters":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  "align-self":
                                                                                                    "center"
                                                                                                },
                                                                                                attrs: {
                                                                                                  cols:
                                                                                                    "auto"
                                                                                                }
                                                                                              },
                                                                                              _vm._l(
                                                                                                dish.styleTags,
                                                                                                function(
                                                                                                  tag
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "v-chip",
                                                                                                    {
                                                                                                      key:
                                                                                                        tag.name,
                                                                                                      attrs: {
                                                                                                        "x-small":
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          tag.displayName
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  "align-self":
                                                                                                    "center"
                                                                                                },
                                                                                                attrs: {
                                                                                                  cols:
                                                                                                    "auto"
                                                                                                }
                                                                                              },
                                                                                              _vm._l(
                                                                                                dish.dietaryTags,
                                                                                                function(
                                                                                                  tag
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "v-chip",
                                                                                                    {
                                                                                                      key:
                                                                                                        tag.name,
                                                                                                      attrs: {
                                                                                                        "x-small":
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          tag.displayName
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              1
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "col-auto",
                                                                      attrs: {
                                                                        "align-self":
                                                                          "center"
                                                                      }
                                                                    },
                                                                    [
                                                                      category
                                                                        .pricingType
                                                                        .name ===
                                                                      "UNIT"
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "padding-left-right-default ",
                                                                              staticStyle: {
                                                                                display:
                                                                                  "inline-block",
                                                                                width:
                                                                                  "80px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  staticStyle: {
                                                                                    display:
                                                                                      "inline-flex"
                                                                                  },
                                                                                  attrs: {
                                                                                    id:
                                                                                      "qty_" +
                                                                                      indexDish,
                                                                                    dense:
                                                                                      "",
                                                                                    label:
                                                                                      "qty",
                                                                                    type:
                                                                                      "number",
                                                                                    "hide-details": true,
                                                                                    min: 0,
                                                                                    max:
                                                                                      dish.max
                                                                                  },
                                                                                  on: {
                                                                                    input: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.qtyChange(
                                                                                        dish,
                                                                                        category,
                                                                                        $event
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      dish.qty,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        dish,
                                                                                        "qty",
                                                                                        _vm._n(
                                                                                          $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "dish.qty"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getPriceWithCommission(
                                                                              dish.price
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "no-margin-padding margin-bottom"
                                                          },
                                                          _vm._l(
                                                            _vm.filteredDishesByCategory(
                                                              _vm.serviceSelectedCd,
                                                              category
                                                            ),
                                                            function(
                                                              dish,
                                                              indexDish
                                                            ) {
                                                              return _c(
                                                                "v-row",
                                                                {
                                                                  key: indexDish,
                                                                  staticStyle: {
                                                                    "text-align":
                                                                      "left"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "no-margin-padding",
                                                                          attrs: {
                                                                            value: dish,
                                                                            "hide-details":
                                                                              "auto",
                                                                            label:
                                                                              dish.description,
                                                                            multiple: true
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeSelected(
                                                                                category,
                                                                                dish,
                                                                                $event
                                                                              )
                                                                            }
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "label",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "v-row",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "align-self":
                                                                                                "center"
                                                                                            },
                                                                                            attrs: {
                                                                                              "no-gutters":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "black--text"
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        dish.description
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "caption restrictions"
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.formatItemRestrictions(
                                                                                                          dish
                                                                                                        )
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "v-row",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "align-self":
                                                                                                "center"
                                                                                            },
                                                                                            attrs: {
                                                                                              "no-gutters":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  "align-self":
                                                                                                    "center"
                                                                                                },
                                                                                                attrs: {
                                                                                                  cols:
                                                                                                    "auto"
                                                                                                }
                                                                                              },
                                                                                              _vm._l(
                                                                                                dish.styleTags,
                                                                                                function(
                                                                                                  tag
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "v-chip",
                                                                                                    {
                                                                                                      key:
                                                                                                        tag.name,
                                                                                                      attrs: {
                                                                                                        "x-small":
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          tag.displayName
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  "align-self":
                                                                                                    "center"
                                                                                                },
                                                                                                attrs: {
                                                                                                  cols:
                                                                                                    "auto"
                                                                                                }
                                                                                              },
                                                                                              _vm._l(
                                                                                                dish.dietaryTags,
                                                                                                function(
                                                                                                  tag
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "v-chip",
                                                                                                    {
                                                                                                      key:
                                                                                                        tag.name,
                                                                                                      attrs: {
                                                                                                        "x-small":
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          tag.displayName
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              1
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .$root
                                                                                .dishesSelected[
                                                                                category
                                                                                  .name
                                                                              ],
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .$root
                                                                                  .dishesSelected,
                                                                                category.name,
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "$root.dishesSelected[category.name]"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "col-auto",
                                                                      attrs: {
                                                                        "align-self":
                                                                          "center"
                                                                      }
                                                                    },
                                                                    [
                                                                      category
                                                                        .pricingType
                                                                        .name ===
                                                                      "UNIT"
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "padding-left-right-default ",
                                                                              staticStyle: {
                                                                                display:
                                                                                  "inline-block",
                                                                                width:
                                                                                  "80px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  staticStyle: {
                                                                                    display:
                                                                                      "inline-flex"
                                                                                  },
                                                                                  attrs: {
                                                                                    id:
                                                                                      "qty_" +
                                                                                      indexDish,
                                                                                    dense:
                                                                                      "",
                                                                                    label:
                                                                                      "qty",
                                                                                    type:
                                                                                      "number",
                                                                                    "hide-details": true,
                                                                                    min: 0,
                                                                                    max:
                                                                                      dish.max
                                                                                  },
                                                                                  on: {
                                                                                    input: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.qtyChange(
                                                                                        dish,
                                                                                        category
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      dish.qty,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        dish,
                                                                                        "qty",
                                                                                        _vm._n(
                                                                                          $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "dish.qty"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getPriceWithCommission(
                                                                              dish.price
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                    _c("v-divider")
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _vm.serviceSelectedCd.packages &&
                            _vm.serviceSelectedCd.packages.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "container" },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "inline" } },
                                      [_vm._v("See package : ")]
                                    ),
                                    _vm._l(_vm.chef.service, function(
                                      service,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticStyle: { display: "inline" }
                                        },
                                        _vm._l(service.packages, function(
                                          pack,
                                          index2
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index2,
                                              staticStyle: { display: "inline" }
                                            },
                                            [
                                              _vm.chef &&
                                              _vm.$root.serviceSelected !=
                                                null &&
                                              _vm.$root.serviceSelected.name ===
                                                service.serviceType.name
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass: "container",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changePackage(
                                                            pack
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(pack.name))]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.chef.service.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "container" },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "inline" } },
                                      [_vm._v("Change type of service : ")]
                                    ),
                                    _vm._l(_vm.chef.service, function(
                                      service,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticStyle: { display: "inline" }
                                        },
                                        [
                                          _vm.chef &&
                                          _vm.$root.serviceSelected != null &&
                                          _vm.$root.serviceSelected.name !==
                                            service.serviceType.name
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "container",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.changeservice(
                                                        service.serviceType
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        service.serviceType
                                                          .displayName
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-md-4" },
                      [
                        _c(
                          "v-card",
                          {
                            staticStyle: { "margin-bottom": "12px" },
                            attrs: { dark: "" }
                          },
                          [
                            _c("v-container", [
                              _c("h2", [_vm._v("Booking Details")])
                            ]),
                            _c("v-divider"),
                            _c(
                              "div",
                              { staticClass: "background-black-light" },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        id: "address",
                                        "no-filter": true,
                                        dark: "",
                                        clearable: "",
                                        items: _vm.$root.formData.locationItems,
                                        loading: _vm.isLoading,
                                        "search-input": _vm.lookupAddress,
                                        color: "white",
                                        "item-text": "place_name",
                                        label: "Location",
                                        placeholder:
                                          "Start typing your address",
                                        "prepend-icon": "mdi-map-marker",
                                        "append-icon": "",
                                        "return-object": "",
                                        "auto-select-first": "",
                                        "hide-no-data": "",
                                        dense: "",
                                        rules: [
                                          function(v) {
                                            return !!v || "Location is required"
                                          },
                                          function(v) {
                                            return (
                                              this$1.checkRange()[0] ||
                                              "Chef out of range by " +
                                                this$1.checkRange()[1] +
                                                " Km"
                                            )
                                          }
                                        ]
                                      },
                                      on: {
                                        "update:searchInput": function($event) {
                                          _vm.lookupAddress = $event
                                        },
                                        "update:search-input": function(
                                          $event
                                        ) {
                                          _vm.lookupAddress = $event
                                        },
                                        change: _vm.getLocation
                                      },
                                      model: {
                                        value: _vm.$root.formData.location,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$root.formData,
                                            "location",
                                            $$v
                                          )
                                        },
                                        expression: "$root.formData.location"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "min-width": "auto"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            id: "date",
                                                            dense: "",
                                                            clearable: "",
                                                            dark: "",
                                                            label: "Date",
                                                            "prepend-icon":
                                                              "mdi-calendar-range",
                                                            readonly: "",
                                                            rules: [
                                                              function(v) {
                                                                return (
                                                                  !!v ||
                                                                  "Date is required"
                                                                )
                                                              }
                                                            ]
                                                          },
                                                          on: {
                                                            "click:clear": function(
                                                              $event
                                                            ) {
                                                              _vm.$root.formData.date = undefined
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.$root.formData
                                                                .date,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.$root
                                                                  .formData,
                                                                "date",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "$root.formData.date"
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3093894798
                                        ),
                                        model: {
                                          value: _vm.datepicker,
                                          callback: function($$v) {
                                            _vm.datepicker = $$v
                                          },
                                          expression: "datepicker"
                                        }
                                      },
                                      [
                                        _c("v-date-picker", {
                                          attrs: {
                                            color: "primary",
                                            "header-color": "primary",
                                            min: _vm.tomorrow,
                                            "allowed-dates":
                                              _vm.checkAvailableDate,
                                            events: _vm.functionEvents
                                          },
                                          on: {
                                            input: function($event) {
                                              _vm.datepicker = false
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function() {
                                                  return undefined
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            157076738
                                          ),
                                          model: {
                                            value: _vm.$root.formData.date,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.$root.formData,
                                                "date",
                                                $$v
                                              )
                                            },
                                            expression: "$root.formData.date"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        id: "time",
                                        dense: "",
                                        dark: "",
                                        clearable: "",
                                        "prepend-icon": "mdi-clock",
                                        items: _vm.availableTimes,
                                        label: "Time",
                                        rules: [
                                          function(v) {
                                            return !!v || "Time is required"
                                          }
                                        ]
                                      },
                                      model: {
                                        value: _vm.$root.formData.time,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$root.formData,
                                            "time",
                                            $$v
                                          )
                                        },
                                        expression: "$root.formData.time"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        id: "guests",
                                        dark: "",
                                        dense: "",
                                        "prepend-icon": "mdi-account-multiple",
                                        label: "Guests",
                                        type: "number",
                                        min:
                                          _vm.serviceSelectedCd.minGuests !==
                                          undefined
                                            ? _vm.serviceSelectedCd.minGuests
                                            : 2,
                                        max:
                                          _vm.serviceSelectedCd.maxGuests !==
                                          undefined
                                            ? _vm.serviceSelectedCd.maxGuests
                                            : 20,
                                        rules: [
                                          function(v) {
                                            return (
                                              !!v ||
                                              "Number of guests is required"
                                            )
                                          },
                                          function(v) {
                                            return (
                                              _vm.serviceSelectedCd
                                                .minGuests === undefined ||
                                              v >=
                                                _vm.serviceSelectedCd
                                                  .minGuests ||
                                              "Minimum required is " +
                                                _vm.serviceSelectedCd.minGuests
                                            )
                                          }
                                        ]
                                      },
                                      model: {
                                        value: _vm.$root.nbGuests,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$root,
                                            "nbGuests",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "$root.nbGuests"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  [
                                    _c("v-textarea", {
                                      attrs: {
                                        id: "comment",
                                        "prepend-icon": "mdi-comment",
                                        dark: "",
                                        dense: "",
                                        label: "Comment",
                                        hint:
                                          "Tell the chef any allergy requirement or any additional information",
                                        placeholder:
                                          "Tell the chef any allergy requirement or any additional information"
                                      },
                                      model: {
                                        value: _vm.$root.comment,
                                        callback: function($$v) {
                                          _vm.$set(_vm.$root, "comment", $$v)
                                        },
                                        expression: "$root.comment"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          { attrs: { dark: "" } },
                          [
                            _c("v-container", [_c("h2", [_vm._v("Cart")])]),
                            _c(
                              "div",
                              { staticClass: "background-black-light" },
                              [
                                _vm.packageSelectedCd
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row",
                                            staticStyle: {
                                              width: "100%",
                                              margin: "0px"
                                            }
                                          },
                                          [
                                            _c("v-col", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.packageSelectedCd.name
                                                ) +
                                                  " x " +
                                                  _vm._s(_vm.$root.nbGuests)
                                              )
                                            ]),
                                            _vm.packageSelectedCd.price !==
                                              undefined &&
                                            _vm.$root.nbGuests != undefined
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "col-auto",
                                                    attrs: {
                                                      "align-self": "end"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.roundToXDigits(
                                                            _vm
                                                              .packageSelectedCd
                                                              .price *
                                                              _vm.$root
                                                                .nbGuests,
                                                            2
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          _vm.packageCategoriesSorted(
                                            _vm.packageSelectedCd
                                          ),
                                          function(packageCategory) {
                                            return _c(
                                              "div",
                                              { key: packageCategory.name },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "row",
                                                    staticStyle: {
                                                      margin: "0px"
                                                    }
                                                  },
                                                  [
                                                    _c("v-col", [
                                                      _vm._v(
                                                        _vm._s(
                                                          packageCategory
                                                            .itemCategory.name
                                                        )
                                                      )
                                                    ]),
                                                    _c("v-col", {
                                                      staticClass: "col-auto",
                                                      attrs: {
                                                        "align-self": "end"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  _vm.$root.dishesSelected[
                                                    packageCategory.itemCategory
                                                      .name
                                                  ],
                                                  function(dish, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "row",
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                          width: "100%",
                                                          margin: "0px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticStyle: {
                                                              "text-indent":
                                                                "10px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                dish.description
                                                              ) + " "
                                                            )
                                                          ]
                                                        ),
                                                        _c("v-col", {
                                                          staticClass:
                                                            "col-auto",
                                                          attrs: {
                                                            "align-self": "end"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _c(
                                      "div",
                                      _vm._l(
                                        _vm.serviceSelectedCd.itemCategories,
                                        function(category) {
                                          return _c(
                                            "div",
                                            { key: category.name },
                                            [
                                              _vm.$root.dishesSelected[
                                                category.name
                                              ] !== undefined &&
                                              _vm.totalCategory(category) > 0
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row",
                                                        staticStyle: {
                                                          width: "100%",
                                                          margin: "0px"
                                                        }
                                                      },
                                                      [
                                                        _c("v-col", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.categoryTitle(
                                                                category
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "col-auto",
                                                            attrs: {
                                                              "align-self":
                                                                "end"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    _vm.totalCategory(
                                                                      category
                                                                    )
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    category.multiSelect ===
                                                    true
                                                      ? _c(
                                                          "div",
                                                          _vm._l(
                                                            _vm.$root
                                                              .dishesSelected[
                                                              category.name
                                                            ],
                                                            function(
                                                              dish,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "row",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "14px",
                                                                    width:
                                                                      "100%",
                                                                    margin:
                                                                      "0px"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticStyle: {
                                                                        "text-indent":
                                                                          "10px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.itemTitle(
                                                                            category,
                                                                            dish
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "col-auto",
                                                                      attrs: {
                                                                        "align-self":
                                                                          "end"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.formatPrice(
                                                                              _vm.totalItem(
                                                                                category,
                                                                                dish
                                                                              )
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    category.multiSelect !==
                                                    true
                                                      ? _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "14px",
                                                                width: "100%",
                                                                margin: "0px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticStyle: {
                                                                    "text-indent":
                                                                      "10px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.itemTitle(
                                                                        category,
                                                                        _vm
                                                                          .$root
                                                                          .dishesSelected[
                                                                          category
                                                                            .name
                                                                        ]
                                                                      )
                                                                    ) + " "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "col-auto",
                                                                  attrs: {
                                                                    "align-self":
                                                                      "end"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.formatPrice(
                                                                          _vm.totalItem(
                                                                            category,
                                                                            _vm
                                                                              .$root
                                                                              .dishesSelected[
                                                                              category
                                                                                .name
                                                                            ]
                                                                          )
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                _vm.minChef ? _c("v-divider") : _vm._e(),
                                _vm.minChef
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c("v-col", [
                                          _vm._v("Minimum order remaining")
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "col-auto",
                                            attrs: { "align-self": "end" }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.minChef) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.minChef
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "caption",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "The chef requires a minimum order amount of " +
                                                  _vm._s(
                                                    _vm.formatPrice(
                                                      _vm.getPriceWithCommission(
                                                        _vm.serviceSelectedCd
                                                          .minPrice
                                                      )
                                                    )
                                                  )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.waiterPrice ? _c("v-divider") : _vm._e(),
                                _vm.waiterPrice
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c("v-col", [_vm._v("Waiter")]),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "col-auto",
                                            attrs: { "align-self": "end" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.waiterPrice) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.waiterPrice
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "caption",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "The chef requires a wait person for this order"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.errorDishes ? _c("v-divider") : _vm._e(),
                                _vm.errorDishes
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { margin: "0px" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "error--text",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "Select a Starter or a Desert"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: {
                                  margin: "0px",
                                  "font-size": "18px"
                                }
                              },
                              [
                                _c("v-col", [_vm._v("Total")]),
                                _vm.total() !== undefined
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "col-auto",
                                        attrs: { "align-self": "end" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatPrice(_vm.total())
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0px" }
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { align: "center" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "margin-default",
                                        attrs: {
                                          outlined: "",
                                          color: "primary",
                                          disabled: !_vm.checkErrors(),
                                          id: "checkout"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.checkout()
                                          }
                                        }
                                      },
                                      [_vm._v("Check Out")]
                                    ),
                                    !_vm.checkErrors()
                                      ? _c(
                                          "div",
                                          { staticClass: "error--text " },
                                          [_vm._v("some errors are present")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }