<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
            v-model="selectedCountry"
            :items="filteredCountries"
            item-text="countryText"
            item-value="prefix"
            label="Select Country Prefix"
            return-object
            hide-no-data
            hide-selected
          >
          <template #selection="{ item }">
            <span class="fi margin-right-default" :class="`fi-${item.flag.toLowerCase()}`"></span>
            {{ item.countryText }}
          </template>
          <template #item="{ item }">
            <span class="fi margin-right-default" :class="`fi-${item.flag.toLowerCase()}`"></span>
            {{ item.countryText }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="phoneNumber"
          label="Phone Number"
          :prefix="selectedCountry ? selectedCountry.prefix : ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="smsText"
          label="SMS Text"
          rows="3"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="sendSMS">Send SMS</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="sheduledTask">Scheduled task</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'flag-icons/css/flag-icons.min.css';
export default {
  name: 'Tests',
  data() {
    return {
      phoneNumber: '',
      smsText: '',
      selectedCountry: null,
      searchTerm: '',
      countryPrefixes: [
        { prefix: '+61', name: 'Australia', flag: 'AU', countryText: '+61 Australia' },
        { prefix: '+55', name: 'Brazil', flag: 'BR', countryText: '+55 Brazil' },
        { prefix: '+86', name: 'China', flag: 'CN', countryText: '+86 China' },
        { prefix: '+33', name: 'France', flag: 'FR', countryText: '+33 France' },
        { prefix: '+49', name: 'Germany', flag: 'DE', countryText: '+49 Germany' },
        { prefix: '+91', name: 'India', flag: 'IN', countryText: '+91 India' },
        { prefix: '+81', name: 'Japan', flag: 'JP', countryText: '+81 Japan' },
        { prefix: '+27', name: 'South Africa', flag: 'ZA', countryText: '+27 South Africa' },
        { prefix: '+44', name: 'United Kingdom', flag: 'GB', countryText: '+44 United Kingdom' },
        { prefix: '+1', name: 'United States', flag: 'US', countryText: '+1 United States' }
      ]
    };
  },
  computed: {
    filteredCountries() {
      return this.countryPrefixes.filter(country => 
        country.countryText.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  methods: {
    sendSMS() {
      // Implement SMS sending logic here
      console.log('Sending SMS to:', this.selectedCountry.prefix + this.phoneNumber);
      console.log('SMS Text:', this.smsText);
        this.$store.dispatch('sendSMS', { phoneNumber: this.selectedCountry.prefix + this.phoneNumber, smsText: this.smsText })
            .then(() => {
                this.$store.dispatch('showSnackbar', { text: 'SMS sent successfully', color: 'primary' });
            })
            .catch(error => {
                console.error('Error sending SMS:', error);
            });
    },
    sheduledTask() {
      // Implement SMS sending logic here
        this.$store.dispatch('startScheduledTask')
            .then(() => {
                this.$store.dispatch('showSnackbar', { text: 'Scheduled Task started successfully successfully', color: 'primary' });
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    
  }
};
</script>

<style scoped>
.flag-icon {
  width: 20px;
  height: auto;
  margin-right: 8px;
}
</style>
