var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h2", [_vm._v("Potential Customers")]),
      _c(
        "v-row",
        {
          staticStyle: { "align-items": "baseline" },
          attrs: { "no-gutters": "" }
        },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search by email or address",
              "single-line": "",
              "hide-details": ""
            },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchPotentialCustomers($event)
              }
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "margin-left-right-default",
              attrs: { align: "center", outlined: "" },
              on: { click: _vm.searchPotentialCustomers }
            },
            [_vm._v("Search")]
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          loading: _vm.loading,
          items: _vm.potentialCustomers,
          "hide-default-footer": false,
          "server-items-length": _vm.totalItems,
          options: _vm.options,
          "footer-props": {
            "items-per-page-options": [1, 2, 3, 10, 20, 30, 40, 50]
          }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.creationDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.creationDate)) + " ")
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }