var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Change Password")
                ])
              ]),
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.changePWD()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { staticClass: "no-padding-top-bottom" },
                        [
                          _c(
                            "v-row",
                            [
                              _vm.error
                                ? _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("div", { staticClass: "error--text" }, [
                                      _vm._v(_vm._s(_vm.error))
                                    ])
                                  ])
                                : _vm._e(),
                              !_vm.resetPassword
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Current Password",
                                          type: "password",
                                          required: "",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: _vm.passwordRules,
                                      label: "Password",
                                      type: "password",
                                      required: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.newPassword,
                                      callback: function($$v) {
                                        _vm.newPassword = $$v
                                      },
                                      expression: "newPassword"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: _vm.passwordMatchRules,
                                      label: "Repeat Password",
                                      type: "password",
                                      required: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.newPasswordRepeat,
                                      callback: function($$v) {
                                        _vm.newPasswordRepeat = $$v
                                      },
                                      expression: "newPasswordRepeat"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.closeAction }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.valid,
                            type: "submit",
                            loading: _vm.loading
                          }
                        },
                        [_vm._v("Change Password")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }