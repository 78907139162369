var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("h2", [_vm._v("Menus")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "margin-left-right-default",
                  attrs: { id: "btn_cancel", color: "primary", outlined: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "margin-right-default",
                  attrs: {
                    id: "btn_save",
                    color: "primary",
                    disabled: _vm.errorGlobal,
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      _vm.validateConfiguration() != true ? null : _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "formService",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _vm.serviceTypes
                ? _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9" } },
                        [
                          _c("v-select", {
                            attrs: {
                              id: "service",
                              items: _vm.serviceTypesModified,
                              "return-object": true,
                              label: "Service",
                              rules: [
                                function(v) {
                                  return (
                                    (!!v && v.length !== 0) ||
                                    "You must select one Type of Service!"
                                  )
                                }
                              ],
                              required: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " + _vm._s(item.displayName) + " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "margin-default",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          item.name === "CLASSIC"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "margin-default"
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("../../assets/course_250.webp"),
                                                      height: "200px",
                                                      "max-width": "200px"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.name === "GRAZING"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "margin-default"
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("../../assets/grazing_250.webp"),
                                                      height: "200px",
                                                      "max-width": "200px"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.name === "FUNCTION"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "margin-default"
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("../../assets/cocktail_250.webp"),
                                                      height: "200px",
                                                      "max-width": "200px"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.name === "SHARING"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "margin-default"
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("../../assets/sharing_250.webp"),
                                                      height: "200px",
                                                      "max-width": "200px"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.name === "FOOD_TRUCK"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "margin-default"
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("../../assets/foodtruck_250.webp"),
                                                      height: "200px",
                                                      "max-width": "200px"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.name === "CLASS"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "margin-default"
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      height: "200px",
                                                      width: "200px"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "h2",
                                            {
                                              staticClass: "margin-default",
                                              staticStyle: {
                                                "align-self": "center"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.displayName))]
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2164791936
                            ),
                            model: {
                              value: _vm.service,
                              callback: function($$v) {
                                _vm.service = $$v
                              },
                              expression: "service"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3", align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "btn_service_add",
                                outlined: "",
                                small: "",
                                disabled: !_vm.valid
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addService()
                                }
                              }
                            },
                            [_vm._v("Add Service")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.dishDialog
            ? _c("popup-dish", {
                attrs: {
                  "dish-dialog": _vm.dishDialog,
                  "new-dish": this.newDish,
                  service: this.userLocal.service[this.tab],
                  "item-category": this.newCategory,
                  "close-action": _vm.closeDialogDish,
                  "edit-action": _vm.addOrEditDish
                }
              })
            : _vm._e(),
          _vm.categoryDialog
            ? _c("popup-category", {
                attrs: {
                  "category-dialog": _vm.categoryDialog,
                  "new-category": this.newCategory,
                  service: this.userLocal.service[this.tab],
                  "close-action": _vm.closeDialogCategory,
                  "edit-action": _vm.addOrEditCategory
                }
              })
            : _vm._e(),
          _vm.packageDialog
            ? _c("popup-package", {
                attrs: {
                  "package-dialog": _vm.packageDialog,
                  "new-package": this.newPackage,
                  service: this.userLocal.service[this.tab],
                  "close-action": _vm.closeDialogPackage,
                  "edit-action": _vm.addOrEditPackage
                }
              })
            : _vm._e(),
          _vm.userLocal.service && _vm.userLocal.service.length !== 0
            ? _c(
                "v-tabs",
                {
                  attrs: {
                    "fixed-tabs": "",
                    "background-color": "primary",
                    "show-arrows": ""
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.userLocal.service, function(item, index) {
                  return _c(
                    "v-tab",
                    { key: item.serviceType.name },
                    [
                      item
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(item.serviceType.displayName) + " "
                            )
                          ])
                        : _vm._e(),
                      _vm.error[index]
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "margin-left-right-default",
                              attrs: { color: "error" }
                            },
                            [_vm._v("mdi-alert-circle-outline")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.userLocal.service && _vm.userLocal.service.length !== 0
            ? _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.userLocal.service, function(service, index) {
                  return _c(
                    "v-tab-item",
                    { key: service.serviceType.name, attrs: { eager: true } },
                    [
                      service
                        ? _c(
                            "v-card",
                            {
                              staticStyle: { margin: "1px" },
                              attrs: { elevation: "4" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { "align-self": "center" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        "align-items": "center",
                                        "text-align": "center",
                                        "font-size": "medium",
                                        "font-weight": "700",
                                        margin: "-12px -12px 0px -12px",
                                        padding: "12px",
                                        "border-radius": "4px",
                                        "background-color": "#f7f3f3"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "caption no-margin-padding ",
                                            attrs: {
                                              id: "swc_enable",
                                              "hide-details": "",
                                              value: service.enabled,
                                              label:
                                                "Visible to public: " +
                                                (service.enabled === true
                                                  ? "Visible"
                                                  : "Hidden")
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.checkVisibility(
                                                  service,
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: service.enabled,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  service,
                                                  "enabled",
                                                  $$v
                                                )
                                              },
                                              expression: "service.enabled"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: {
                                            "text-align": "right"
                                          },
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticStyle: {
                                                "align-items": "center"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                staticClass:
                                                  "caption no-margin-padding ",
                                                attrs: {
                                                  id: "swc_tags",
                                                  "hide-details": "",
                                                  label: "show Tags"
                                                },
                                                model: {
                                                  value: _vm.showTags,
                                                  callback: function($$v) {
                                                    _vm.showTags = $$v
                                                  },
                                                  expression: "showTags"
                                                }
                                              }),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "btn_service_delete",
                                                                      icon: "",
                                                                      color:
                                                                        "primary"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteService(
                                                                          service
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-delete-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-spacer")
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Remove the service : " +
                                                        _vm._s(
                                                          service.serviceType
                                                            .displayName
                                                        )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "medium",
                                        "font-weight": "700",
                                        margin: "-12px -12px 0px -12px",
                                        padding: "12px",
                                        "border-radius": "4px",
                                        "background-color": "#f7f3f3"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: {
                                            "align-self": "center"
                                          },
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                service.serviceType.displayName
                                              ) + " "
                                            ),
                                            _c("br"),
                                            _vm._v(" A la carte ")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.checkMenuComputed
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "align-center justify-center caption error--text",
                                          staticStyle: {
                                            "text-align": "center",
                                            margin: "-12px -12px 0px -12px",
                                            "background-color": "#f7f3f3"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "A menu needs at least a Starter and a Main or a Main and a Desert"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(service.itemCategories, function(
                                    category
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: category.name,
                                        staticClass: "margin-top-default"
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "center"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          align: "start"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "primary--text category"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                category.name
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: { align: "end" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " $ " +
                                                            _vm._s(
                                                              category.pricingType !=
                                                                undefined &&
                                                                category.pricingType !==
                                                                  null
                                                                ? category
                                                                    .pricingType
                                                                    .displayName
                                                                : ""
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          "align-self":
                                                            "center",
                                                          "text-align": "right",
                                                          "padding-bottom":
                                                            "0px"
                                                        },
                                                        attrs: { cols: "2" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.moveUpCategory(
                                                                                    service,
                                                                                    category
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-arrow-up"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Move the Category up"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.moveDownCategory(
                                                                                    service,
                                                                                    category
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-arrow-down"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Move the Category down"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.openDialogCategory(
                                                                                    service,
                                                                                    category
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-pencil-outline"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Edit the category"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteCategory(
                                                                                    service,
                                                                                    category
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-delete-outline"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Edit the category"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              _vm.filteredDishesByCategory(
                                                service.dishes,
                                                category
                                              ),
                                              function(dish, index) {
                                                return _c(
                                                  "v-row",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "margin-top-default",
                                                    staticStyle: {
                                                      "text-align": "left"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          "align-self":
                                                            "center",
                                                          "margin-left": "12px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticStyle: {
                                                              "align-items":
                                                                "center"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticStyle: {
                                                                  "padding-bottom":
                                                                    "0px"
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      dish.description
                                                                    )
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "caption restrictions"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.formatItemRestrictions(
                                                                          dish
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "col-auto",
                                                                staticStyle: {
                                                                  "align-self":
                                                                    "center",
                                                                  "padding-bottom":
                                                                    "0px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      dish.price
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticStyle: {
                                                                  "align-self":
                                                                    "center",
                                                                  "text-align":
                                                                    "right",
                                                                  "padding-bottom":
                                                                    "0px"
                                                                },
                                                                attrs: {
                                                                  cols: "2"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        icon:
                                                                                          "",
                                                                                        color:
                                                                                          "primary"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          $event.preventDefault()
                                                                                          return _vm.openDialogDish(
                                                                                            service,
                                                                                            category,
                                                                                            dish
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-pencil-outline"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Edit the item"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        icon:
                                                                                          "",
                                                                                        color:
                                                                                          "primary"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.deleteDish(
                                                                                            service,
                                                                                            category,
                                                                                            dish
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-delete-outline"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Delete the item"
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm.showTags &&
                                                        category.categoryType
                                                          .name !== "OTHER"
                                                          ? _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "text--lighten-3",
                                                                    staticStyle: {
                                                                      "align-self":
                                                                        "center",
                                                                      padding:
                                                                        "0px 12px 0px 12px"
                                                                    },
                                                                    attrs: {
                                                                      cols:
                                                                        "auto"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    dish.styleTags,
                                                                    function(
                                                                      tag
                                                                    ) {
                                                                      return _c(
                                                                        "v-chip",
                                                                        {
                                                                          key:
                                                                            tag.name,
                                                                          attrs: {
                                                                            "x-small":
                                                                              "",
                                                                            "text-color":
                                                                              "black",
                                                                            color:
                                                                              "primary-opacity4"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              tag.displayName
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticStyle: {
                                                                      "align-self":
                                                                        "center",
                                                                      padding:
                                                                        "0px 12px 0px 12px"
                                                                    },
                                                                    attrs: {
                                                                      cols:
                                                                        "auto"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    dish.ingredientTags,
                                                                    function(
                                                                      tag
                                                                    ) {
                                                                      return _c(
                                                                        "v-chip",
                                                                        {
                                                                          key:
                                                                            tag.name,
                                                                          attrs: {
                                                                            "x-small":
                                                                              "",
                                                                            color:
                                                                              "primary-opacity3"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              tag.displayName
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticStyle: {
                                                                      "align-self":
                                                                        "center",
                                                                      padding:
                                                                        "0px 12px 0px 12px"
                                                                    },
                                                                    attrs: {
                                                                      cols:
                                                                        "auto"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    dish.dietaryTags,
                                                                    function(
                                                                      tag
                                                                    ) {
                                                                      return _c(
                                                                        "v-chip",
                                                                        {
                                                                          key:
                                                                            tag.name,
                                                                          attrs: {
                                                                            "x-small":
                                                                              "",
                                                                            color:
                                                                              "primary-opacity2"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              tag.displayName
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticStyle: {
                                                                      "align-self":
                                                                        "center",
                                                                      padding:
                                                                        "0px 12px 0px 12px"
                                                                    },
                                                                    attrs: {
                                                                      cols:
                                                                        "auto"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    dish.equipments,
                                                                    function(
                                                                      tag
                                                                    ) {
                                                                      return _c(
                                                                        "v-chip",
                                                                        {
                                                                          key:
                                                                            tag.name,
                                                                          attrs: {
                                                                            "x-small":
                                                                              "",
                                                                            x:
                                                                              "",
                                                                            color:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              tag.displayName
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "margin-top-bottom margin-default padding-top",
                                                staticStyle: {
                                                  "text-align": "center"
                                                },
                                                attrs: {
                                                  "align-content": "center"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "col margin-top-default",
                                                    attrs: {
                                                      id: "btn_item",
                                                      color: "primary",
                                                      dark: "",
                                                      outlined: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openDialogDish(
                                                          service,
                                                          category
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          category.categoryType
                                                            .name != "OTHER"
                                                            ? "Add a Dish"
                                                            : "Add an Item"
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          dark: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-plus-circle-outline"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-divider", {
                                                  staticClass:
                                                    "margin-top-bottom"
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  }),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "margin-top-bottom",
                                      staticStyle: { "text-align": "center" },
                                      attrs: { "align-content": "center" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "col margin-default",
                                          attrs: {
                                            id: "btn_category",
                                            color: "primary",
                                            dark: "",
                                            outlined: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openDialogCategory(
                                                service
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(" Add a Category "),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "", dark: "" } },
                                            [_vm._v("mdi-plus-circle-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-divider", {
                                            staticClass: "margin-top-bottom"
                                          })
                                        ],
                                        1
                                      ),
                                      service.dishes === undefined ||
                                      service.dishes.length == 0
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "margin-bottom",
                                              attrs: {
                                                "align-content": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: " error--text ",
                                                  staticStyle: {
                                                    "text-align": "center",
                                                    "font-weight": "bold"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Add some Items before creating a package"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm.isDirtyItems()
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "margin-bottom",
                                              attrs: {
                                                "align-content": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: " error--text ",
                                                  staticStyle: {
                                                    "text-align": "center",
                                                    "font-weight": "bold"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Save before creating/editing a package"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      service.packages !== undefined &&
                                      service.packages.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "font-size": "medium",
                                                "font-weight": "700",
                                                margin: "-12px -12px 0px -12px",
                                                padding: "12px",
                                                "border-radius": "4px",
                                                "background-color": "#f7f3f3"
                                              }
                                            },
                                            [
                                              _vm._v(" Packages "),
                                              _vm._l(service.packages, function(
                                                pack
                                              ) {
                                                return _c(
                                                  "v-card",
                                                  {
                                                    key: pack.id,
                                                    staticClass:
                                                      "margin-top-default",
                                                    attrs: { elevation: "4" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-title",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "left"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      pack.name
                                                                    ) +
                                                                    _vm._s(
                                                                      _vm.formatPackageMinMaxGuests(
                                                                        pack
                                                                      ) !== ""
                                                                        ? " - " +
                                                                            _vm.formatPackageMinMaxGuests(
                                                                              pack
                                                                            )
                                                                        : " "
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      pack.enabled
                                                                        ? "Visible"
                                                                        : "Hidden"
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "right"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " $" +
                                                                    _vm._s(
                                                                      pack.price
                                                                    ) +
                                                                    " pp "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "right"
                                                                },
                                                                attrs: {
                                                                  cols: "2"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        id:
                                                                                          "btn_package_modify",
                                                                                        icon:
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        disabled: _vm.isDirtyItems()
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          $event.preventDefault()
                                                                                          return _vm.openDialogPackage(
                                                                                            service,
                                                                                            pack
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        "aria-label":
                                                                                          "Modify Package",
                                                                                        "aria-hidden":
                                                                                          "false"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-pencil-outline"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Edit the package"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        id:
                                                                                          "btn_package_delete",
                                                                                        icon:
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        disabled: _vm.isDirtyItems()
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.deletePackage(
                                                                                            pack,
                                                                                            service
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-delete-outline"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Delete the package"
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "left",
                                                          "font-size": "unset"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "margin-top-bottom"
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._l(
                                                          _vm.packageCategoriesSorted(
                                                            pack
                                                          ),
                                                          function(category) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key:
                                                                  category.name,
                                                                staticClass: " "
                                                              },
                                                              [
                                                                _vm.filteredDishesByCategoryForPackages(
                                                                  service,
                                                                  category
                                                                ).length > 0
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {},
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  align:
                                                                                    "start"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "primary--text category"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            category
                                                                                              .itemCategory
                                                                                              .name
                                                                                          ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "caption restrictions"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.formatPackageCategorySelection(
                                                                                              category
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._l(
                                                                          _vm.filteredDishesByCategoryForPackages(
                                                                            service,
                                                                            category
                                                                          ),
                                                                          function(
                                                                            item,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "v-row",
                                                                              {
                                                                                key: index,
                                                                                staticStyle: {
                                                                                  "margin-top":
                                                                                    "0px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "align-self":
                                                                                        "center",
                                                                                      "margin-left":
                                                                                        "12px"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item.description
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "caption restrictions"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.formatItemRestrictions(
                                                                                              item
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _vm._e(),
                                                                category.comment
                                                                  ? _c(
                                                                      "v-row",
                                                                      {
                                                                        staticStyle: {
                                                                          "margin-top":
                                                                            "0px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticStyle: {
                                                                              "align-self":
                                                                                "center",
                                                                              "margin-left":
                                                                                "12px"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "padding-default comment"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    category.comment
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      service.packages !== undefined &&
                                      service.packages.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "margin-top-bottom"
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "margin-bottom"
                                                },
                                                [_c("v-divider")],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          },
                                          attrs: { "align-content": "center" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "col margin-default",
                                              attrs: {
                                                id: "btn_package",
                                                color: "primary",
                                                outlined: "",
                                                disabled: _vm.isDirtyItems()
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openDialogPackage(
                                                    service
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(" Add a Package "),
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { right: "", dark: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-plus-circle-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-form",
                        {
                          ref: "formConfiguration",
                          refInFor: true,
                          attrs: {
                            "v-model": _vm.error[index],
                            "lazy-validation": ""
                          }
                        },
                        [
                          service
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "margin-card-default ",
                                  attrs: { elevation: "4" }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            "no-gutters": ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.expandConfigaration = !_vm.expandConfigaration
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "inline" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "primary--text",
                                                  staticStyle: {
                                                    display: "inline"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Service configuration"
                                                  )
                                                ]
                                              ),
                                              _vm.error[_vm.tab]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "margin-left-right-default",
                                                      staticStyle: {
                                                        display: "inline"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "error"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-alert-circle-outline"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary"
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.expandConfigaration
                                                      ? "mdi-chevron-up"
                                                      : "mdi-chevron-down"
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.expandConfigaration
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { align: "center" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                            "align-content":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              id:
                                                                "minimum_amount",
                                                              label:
                                                                "Minimum Earnings ($)",
                                                              type: "number",
                                                              rules: [
                                                                function(v) {
                                                                  return (
                                                                    (!!v &&
                                                                      v.length !==
                                                                        0) ||
                                                                    "You must enter a minimum earnings !"
                                                                  )
                                                                }
                                                              ],
                                                              hint:
                                                                "The client will have to spend your minimum earnings. Example: If a client book for 2 people with a total of 160$ and your mminimum earnings is $200 you will get $200 minimum",
                                                              "persistent-hint":
                                                                _vm.helpMinPrice
                                                            },
                                                            on: {
                                                              "update:error": function(
                                                                $event
                                                              ) {
                                                                return _vm.validateConfiguration()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                service.minPrice,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  service,
                                                                  "minPrice",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "service.minPrice"
                                                            }
                                                          }),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                id: "btn_help",
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpMinPrice = !_vm.helpMinPrice
                                                                },
                                                                hover: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpMinPrice = !_vm.helpMinPrice
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-help-circle"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                            "align-content":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              id:
                                                                "requirements",
                                                              label:
                                                                "Requirements",
                                                              hint:
                                                                "Add any extra requirements here. Eg: Property accessible by car. Need 4x2m space for the food truck. Access to a power point/water outlet",
                                                              placeholder:
                                                                "Eg: Property accessible by car. Need 5x2m space for the food truck",
                                                              "persistent-hint":
                                                                _vm.helpRequirements,
                                                              maxlength: 5000
                                                            },
                                                            on: {
                                                              "update:error": function(
                                                                $event
                                                              ) {
                                                                return _vm.validateConfiguration()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                service.requirements,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  service,
                                                                  "requirements",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "service.requirements"
                                                            }
                                                          }),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                id: "btn_help",
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpRequirements = !_vm.helpRequirements
                                                                },
                                                                hover: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpRequirements = !_vm.helpRequirements
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-help-circle"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "primary--text margin-top-default"
                                                },
                                                [
                                                  _vm._v(
                                                    "A la carte configuration"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-row",
                                                { attrs: { align: "start" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                            "align-content":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              id:
                                                                "minimum_guest",
                                                              label:
                                                                "Minimum Guest",
                                                              type: "number",
                                                              messages: _vm.helpMinGuest
                                                                ? [
                                                                    "Clients won't be able to book your service if they select less than your minimum guest requirement. We don't recommend setting this field,use the field minimum earnings instead "
                                                                  ]
                                                                : []
                                                            },
                                                            model: {
                                                              value:
                                                                service.minGuests,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  service,
                                                                  "minGuests",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "service.minGuests"
                                                            }
                                                          }),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpMinGuest = !_vm.helpMinGuest
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-help-circle"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                            "align-content":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              id:
                                                                "maximum_guest",
                                                              label:
                                                                "Maximum Guest",
                                                              type: "number",
                                                              messages: _vm.helpMaxGuest
                                                                ? [
                                                                    "Clients won't be able to book your service if they select more than your maximum guest requirement "
                                                                  ]
                                                                : []
                                                            },
                                                            model: {
                                                              value:
                                                                service.maxGuests,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  service,
                                                                  "maxGuests",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "service.maxGuests"
                                                            }
                                                          }),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpMaxGuest = !_vm.helpMaxGuest
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-help-circle"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { attrs: { align: "start" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                            "align-content":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              id:
                                                                "minimum_dishes",
                                                              label:
                                                                "Minimum of dishes to hire a wait person",
                                                              type: "number",
                                                              messages: _vm.helpMinWaiterDishes
                                                                ? [
                                                                    "If the total number of dishes ordered (Number of people multiplied by the Number of dishes) reaches your limit, the wait person cost will be automatically added  "
                                                                  ]
                                                                : []
                                                            },
                                                            model: {
                                                              value:
                                                                service.minWaiterDishes,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  service,
                                                                  "minWaiterDishes",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "service.minWaiterDishes"
                                                            }
                                                          }),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpMinWaiterDishes = !_vm.helpMinWaiterDishes
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-help-circle"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                            "align-content":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              id:
                                                                "amount_waiter",
                                                              label:
                                                                "Wait person cost",
                                                              type: "number",
                                                              messages: _vm.helpWaiterPrice
                                                                ? [
                                                                    "Cost for hiring a wait person, this cost will automatically be added to the client booking when the minimum of dishes is reached"
                                                                  ]
                                                                : []
                                                            },
                                                            model: {
                                                              value:
                                                                service.waiterPrice,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  service,
                                                                  "waiterPrice",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "service.waiterPrice"
                                                            }
                                                          }),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.helpWaiterPrice = !_vm.helpWaiterPrice
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-help-circle"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("menu-example", { attrs: { service: service } }),
                      _c("br"),
                      true
                        ? _c(
                            "a",
                            {
                              staticClass: "margin-default ",
                              attrs: { target: "_blank", href: "/profile" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openProfile()
                                }
                              }
                            },
                            [_vm._v("Preview your public profile")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }