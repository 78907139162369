var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "v-img",
        {
          staticClass: "brighten-v-img private",
          attrs: {
            src: require("../assets/home_1280.webp"),
            srcset:
              require("../assets/home_1280.webp") +
              ", " +
              require("../assets/home_900.webp") +
              " 900w, " +
              require("../assets/home_400.webp") +
              " 400w"
          }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "v-col",
                { attrs: { "align-content": "center", justify: "center" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      attrs: { "align-content": "center", justify: "center" }
                    },
                    [_c("div", { staticClass: "col" })]
                  ),
                  _c("div", { staticClass: "text-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: " white--text",
                        staticStyle: { visibility: "visible" }
                      },
                      [
                        _c("h1", {}, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatContent(true)) +
                              " " +
                              _vm._s(
                                _vm.formatCityName()
                                  ? "in " + _vm.formatCityName()
                                  : ""
                              ) +
                              " "
                          )
                        ]),
                        _c("h4", {}, [
                          _vm._v(
                            " Bring a restaurant experience in your own home with a " +
                              _vm._s(_vm.formatContent()) +
                              " " +
                              _vm._s(
                                _vm.formatCityName()
                                  ? "in " + _vm.formatCityName()
                                  : ""
                              ) +
                              " "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "margin-top-bottom-big",
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { href: "/search", target: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.push({ path: "/search" })
                        }
                      }
                    },
                    [_vm._v("See Menus")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        [
          _c(
            "v-container",
            {
              staticClass: "container margin-top-bottom-big",
              attrs: { fuild: "" }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "text-h5",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _vm._v(
                    " Our " +
                      _vm._s(_vm.formatContent()) +
                      " service " +
                      _vm._s(
                        _vm.formatCityName() ? "in " + _vm.formatCityName() : ""
                      ) +
                      " is the perfect option for people who want to enjoy restaurant-quality meals in the comfort of their own home, without having to deal with the hassle of grocery shopping, cooking, serving and cleaning up, leaving you free to relax and enjoy their meal. "
                  )
                ]
              ),
              _c(
                "v-row",
                {
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-img", {
                    staticClass: "margin-top-bottom-big round-corner",
                    attrs: {
                      height: "300px",
                      "max-width": "500px",
                      src: require("../assets/plate1_400.webp"),
                      srcset:
                        require("../assets/plate1_900.webp") +
                        ", " +
                        require("../assets/plate1_400.webp") +
                        " 500w"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-h5",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _vm._v(
                    " Our " +
                      _vm._s(_vm.formatContent()) +
                      " service " +
                      _vm._s(
                        _vm.formatCityName() ? "in " + _vm.formatCityName() : ""
                      ) +
                      " is also an excellent option for special occasions such as dinner parties, birthday parties, functions, events, romantic meals for two, or family gatherings. Whether you're looking to add a touch of luxury to your dining experience or simply want to make mealtime easier and more enjoyable, hiring a " +
                      _vm._s(_vm.formatContent()) +
                      " is a great option. "
                  )
                ]
              ),
              _c(
                "v-row",
                {
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-img", {
                    staticClass: "margin-top-bottom-big  round-corner",
                    attrs: {
                      height: "300px",
                      "max-width": "500px",
                      src: require("../assets/plate2_400.webp"),
                      srcset:
                        require("../assets/plate2_900.webp") +
                        ", " +
                        require("../assets/plate2_400.webp") +
                        " 500w"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-h5",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatCityName()
                          ? "In " + _vm.formatCityName() + ", our"
                          : "Our"
                      ) +
                      " " +
                      _vm._s(_vm.formatContent()) +
                      " service is becoming increasingly popular, as more and more people discover the benefits of having a professional chef prepare meals in the comfort of their own home. Private chefs " +
                      _vm._s(
                        _vm.formatCityName() ? "in " + _vm.formatCityName() : ""
                      ) +
                      " have the opportunity to showcase their skills and creativity, creating delicious and beautifully presented meals that are tailored to their clients' tastes and dietary needs. "
                  )
                ]
              ),
              _c(
                "v-row",
                {
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-img", {
                    staticClass: "margin-top-bottom-big  round-corner",
                    attrs: {
                      height: "300px",
                      "max-width": "500px",
                      src: require("../assets/plate3_400.webp"),
                      srcset:
                        require("../assets/plate3_900.webp") +
                        ", " +
                        require("../assets/plate3_400.webp") +
                        " 500w"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-h5",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _vm._v(
                    " At Kootto, our " +
                      _vm._s(_vm.formatContent()) +
                      "s are also able to work with a wide range of ingredients, including fresh, local produce, exotic spices, and unique flavors. This allows them to create dishes that are both tasty and diverse, and that can help to add some variety and excitement to the client's mealtime experience. "
                  )
                ]
              ),
              _c(
                "v-row",
                {
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-img", {
                    staticClass: "margin-top-bottom-big  round-corner",
                    attrs: {
                      height: "300px",
                      "max-width": "500px",
                      src: require("../assets/plate2_400.webp"),
                      srcset:
                        require("../assets/plate4_900.webp") +
                        ", " +
                        require("../assets/plate4_400.webp") +
                        " 500w"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-h5",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _vm._v(
                    " At Kootto, our " +
                      _vm._s(_vm.formatContent()) +
                      "s are free to create their own menus, this allows our chefs to express themselves and their culinary talents, and create dishes that are truly unique and delicious. "
                  ),
                  _c("br"),
                  _c("br")
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "margin-top-bottom-big",
                  attrs: { "align-content": "center", justify: "space-around" }
                },
                [
                  _c("v-img", {
                    staticClass: " round-corner",
                    attrs: {
                      height: "300px",
                      "max-width": "500px",
                      src: require("../assets/serving_900.webp"),
                      srcset:
                        require("../assets/serving_900.webp") +
                        ", " +
                        require("../assets/serving_400.webp") +
                        " 500w"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "align-content": "center", justify: "center" } },
                [
                  _c("br"),
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", href: "/search", target: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.push({ path: "/search" })
                        }
                      }
                    },
                    [_vm._v("See Menus")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }