var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.loggedin && _vm.user
        ? _c(
            "div",
            { staticClass: "user" },
            [
              _vm.$store.state.isAdmin &&
              _vm.userLocal !== undefined &&
              _vm.userLocal.profile !== undefined &&
              _vm.userLocal.profile.status !== undefined
                ? _c(
                    "div",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "align-content": "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "col-sm-10 col-12",
                                  attrs: { "align-self": "center" }
                                },
                                [
                                  _vm.$store.state.statusProfile
                                    ? _c("v-select", {
                                        attrs: {
                                          id: "status",
                                          items: _vm.$store.state.statusProfile.filter(
                                            function(s) {
                                              return (
                                                s.name === "REVIEW" ||
                                                s.name === "REJECTED" ||
                                                s.name === "APPROVED"
                                              )
                                            }
                                          ),
                                          "item-text": "displayName",
                                          "return-object": true,
                                          label: "Status",
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v || "This field is required!"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.userLocal.profile.status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.userLocal.profile,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "userLocal.profile.status"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "col-sm-2 col-12" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "margin-left-right-default",
                                      class: {
                                        "warning--text ":
                                          this.$root.self === false,
                                        "": this.$root.self === true
                                      },
                                      attrs: {
                                        id: "btn_admin_save",
                                        outlined: "",
                                        color: "primary",
                                        disabled: !_vm.valid,
                                        loading: _vm.loading
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.validate() != true
                                            ? null
                                            : _vm.save()
                                        }
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.userLocal.profile.status.name === "REVIEW" ||
                          _vm.userLocal.profile.status.name === "REJECTED"
                            ? _c("v-textarea", {
                                attrs: {
                                  id: "comments",
                                  label: "Comments",
                                  rules: [
                                    function(v) {
                                      return !!v || "This field is required!"
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.userLocal.profile.comments,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.userLocal.profile,
                                      "comments",
                                      $$v
                                    )
                                  },
                                  expression: "userLocal.profile.comments"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("router-view")
            ],
            1
          )
        : _vm._e(),
      _vm.dirty
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "500px" },
              model: {
                value: _vm.dirty,
                callback: function($$v) {
                  _vm.dirty = $$v
                },
                expression: "dirty"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Do you really want to leave? ")
                    ])
                  ]),
                  _c("v-card-text", [_vm._v(" You have unsaved changes! ")]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "margin-left-right-default",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              _vm.dirty = false
                            }
                          }
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "margin-right-default",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.dirty = false
                              _vm.next()
                            }
                          }
                        },
                        [_vm._v("Yes")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }