var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        bottom: _vm.snackbar.bottom,
        color: _vm.snackbar.color,
        timeout: _vm.snackbar.timeout,
        "multi-line": _vm.snackbar.multiline === false
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { id: "btn_snack_close", text: "" },
                    on: { click: _vm.closeSnackbar }
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Close ")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.snackbar.visible,
        callback: function($$v) {
          _vm.$set(_vm.snackbar, "visible", $$v)
        },
        expression: "snackbar.visible"
      }
    },
    [_vm._v(" " + _vm._s(_vm.snackbar.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }