var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "margin-bottom" },
            [
              _c("h2", [_vm._v("Company (optional)")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "margin-left-right-default",
                  attrs: { id: "btn_cancel", outlined: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "margin-right-default",
                  attrs: {
                    id: "btn_save",
                    color: "primary",
                    loading: _vm.loading
                  },
                  on: { click: _vm.save }
                },
                [_vm._v("Save")]
              )
            ],
            1
          ),
          _c("h2"),
          _c("v-text-field", {
            attrs: {
              id: "name",
              label: "Company name / Business Name / Trading Name",
              placeholder: "Company name or Business Name or Trading Name"
            },
            model: {
              value: _vm.userLocal.company.name,
              callback: function($$v) {
                _vm.$set(_vm.userLocal.company, "name", $$v)
              },
              expression: "userLocal.company.name"
            }
          }),
          _c("v-text-field", {
            attrs: { id: "abn", type: "number", label: "ABN" },
            model: {
              value: _vm.userLocal.company.abn,
              callback: function($$v) {
                _vm.$set(_vm.userLocal.company, "abn", $$v)
              },
              expression: "userLocal.company.abn"
            }
          }),
          _c("v-autocomplete", {
            attrs: {
              "no-filter": true,
              id: "ac_address",
              clearable: "",
              items: _vm.addressItems,
              loading: _vm.isLoading,
              "search-input": _vm.lookupAddress,
              "item-text": "place_name",
              label: "Location",
              "hide-no-data": "",
              placeholder: "Start typing your address",
              "prepend-icon": "",
              "append-icon": "",
              "return-object": "",
              "auto-select-first": ""
            },
            on: {
              "update:searchInput": function($event) {
                _vm.lookupAddress = $event
              },
              "update:search-input": function($event) {
                _vm.lookupAddress = $event
              },
              change: _vm.getLocation
            },
            model: {
              value: _vm.address,
              callback: function($$v) {
                _vm.address = $$v
              },
              expression: "address"
            }
          }),
          _c("v-text-field", {
            attrs: { disabled: "", label: "Address" },
            model: {
              value: _vm.userLocal.company.address,
              callback: function($$v) {
                _vm.$set(_vm.userLocal.company, "address", $$v)
              },
              expression: "userLocal.company.address"
            }
          }),
          _c("v-text-field", {
            attrs: { id: "address", disabled: "", label: "City" },
            model: {
              value: _vm.userLocal.company.city,
              callback: function($$v) {
                _vm.$set(_vm.userLocal.company, "city", $$v)
              },
              expression: "userLocal.company.city"
            }
          }),
          _c("v-text-field", {
            attrs: { id: "region", disabled: "", label: "Region/State" },
            model: {
              value: _vm.userLocal.company.region,
              callback: function($$v) {
                _vm.$set(_vm.userLocal.company, "region", $$v)
              },
              expression: "userLocal.company.region"
            }
          }),
          _c("v-text-field", {
            attrs: { id: "postcode", disabled: "", label: "Post Code" },
            model: {
              value: _vm.userLocal.company.postcode,
              callback: function($$v) {
                _vm.$set(_vm.userLocal.company, "postcode", $$v)
              },
              expression: "userLocal.company.postcode"
            }
          }),
          _c("v-text-field", {
            attrs: {
              id: "phone",
              type: "number",
              label: "Business Phone Number"
            },
            model: {
              value: _vm.userLocal.company.phone,
              callback: function($$v) {
                _vm.$set(_vm.userLocal.company, "phone", $$v)
              },
              expression: "userLocal.company.phone"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }