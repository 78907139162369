var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("Login")])
              ]),
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.login()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { staticClass: "no-padding-top-bottom" },
                        [
                          _vm.resendVerifyEmail
                            ? _c(
                                "v-row",
                                [
                                  _vm.error
                                    ? _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "error--text" },
                                          [_vm._v(_vm._s(_vm.error))]
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "margin-right-default",
                                          attrs: {
                                            small: "",
                                            loading: _vm.loadingEmail
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.sendConfirmationEmail()
                                            }
                                          }
                                        },
                                        [_vm._v(" Request verification code ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text--secondary margin-top-default"
                                        },
                                        [
                                          _vm._v(
                                            "The verification code will be valid during 5 minutes"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "code",
                                          label: "Verification Code",
                                          required: "",
                                          dense: "",
                                          rules: _vm.verificationCodeRules,
                                          type: "number"
                                        },
                                        model: {
                                          value: _vm.code,
                                          callback: function($$v) {
                                            _vm.code = $$v
                                          },
                                          expression: "code"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "email",
                                          label: "Email",
                                          required: "",
                                          rules: _vm.emailRules,
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  !_vm.forgotPassword
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              id: "password",
                                              rules: _vm.passowrdRules,
                                              label: "Password",
                                              type: "password",
                                              required: "",
                                              dense: ""
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function($$v) {
                                                _vm.password = $$v
                                              },
                                              expression: "password"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("div", [
                                      _vm._v(" " + _vm._s(_vm.infoMessages))
                                    ])
                                  ])
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      !_vm.forgotPassword
                        ? _c(
                            "a",
                            {
                              attrs: {
                                id: "link_reset_password",
                                disabled: !_vm.valid
                              },
                              on: {
                                click: function($event) {
                                  _vm.forgotPassword = true
                                }
                              }
                            },
                            [_vm._v("I forgot my password")]
                          )
                        : _vm._e(),
                      _vm.forgotPassword
                        ? _c(
                            "a",
                            {
                              attrs: { id: "link_login", disabled: !_vm.valid },
                              on: {
                                click: function($event) {
                                  _vm.forgotPassword = false
                                }
                              }
                            },
                            [_vm._v("Login")]
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { id: "close", color: "primary", text: "" },
                          on: { click: _vm.closeAction }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "submit",
                            color: "primary",
                            disabled: !_vm.valid,
                            type: "submit",
                            loading: _vm.loading
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.resendVerifyEmail
                                ? "Verify"
                                : _vm.forgotPassword
                                ? "Reset my password"
                                : "Login"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }