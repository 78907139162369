var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.show,
                callback: function($$v) {
                  _vm.show = $$v
                },
                expression: "show"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(
                        "Signup " + _vm._s(_vm.iamachef() ? "as a Chef" : "")
                      )
                    ]),
                    _vm.iamachef()
                      ? _c(
                          "div",
                          { staticClass: "margin-left-right-default" },
                          [
                            _vm._v("(Check out "),
                            _c(
                              "a",
                              {
                                attrs: { href: "/signup", target: "_blank" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              },
                              [_vm._v("our key features")]
                            ),
                            _vm._v(")")
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.signup()
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _vm.error
                                    ? _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "error--text" },
                                          [_vm._v(_vm._s(_vm.error))]
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "firstname",
                                          dense: "",
                                          label: "Firstname",
                                          rules: _vm.firstnameRule,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.user.userDetails.firstname,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.user.userDetails,
                                              "firstname",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.userDetails.firstname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "lastname",
                                          dense: "",
                                          label: "Lastname",
                                          rules: _vm.lastnameRule,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.user.userDetails.lastname,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.user.userDetails,
                                              "lastname",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.userDetails.lastname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "phone",
                                          dense: "",
                                          label: "Mobile",
                                          rules: _vm.mobileRule,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.user.userDetails.phone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.user.userDetails,
                                              "phone",
                                              $$v
                                            )
                                          },
                                          expression: "user.userDetails.phone"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "email",
                                          label: "Email",
                                          required: "",
                                          rules: _vm.emailRules,
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.user.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.user, "email", $$v)
                                          },
                                          expression: "user.email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "password",
                                          rules: _vm.passwordRules,
                                          label: "Password",
                                          type: "password",
                                          required: "",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.user.password,
                                          callback: function($$v) {
                                            _vm.$set(_vm.user, "password", $$v)
                                          },
                                          expression: "user.password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "passwordRepeat",
                                          rules: _vm.passwordMatchRules,
                                          label: "Repeat Password",
                                          type: "password",
                                          required: "",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.passwordRepeat,
                                          callback: function($$v) {
                                            _vm.passwordRepeat = $$v
                                          },
                                          expression: "passwordRepeat"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v ||
                                                "You must agree to continue!"
                                              )
                                            }
                                          ],
                                          required: "",
                                          dense: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      " Do you accept the "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "/terms",
                                                          target: "_blank"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Terms and Conditions"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" and our "),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "/privacy",
                                                          target: "_blank"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Privacy Policy")]
                                                    ),
                                                    _vm._v("? ")
                                                  ])
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          4056690129
                                        ),
                                        model: {
                                          value: _vm.terms,
                                          callback: function($$v) {
                                            _vm.terms = $$v
                                          },
                                          expression: "terms"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-col", [
                                    _c("div", [
                                      _vm._v(" Already have an account? "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { id: "login" },
                                          on: {
                                            click: function($event) {
                                              _vm.close()
                                              _vm.$root.showLogin = true
                                            }
                                          }
                                        },
                                        [_vm._v("Login")]
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          !_vm.iamachef()
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "btn_iamachef",
                                    color: "primary",
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.chefLocal = true
                                    }
                                  }
                                },
                                [_vm._v("Signup as a Chef")]
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "btn_close",
                                color: "primary",
                                text: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "btn_signup",
                                color: "primary",
                                disabled: !_vm.valid,
                                type: "submit",
                                loading: _vm.loading
                              }
                            },
                            [_vm._v("Signup")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }