var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkCategory
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "1200px" },
          model: {
            value: _vm.categoryDialog,
            callback: function($$v) {
              _vm.categoryDialog = $$v
            },
            expression: "categoryDialog"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "formDish",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  _vm.validateCategory() != true ? null : _vm.edit()
                }
              },
              model: {
                value: _vm.validCategory,
                callback: function($$v) {
                  _vm.validCategory = $$v
                },
                expression: "validCategory"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "headline" }, [_vm._v("Category")])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      id: "category_type",
                                      items: _vm.dishTypes,
                                      "item-text": "displayName",
                                      "return-object": true,
                                      label: "Category Type",
                                      rules: [
                                        function(v) {
                                          return (
                                            (!!v && v.length !== 0) ||
                                            "You must select one Type !"
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    on: {
                                      change: _vm.defaultCategoryDescription
                                    },
                                    model: {
                                      value: _vm.localCategory.categoryType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.localCategory,
                                          "categoryType",
                                          $$v
                                        )
                                      },
                                      expression: "localCategory.categoryType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "category_description",
                                      label: "Category description",
                                      type: "text",
                                      rules: [
                                        function(v) {
                                          return (
                                            (!!v && v.length !== 0) ||
                                            "You must enter a description !"
                                          )
                                        },
                                        function(v) {
                                          return (
                                            this$1.checkCategoryName(v) ||
                                            "The name should be unique"
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.localCategory.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.localCategory, "name", $$v)
                                      },
                                      expression: "localCategory.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.localCategory != undefined &&
                              _vm.localCategory.categoryType != undefined &&
                              _vm.localCategory.categoryType.name !== "KID"
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            id: "pricing_type",
                                            row: "",
                                            label: "Pricing :",
                                            rules: [
                                              function(v) {
                                                return (
                                                  v !== undefined ||
                                                  "This field is required!"
                                                )
                                              }
                                            ]
                                          },
                                          model: {
                                            value:
                                              _vm.localCategory.pricingType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.localCategory,
                                                "pricingType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localCategory.pricingType"
                                          }
                                        },
                                        _vm._l(_vm.pricingType, function(
                                          option
                                        ) {
                                          return _c("v-radio", {
                                            key: option.name,
                                            attrs: {
                                              label: option.displayName,
                                              value: option
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.localCategory.categoryType !== undefined &&
                              (_vm.localCategory.categoryType.name == "OTHER" ||
                                _vm.localCategory.categoryType.name == "FOOD" ||
                                _vm.localCategory.categoryType.name == "KID")
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "caption no-margin-padding ",
                                        attrs: {
                                          id: "category_multiselect",
                                          label: "Multiple selection",
                                          hint:
                                            "This option will allow the client to select multiple items in this category",
                                          "persistent-hint": true
                                        },
                                        model: {
                                          value: _vm.localCategory.multiSelect,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.localCategory,
                                              "multiSelect",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localCategory.multiSelect"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-row", { attrs: { align: "start" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { id: "btn_category_close", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeAction()
                            }
                          }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "btn_category_action",
                            color: "primary",
                            type: "submit",
                            disabled: !_vm.validCategory
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.localCategory.edit === true ? "Edit" : "Add"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }