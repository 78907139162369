var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.localPackage
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "1200px" },
          model: {
            value: _vm.packageDialog,
            callback: function($$v) {
              _vm.packageDialog = $$v
            },
            expression: "packageDialog"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  _vm.validate() != true ? null : _vm.edit()
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "headline" }, [_vm._v("Package")])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "package_description",
                                      label: "Package description",
                                      type: "text",
                                      rules: [
                                        function(v) {
                                          return (
                                            (!!v && v.length !== 0) ||
                                            "You must enter a description !"
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.localPackage.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.localPackage, "name", $$v)
                                      },
                                      expression: "localPackage.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    ref: "price",
                                    attrs: {
                                      id: "package_price",
                                      label: "Price per person",
                                      type: "number",
                                      rules: [
                                        function(v) {
                                          return (
                                            (!!v && v.length !== 0) ||
                                            "You must enter a price !"
                                          )
                                        }
                                      ],
                                      hint:
                                        "The price per person must include everything (Taxes, buying/preparation/driving/cooking/serving/cleaning time )",
                                      "persistent-hint": true
                                    },
                                    model: {
                                      value: _vm.localPackage.price,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.localPackage,
                                          "price",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "localPackage.price"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "start" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "min_guests",
                                      label: "Mininum guests",
                                      type: "number",
                                      min: "0",
                                      max: "999",
                                      hint:
                                        "Minimum number of guests required for this package",
                                      "persistent-hint": true
                                    },
                                    model: {
                                      value: _vm.localPackage.minGuests,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.localPackage,
                                          "minGuests",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "localPackage.minGuests"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "max_guests",
                                      label: "Maximum guests",
                                      type: "number",
                                      min: "0",
                                      max: "999",
                                      hint:
                                        "Maximum number of guests for this package",
                                      "persistent-hint": true
                                    },
                                    model: {
                                      value: _vm.localPackage.maxGuests,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.localPackage,
                                          "maxGuests",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "localPackage.maxGuests"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "start" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "caption no-margin-padding ",
                                    attrs: {
                                      id: "swc_enable",
                                      "hide-details": "",
                                      value: _vm.localPackage.enabled,
                                      label:
                                        "Visible to public: " +
                                        (_vm.localPackage.enabled === true
                                          ? "Visible"
                                          : "Hidden")
                                    },
                                    model: {
                                      value: _vm.localPackage.enabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.localPackage,
                                          "enabled",
                                          $$v
                                        )
                                      },
                                      expression: "localPackage.enabled"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "formCategory",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  _vm.validateCategory() != true
                                    ? null
                                    : _vm.addPackageCategory()
                                }
                              },
                              model: {
                                value: _vm.validCategory,
                                callback: function($$v) {
                                  _vm.validCategory = $$v
                                },
                                expression: "validCategory"
                              }
                            },
                            [
                              _vm.localPackage
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "margin-top-default",
                                      attrs: { elevation: "4" }
                                    },
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Package composition")
                                      ]),
                                      _c(
                                        "v-card-text",
                                        _vm._l(
                                          _vm.packageCategoriesSorted(
                                            _vm.localPackage
                                          ),
                                          function(category) {
                                            return _c(
                                              "div",
                                              {
                                                key: category.name,
                                                staticClass:
                                                  "margin-top-default"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      category.itemCategory.name
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      category.selectionType
                                                        .displayName
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { small: "" },
                                                    on: {
                                                      "!click": function(
                                                        $event
                                                      ) {
                                                        return _vm.removeCategory(
                                                          category
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("mdi-delete-outline")]
                                                ),
                                                _vm._l(
                                                  _vm.filteredDishesByCategoryForPackages(
                                                    _vm.service,
                                                    category
                                                  ),
                                                  function(item, index) {
                                                    return _c(
                                                      "ul",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "margin-top-default"
                                                      },
                                                      [
                                                        _c("li", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.description
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "padding-default comment"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          category.comment
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-card",
                                {
                                  staticClass: "margin-top-default",
                                  attrs: { elevation: "4" }
                                },
                                [
                                  _c("v-card-title", [
                                    _vm._v("Add a category to the package")
                                  ]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "start" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                md: "4",
                                                v: ""
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  id: "category",
                                                  items: _vm.categoriesNotUsed(),
                                                  "item-text": "name",
                                                  "return-object": true,
                                                  label: "Category",
                                                  rules: [
                                                    function(v) {
                                                      return (
                                                        (!!v &&
                                                          v.length !== 0) ||
                                                        "You must select one Category !"
                                                      )
                                                    }
                                                  ],
                                                  required: ""
                                                },
                                                model: {
                                                  value: _vm.newPackageCategory,
                                                  callback: function($$v) {
                                                    _vm.newPackageCategory = $$v
                                                  },
                                                  expression:
                                                    "newPackageCategory"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "4" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  id: "category",
                                                  items: _vm.selectionTypes,
                                                  "item-text": "displayName",
                                                  "return-object": true,
                                                  label: "Selection types",
                                                  rules: [
                                                    function(v) {
                                                      return (
                                                        (!!v &&
                                                          v.length !== 0) ||
                                                        "You must select one selection type !"
                                                      )
                                                    }
                                                  ],
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.newPackageSelectionType,
                                                  callback: function($$v) {
                                                    _vm.newPackageSelectionType = $$v
                                                  },
                                                  expression:
                                                    "newPackageSelectionType"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "4" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  id: "restricted_items",
                                                  items: _vm.itemsCategory,
                                                  multiple: "",
                                                  "item-text": "description",
                                                  "return-object": true,
                                                  label:
                                                    "Limited list of items",
                                                  hint:
                                                    "Leave blank to include all the items in the category",
                                                  "persistent-hint": true
                                                },
                                                model: {
                                                  value:
                                                    _vm.newPackageLimitedItems,
                                                  callback: function($$v) {
                                                    _vm.newPackageLimitedItems = $$v
                                                  },
                                                  expression:
                                                    "newPackageLimitedItems"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.newPackageSelectionType !==
                                        undefined &&
                                      _vm.newPackageSelectionType.name ==
                                        "MULTIPLE"
                                        ? _c(
                                            "v-row",
                                            { attrs: { align: "start" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", md: "4" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      id: "minItem",
                                                      label:
                                                        "Mininum number of items to select",
                                                      type: "number",
                                                      min: "0",
                                                      max: "999"
                                                    },
                                                    model: {
                                                      value: _vm.minItem,
                                                      callback: function($$v) {
                                                        _vm.minItem = _vm._n(
                                                          $$v
                                                        )
                                                      },
                                                      expression: "minItem"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", md: "4" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      id: "maxItem",
                                                      label:
                                                        "Maximum number of items to select",
                                                      type: "number",
                                                      min: "0",
                                                      max: "999"
                                                    },
                                                    model: {
                                                      value: _vm.maxItem,
                                                      callback: function($$v) {
                                                        _vm.maxItem = _vm._n(
                                                          $$v
                                                        )
                                                      },
                                                      expression: "maxItem"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { attrs: { align: "start" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  id: "comment",
                                                  "prepend-icon": "mdi-comment",
                                                  dense: "",
                                                  label: "Comment",
                                                  hint:
                                                    "Add any additional information, like how you manage multiple selection.",
                                                  placeholder:
                                                    "Add any additional information, like how you manage multiple selection."
                                                },
                                                model: {
                                                  value: _vm.comment,
                                                  callback: function($$v) {
                                                    _vm.comment = $$v
                                                  },
                                                  expression: "comment"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            id: "btn_add_category",
                                            outlined: "",
                                            small: "",
                                            color: "primary",
                                            type: "submit",
                                            disabled: !_vm.validCategory
                                          }
                                        },
                                        [_vm._v("Add to package composition")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { id: "btn_package_close", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeAction()
                            }
                          }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "btn_package_action",
                            color: "primary",
                            type: "submit",
                            disabled: !_vm.valid
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.localPackage.edit === true ? "Edit" : "Add"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }