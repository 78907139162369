var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-container",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "margin-bottom" },
                [
                  _c("h2", [_vm._v("Details (Never revealed publicly)")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "margin-left-right-default",
                      attrs: { id: "btn_cancel", outlined: "" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "margin-right-default",
                      attrs: {
                        id: "btn_save",
                        color: "primary",
                        disabled: !_vm.valid,
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              ),
              _c("v-text-field", {
                attrs: {
                  id: "firstname",
                  label: "Firstname",
                  rules: [
                    function(v) {
                      return !!v || "This field is required!"
                    }
                  ],
                  required: ""
                },
                model: {
                  value: _vm.userLocal.userDetails.firstname,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "firstname", $$v)
                  },
                  expression: "userLocal.userDetails.firstname"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  id: "lasttname",
                  label: "Lastname",
                  rules: [
                    function(v) {
                      return !!v || "This field is required!"
                    }
                  ]
                },
                model: {
                  value: _vm.userLocal.userDetails.lastname,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "lastname", $$v)
                  },
                  expression: "userLocal.userDetails.lastname"
                }
              }),
              _c(
                "v-menu",
                {
                  ref: "datepicker",
                  attrs: {
                    "close-on-content-click": false,
                    "max-width": "290",
                    "offset-yc": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  attrs: {
                                    id: "dob",
                                    clearable: "",
                                    readonly: "",
                                    label: "Date Of Birth",
                                    hint: "yyyy/mm/dd",
                                    value: _vm.userLocal.userDetails.dob
                                  },
                                  on: {
                                    "click:append": function($event) {
                                      _vm.datepicker = true
                                    },
                                    "click:prepend": function($event) {
                                      _vm.datepicker = true
                                    },
                                    "click:clear": function($event) {
                                      _vm.userLocal.userDetails.dob = null
                                    }
                                  }
                                },
                                on
                              )
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3472511713
                  ),
                  model: {
                    value: _vm.datepicker,
                    callback: function($$v) {
                      _vm.datepicker = $$v
                    },
                    expression: "datepicker"
                  }
                },
                [
                  _c("v-date-picker", {
                    ref: "picker",
                    attrs: {
                      max: new Date().toISOString().substr(0, 10),
                      color: "primary",
                      width: "290"
                    },
                    on: {
                      change: _vm.saveDate,
                      input: function($event) {
                        _vm.datepicker = false
                      }
                    },
                    model: {
                      value: _vm.userLocal.userDetails.dob,
                      callback: function($$v) {
                        _vm.$set(_vm.userLocal.userDetails, "dob", $$v)
                      },
                      expression: "userLocal.userDetails.dob"
                    }
                  })
                ],
                1
              ),
              _c("v-autocomplete", {
                attrs: {
                  id: "address",
                  "no-filter": true,
                  clearable: "",
                  items: _vm.addressItems,
                  loading: _vm.isLoading,
                  "search-input": _vm.lookupAddress,
                  "item-text": "place_name",
                  label: "Location",
                  placeholder: "Start typing your address",
                  "prepend-icon": "",
                  "append-icon": "",
                  "return-object": "",
                  "auto-select-first": "",
                  hint:
                    "This field is necessary in order to set the correct currency and also to set the coverage area of the service (defined in the profile Section)",
                  "persistent-hint": true
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.lookupAddress = $event
                  },
                  "update:search-input": function($event) {
                    _vm.lookupAddress = $event
                  },
                  change: _vm.getLocation
                },
                model: {
                  value: _vm.address,
                  callback: function($$v) {
                    _vm.address = $$v
                  },
                  expression: "address"
                }
              }),
              _c("v-text-field", {
                attrs: { disabled: "", label: "Address" },
                model: {
                  value: _vm.userLocal.userDetails.address,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "address", $$v)
                  },
                  expression: "userLocal.userDetails.address"
                }
              }),
              _c("v-text-field", {
                attrs: { disabled: "", label: "City" },
                model: {
                  value: _vm.userLocal.userDetails.city,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "city", $$v)
                  },
                  expression: "userLocal.userDetails.city"
                }
              }),
              _c("v-text-field", {
                attrs: { disabled: "", label: "Post Code" },
                model: {
                  value: _vm.userLocal.userDetails.postcode,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "postcode", $$v)
                  },
                  expression: "userLocal.userDetails.postcode"
                }
              }),
              _c("v-text-field", {
                attrs: { disabled: "", label: "Region" },
                model: {
                  value: _vm.userLocal.userDetails.region,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "region", $$v)
                  },
                  expression: "userLocal.userDetails.region"
                }
              }),
              _c("v-text-field", {
                attrs: { disabled: "", label: "Country" },
                model: {
                  value: _vm.userLocal.userDetails.country,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "country", $$v)
                  },
                  expression: "userLocal.userDetails.country"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Phone Number" },
                model: {
                  value: _vm.userLocal.userDetails.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.userLocal.userDetails, "phone", $$v)
                  },
                  expression: "userLocal.userDetails.phone"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "caption" },
            [
              _c("v-icon", [_vm._v("mdi-help-circle-outline")]),
              _vm._v(
                "All these data are for administration purposes, we will not show this information publicly.\\n We use the address to determine the currency and also the default coverage area in the profile area. "
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }