var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("navigation", {
        attrs: {
          "show-drawer":
            _vm.$router.currentRoute.path.includes("/dashboard") ||
            _vm.$router.currentRoute.path.includes("/admin") ||
            _vm.$router.currentRoute.path.includes("/account"),
          drawer: false
        }
      }),
      _c("snackbar", { staticStyle: { "white-space": "break-spaces" } }),
      _c("login", {
        attrs: { show: _vm.$root.showLogin, "close-action": _vm.closeLogin }
      }),
      _c("change-password", {
        attrs: {
          show: _vm.$root.showChangePWD,
          "close-action": _vm.closeChangePassword
        }
      }),
      _c("signup", {
        attrs: {
          chef: _vm.$root.showSignupChef,
          show: _vm.$root.showSignup,
          "close-action": _vm.closeSignup
        }
      }),
      _c("v-main", [_c("router-view")], 1),
      _vm.openContactUs
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.openContactUs,
                callback: function($$v) {
                  _vm.openContactUs = $$v
                },
                expression: "openContactUs"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Contact Us ")
                    ])
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "formContactUs",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.sendMessage()
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Your Email Address",
                                  rules: [
                                    function(v) {
                                      return !!v || "This field is required!"
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.email.sender,
                                  callback: function($$v) {
                                    _vm.$set(_vm.email, "sender", $$v)
                                  },
                                  expression: "email.sender"
                                }
                              }),
                              this.$store.state.subjects
                                ? _c("v-select", {
                                    attrs: {
                                      items: this.$store.state.subjects,
                                      "item-text": "displayName",
                                      "return-object": true,
                                      label: "Subject",
                                      rules: [
                                        function(v) {
                                          return (
                                            (!!v && v.length !== 0) ||
                                            "You must select a Subject!"
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.email.subject,
                                      callback: function($$v) {
                                        _vm.$set(_vm.email, "subject", $$v)
                                      },
                                      expression: "email.subject"
                                    }
                                  })
                                : _vm._e(),
                              _c("v-textarea", {
                                attrs: {
                                  label: "Message",
                                  hint: "Message",
                                  placeholder: "Message",
                                  rules: [
                                    function(v) {
                                      return !!v || "This field is required!"
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.email.message,
                                  callback: function($$v) {
                                    _vm.$set(_vm.email, "message", $$v)
                                  },
                                  expression: "email.message"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function($event) {
                                  _vm.openContactUs = false
                                }
                              }
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: !_vm.valid,
                                type: "submit",
                                loading: _vm.isLoading
                              }
                            },
                            [_vm._v("Send")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.$router.currentRoute.path.includes("/dashboard") &&
      !_vm.$router.currentRoute.path.includes("/admin") &&
      !_vm.$router.currentRoute.path.includes("/account")
        ? _c(
            "v-footer",
            { staticClass: "margin-top-big" },
            [
              _c(
                "v-container",
                { staticClass: "container margin ", attrs: { fuild: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "align-self": "center" },
                          attrs: {
                            icon: "",
                            href:
                              "https://www.facebook.com/Kootto-104830377944163",
                            target: "_blank",
                            "aria-label": "Facebook link"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { "aria-label": "Facebook" } },
                            [_vm._v("mdi-facebook")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "align-self": "center" },
                          attrs: {
                            icon: "",
                            href:
                              "https://www.instagram.com/kootto_privatechef/",
                            target: "_blank",
                            "aria-label": "Instagram link"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { "aria-label": "Instagram" } },
                            [_vm._v("mdi-instagram")]
                          )
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "a",
                        {
                          staticClass: "margin-default text-decoration-none",
                          attrs: { href: "/terms" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$router.push({ path: "/terms" })
                            }
                          }
                        },
                        [_vm._v("Terms")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "margin-default text-decoration-none",
                          attrs: { href: "/privacy" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$router.push({ path: "/privacy" })
                            }
                          }
                        },
                        [_vm._v("Privacy")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "margin-default text-decoration-none",
                          attrs: { href: "/help" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$router.push({ path: "/help" })
                            }
                          }
                        },
                        [_vm._v("Help")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "margin-default text-decoration-none",
                          attrs: { href: "/" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.openContactUs = true
                            }
                          }
                        },
                        [_vm._v("Contact Us")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "x-small",
                            "align-self": "center"
                          }
                        },
                        [
                          _vm._v(
                            "v" +
                              _vm._s(_vm.appVersion) +
                              " - Kootto © " +
                              _vm._s(new Date().getFullYear()) +
                              " " +
                              _vm._s(_vm.env === "prod" ? "" : _vm.env)
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-divider")], 1)
                ],
                1
              ),
              _c(
                "v-container",
                {
                  staticClass: "container margin-top-bottom-big ",
                  attrs: { fuild: "" }
                },
                [
                  _c("v-row", { staticClass: "subtitle-2" }, [
                    _c(
                      "div",
                      { staticClass: "primary--text margin-top-bottom" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: " text-decoration-none",
                            attrs: { href: "/signup" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.$router.push({ path: "/signup" })
                              }
                            }
                          },
                          [_vm._v("Key Features for chefs")]
                        )
                      ]
                    )
                  ]),
                  _c("v-row", { staticClass: "subtitle-2" }, [
                    _c("div", { staticClass: "primary--text" }, [
                      _vm._v("Enjoy our private chef service in all Australia")
                    ])
                  ]),
                  _c(
                    "v-row",
                    _vm._l(_vm.cities, function(n) {
                      return _c(
                        "v-col",
                        {
                          key: n,
                          staticClass: "col-12 col-sm-6 col-md-4 col-lg-3 ",
                          staticStyle: { padding: "2px" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "caption pa-1 text-decoration-none",
                              attrs: { href: "/private-chef/city/" + n },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$router.push({
                                    path: "/private-chef/city/" + n
                                  })
                                }
                              }
                            },
                            [_vm._v("private chef in " + _vm._s(n))]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }