var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            fixed: false,
            absolute: false,
            app: "",
            color: "#383737",
            dark: "",
            dense: ""
          }
        },
        [
          _vm.showDrawer && _vm.$vuetify.breakpoint.xs
            ? _c("v-app-bar-nav-icon", {
                on: {
                  click: function($event) {
                    _vm.drawer = !_vm.drawer
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-img", {
                staticClass: "shrink mr-2",
                attrs: {
                  alt: "Kootto Logo",
                  contain: "",
                  src: require("../assets/icons/Dark_logo_filled.svg"),
                  transition: "scale-transition",
                  width: "40"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ path: "/" })
                  }
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-family": "luxia",
                    "font-weight": "bold",
                    "font-size": "x-large"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ path: "/" })
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$root.appName))]
              )
            ],
            1
          ),
          _c("v-spacer"),
          _vm.$vuetify.breakpoint.xs
            ? _c(
                "v-menu",
                {
                  staticClass: "text-center",
                  attrs: {
                    "aria-label": "Menu",
                    id: "menu",
                    "offset-y": "",
                    absolute: "",
                    "close-on-click": true,
                    "close-on-content-click": false
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-app-bar-nav-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { "aria-label": "Menu" } },
                                  "v-app-bar-nav-icon",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3961607160
                  ),
                  model: {
                    value: _vm.showMenu,
                    callback: function($$v) {
                      _vm.showMenu = $$v
                    },
                    expression: "showMenu"
                  }
                },
                [
                  _c(
                    "v-list",
                    [
                      _vm.loggedin &&
                      !_vm.isAdmin &&
                      !_vm.isChef &&
                      !_vm.$router.currentRoute.path.includes("/account")
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "text-center",
                              attrs: { id: "account" },
                              on: {
                                click: function($event) {
                                  _vm.showMenu = false
                                  _vm.$router.push({
                                    path: "/account/overview"
                                  })
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                                _vm._v("Account")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.loggedin &&
                      _vm.isAdmin &&
                      !_vm.$router.currentRoute.path.includes("/admin")
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "text-center",
                              attrs: { id: "admin" },
                              on: {
                                click: function($event) {
                                  _vm.showMenu = false
                                  _vm.$router.push({ path: "/admin/overview" })
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                                _vm._v("Admin")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.loggedin &&
                      (_vm.isChef || _vm.isAdmin) &&
                      !_vm.$router.currentRoute.path.includes("/dashboard")
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "text-center",
                              attrs: { id: "dasboard" },
                              on: {
                                click: function($event) {
                                  _vm.showMenu = false
                                  _vm.$router.push({
                                    path: "/dashboard/overview"
                                  })
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                                _vm._v("Dashboard")
                              ])
                            ]
                          )
                        : _vm._e(),
                      !_vm.loggedin
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                "!click": function($event) {
                                  _vm.showMenu = false
                                  _vm.openSignup(true)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2 mr-2 pointer",
                                  attrs: { id: "signup_chef" }
                                },
                                [_vm._v("Sign Up as a Chef")]
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.loggedin
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                "!click": function($event) {
                                  _vm.showMenu = false
                                  _vm.openSignup(false)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2 mr-2",
                                  attrs: { id: "signup" }
                                },
                                [_vm._v("Signup")]
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.loggedin
                        ? _c(
                            "v-list-item",
                            {
                              attrs: { id: "login" },
                              on: {
                                "!click": function($event) {
                                  return _vm.openLogin()
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                                _vm._v("Login")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.loggedin
                        ? _c(
                            "v-list-item",
                            {
                              attrs: { id: "logout" },
                              on: {
                                "!click": function($event) {
                                  _vm.logout()
                                  _vm.showMenu = false
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                                _vm._v("Logout")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.loggedin
                        ? _c(
                            "v-list-item",
                            {
                              attrs: { id: "change_password" },
                              on: {
                                "!click": function($event) {
                                  _vm.$root.showChangePWD = true
                                  _vm.showMenu = false
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                                _vm._v("Change Password")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.loggedin && this.$store.state.user
                        ? _c("v-list-item", { attrs: { id: "email" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "ml-2 mr-2 ",
                                staticStyle: { color: "grey" }
                              },
                              [_vm._v(_vm._s(this.$store.state.user.email))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.xs &&
          _vm.loggedin &&
          !_vm.isAdmin &&
          !_vm.isChef &&
          !_vm.routeIncludesAccount()
            ? _c(
                "div",
                {
                  attrs: { id: "account_overview", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ path: "/account/overview" })
                    }
                  }
                },
                [
                  _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                    _vm._v("Account")
                  ])
                ]
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.xs &&
          _vm.loggedin &&
          _vm.isAdmin &&
          !_vm.routeIncludesAdmin()
            ? _c(
                "div",
                {
                  attrs: { id: "admin_overview", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ path: "/admin/overview" })
                    }
                  }
                },
                [
                  _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                    _vm._v("Admin")
                  ])
                ]
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.xs &&
          _vm.loggedin &&
          (_vm.isChef || _vm.isAdmin) &&
          !_vm.routeIncludesDashboard()
            ? _c(
                "div",
                {
                  attrs: { id: "dasboard_overview", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ path: "/dashboard/overview" })
                    }
                  }
                },
                [
                  _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                    _vm._v("Dashboard")
                  ])
                ]
              )
            : _vm._e(),
          !_vm.loggedin && !_vm.$vuetify.breakpoint.xs
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mr-2 pointer",
                    attrs: { id: "signup_chef" },
                    on: {
                      click: function($event) {
                        return _vm.openSignup(true)
                      }
                    }
                  },
                  [_vm._v("Sign Up as a Chef")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mr-2 pointer",
                    attrs: { id: "signup" },
                    on: {
                      click: function($event) {
                        return _vm.openSignup(false)
                      }
                    }
                  },
                  [_vm._v("Signup")]
                )
              ])
            : _vm._e(),
          !_vm.$vuetify.breakpoint.xs && !_vm.loggedin
            ? _c(
                "div",
                {
                  attrs: { id: "login" },
                  on: {
                    click: function($event) {
                      return _vm.openLogin()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "ml-2 mr-2 pointer" }, [
                    _vm._v("Login")
                  ])
                ]
              )
            : _vm._e(),
          _vm.loggedin && !_vm.$vuetify.breakpoint.xs
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      id: "menu",
                                      "aria-label": "Menu",
                                      text: ""
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-account-circle")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    785518192
                  )
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { id: "logout" }, on: { click: _vm.logout } },
                        [_c("v-list-item-title", [_vm._v("Logout")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: { id: "change_password" },
                          on: {
                            click: function($event) {
                              _vm.$root.showChangePWD = true
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("Change Password")])],
                        1
                      ),
                      _vm.loggedin && this.$store.state.user
                        ? _c("v-list-item", { attrs: { id: "email" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "ml-2 mr-2 ",
                                staticStyle: { color: "grey" }
                              },
                              [_vm._v(_vm._s(this.$store.state.user.email))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.showDrawer &&
      (_vm.isChef || _vm.isAdmin) &&
      _vm.routeIncludesDashboard()
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                id: "dashboard",
                "expand-on-hover": _vm.$vuetify.breakpoint.sm,
                "mini-variant": _vm.$vuetify.breakpoint.sm,
                right: false,
                permanent:
                  _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.mdAndUp,
                "disable-resize-watcher": true,
                app: "",
                dark: ""
              },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                {
                  class: {
                    "warning--text py-0": this.$root.self === false,
                    "py-0": this.$root.self === true
                  },
                  attrs: { dense: "", nav: "" }
                },
                [
                  _vm.loggedin && _vm.user && _vm.user.profile
                    ? _c(
                        "v-list-item",
                        {
                          class: _vm.miniVariant && "px-0",
                          attrs: { "two-line": "" }
                        },
                        [
                          _c("v-list-item-avatar", [
                            _vm.user.profile.photoPath
                              ? _c("img", {
                                  staticStyle: { "object-fit": "cover" },
                                  attrs: {
                                    src:
                                      _vm.user.profile.photoPath +
                                      _vm.user.profile.photoFilename,
                                    alt: _vm.user.profile.profileName
                                  }
                                })
                              : _vm._e()
                          ]),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.user.profile.profileName))
                              ]),
                              _vm.user.profile.status
                                ? _c("v-list-item-subtitle", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.user.profile.status.name !==
                                          "APPROVED"
                                          ? _vm.user.profile.status.displayName
                                          : ""
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _vm._l(_vm.itemsDashboard, function(item) {
                    return _c(
                      "v-list-item",
                      { key: item.title, attrs: { link: "", to: item.link } },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.title))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showDrawer && !_vm.isChef && _vm.routeIncludesAccount()
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                id: "account",
                "expand-on-hover": _vm.$vuetify.breakpoint.sm,
                "mini-variant": _vm.$vuetify.breakpoint.sm,
                right: false,
                permanent:
                  _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.mdAndUp,
                "disable-resize-watcher": true,
                app: "",
                dark: ""
              },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                {
                  class: {
                    "warning--text  py-0": this.$root.self === false,
                    "py-0": this.$root.self === true
                  },
                  attrs: { dense: "", nav: "" }
                },
                [
                  _vm.loggedin && _vm.user && _vm.user.userDetails
                    ? _c(
                        "v-list-item",
                        {
                          class: _vm.miniVariant && "px-0",
                          attrs: { "two-line": "" }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(_vm.user.userDetails.firstname) +
                                    " " +
                                    _vm._s(_vm.user.userDetails.lastname)
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _vm._l(_vm.itemsAccount, function(item) {
                    return _c(
                      "v-list-item",
                      { key: item.title, attrs: { link: "", to: item.link } },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.title))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showDrawer && _vm.isAdmin && _vm.routeIncludesAdmin()
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                id: "admin",
                "expand-on-hover": _vm.$vuetify.breakpoint.sm,
                "mini-variant": _vm.$vuetify.breakpoint.sm,
                right: false,
                permanent:
                  _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.mdAndUp,
                "disable-resize-watcher": true,
                app: "",
                dark: ""
              },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                { staticClass: "py-0", attrs: { dense: "", nav: "" } },
                [
                  _vm.loggedin && _vm.user && _vm.user.profile
                    ? _c("v-list-item", {
                        class: _vm.miniVariant && "px-0",
                        attrs: { "two-line": "" }
                      })
                    : _vm._e(),
                  _c("v-divider"),
                  _vm._l(_vm.itemsAdmin, function(item) {
                    return _c(
                      "v-list-item",
                      { key: item.title, attrs: { link: "", to: item.link } },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.title))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }