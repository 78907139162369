var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.localDish
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "1200px" },
          model: {
            value: _vm.dishDialog,
            callback: function($$v) {
              _vm.dishDialog = $$v
            },
            expression: "dishDialog"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "formDish",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  _vm.validateDish() != true ? false : _vm.edit()
                }
              },
              model: {
                value: _vm.validDish,
                callback: function($$v) {
                  _vm.validDish = $$v
                },
                expression: "validDish"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "headline" }, [_vm._v("Dish")])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "start" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: { "flex-wrap": "nowrap" },
                                      attrs: {
                                        "no-gutters": "",
                                        align: "center",
                                        "align-content": "center"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "dish_description",
                                          label: "Dish description",
                                          type: "text",
                                          rules: [
                                            function(v) {
                                              return (
                                                (!!v && v.length !== 0) ||
                                                "You must enter a description !"
                                              )
                                            }
                                          ],
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.localDish.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.localDish,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "localDish.description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "start" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: { "flex-wrap": "nowrap" },
                                      attrs: {
                                        "no-gutters": "",
                                        align: "center",
                                        "align-content": "center"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        ref: "price",
                                        attrs: {
                                          id: "dish_price",
                                          label: "Price",
                                          type: "number",
                                          rules: [
                                            function(v) {
                                              return (
                                                parseInt(_vm.localDish.price) >=
                                                  0 ||
                                                "You must enter a price !"
                                              )
                                            }
                                          ],
                                          hint:
                                            "The price must include everything (Taxes, buying/preparation/driving/cooking/serving/cleaning time). You can set the price to 0 if you only want to use this item in a package",
                                          "persistent-hint": _vm.helpDishPrice
                                        },
                                        model: {
                                          value: _vm.localDish.price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.localDish,
                                              "price",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "localDish.price"
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", color: "primary" },
                                          on: {
                                            click: function($event) {
                                              _vm.helpDishPrice = !_vm.helpDishPrice
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-help-circle")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: { "flex-wrap": "nowrap" },
                                      attrs: {
                                        "no-gutters": "",
                                        align: "center",
                                        "align-content": "center"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          id: "category",
                                          items: _vm.categories,
                                          "item-text": "name",
                                          "return-object": true,
                                          label: "Category",
                                          rules: [
                                            function(v) {
                                              return (
                                                (!!v && v.length !== 0) ||
                                                "You must select one Category !"
                                              )
                                            }
                                          ],
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.localDish.itemCategory,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.localDish,
                                              "itemCategory",
                                              $$v
                                            )
                                          },
                                          expression: "localDish.itemCategory"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.localDish.itemCategory.pricingType.name ===
                              "UNIT"
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "flex-wrap": "nowrap"
                                          },
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "min",
                                            attrs: {
                                              id: "dish_min",
                                              label: "min",
                                              type: "number",
                                              min: "0",
                                              max: "999",
                                              hint:
                                                "The minimum number of unit to order this item",
                                              "persistent-hint": _vm.helpMinMax
                                            },
                                            model: {
                                              value: _vm.localDish.min,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localDish,
                                                  "min",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "localDish.min"
                                            }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.helpMinMax = !_vm.helpMinMax
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-help-circle")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.localDish.itemCategory.pricingType.name ===
                              "UNIT"
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "flex-wrap": "nowrap"
                                          },
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "max",
                                            attrs: {
                                              id: "dish_max",
                                              label: "max",
                                              type: "number",
                                              min: "0",
                                              max: "999",
                                              hint:
                                                "The maximum number of unit possible to order",
                                              "persistent-hint": _vm.helpMinMax
                                            },
                                            model: {
                                              value: _vm.localDish.max,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localDish,
                                                  "max",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "localDish.max"
                                            }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.helpMinMax = !_vm.helpMinMax
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-help-circle")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.localDish.itemCategory.categoryType.name !==
                          "OTHER"
                            ? _c(
                                "v-row",
                                { attrs: { align: "start" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "flex-wrap": "nowrap"
                                          },
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _c("v-col", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticStyle: {
                                                      "flex-wrap": "nowrap"
                                                    },
                                                    attrs: {
                                                      "no-gutters": "",
                                                      align: "center",
                                                      "align-content": "center"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-input",
                                                      {
                                                        staticClass:
                                                          "input-chip",
                                                        attrs: {
                                                          "persistent-hint":
                                                            _vm.helpIngredients,
                                                          hint:
                                                            "This list of ingredients will be used to deduct the dietary tags! Fill in this section carefully. Animal Product can be : egg, milk, honey, ..."
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "primary--text",
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px"
                                                            },
                                                            attrs: {
                                                              slot: "label"
                                                            },
                                                            slot: "label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "This dish contains :"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-item-group",
                                                          {
                                                            attrs: {
                                                              multiple: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.generateDietaryTags()
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.localDish
                                                                  .ingredientTags,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.localDish,
                                                                  "ingredientTags",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "localDish.ingredientTags"
                                                            }
                                                          },
                                                          _vm._l(
                                                            _vm.ingredientTags,
                                                            function(tag) {
                                                              return _c(
                                                                "v-item",
                                                                {
                                                                  key: tag.name,
                                                                  attrs: {
                                                                    value: tag,
                                                                    color:
                                                                      "primary"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var active =
                                                                            ref.active
                                                                          var toggle =
                                                                            ref.toggle
                                                                          return [
                                                                            _c(
                                                                              "v-chip",
                                                                              {
                                                                                attrs: {
                                                                                  id:
                                                                                    tag.name,
                                                                                  "active-class":
                                                                                    "primary--text",
                                                                                  "input-value": _vm.checkChip(
                                                                                    _vm
                                                                                      .localDish
                                                                                      .ingredientTags,
                                                                                    tag
                                                                                  ),
                                                                                  filter:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: toggle
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      tag.displayName
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.helpIngredients = !_vm.helpIngredients
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-help-circle"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.localDish.dietaryTags
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "padding-top-bottom-default"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              " primary--text",
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Generated Dietary Tags"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.localDish
                                                            .dietaryTags,
                                                          function(tag) {
                                                            return _c(
                                                              "v-chip",
                                                              {
                                                                key: tag.name,
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary ",
                                                                  id: tag.name
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    tag.displayName
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "flex-wrap": "nowrap"
                                          },
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "primary--text",
                                                  staticStyle: {
                                                    "font-size": "12px"
                                                  }
                                                },
                                                [_vm._v("Style Tags")]
                                              ),
                                              _c(
                                                "v-item-group",
                                                {
                                                  attrs: { multiple: "" },
                                                  model: {
                                                    value:
                                                      _vm.localDish.styleTags,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.localDish,
                                                        "styleTags",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "localDish.styleTags"
                                                  }
                                                },
                                                _vm._l(_vm.styleTags, function(
                                                  tag
                                                ) {
                                                  return _c("v-item", {
                                                    key: tag.name,
                                                    attrs: {
                                                      value: tag,
                                                      color: "primary"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var active =
                                                              ref.active
                                                            var toggle =
                                                              ref.toggle
                                                            return [
                                                              _c(
                                                                "v-chip",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      tag.name,
                                                                    "active-class":
                                                                      "primary--text",
                                                                    "input-value": _vm.checkChip(
                                                                      _vm
                                                                        .localDish
                                                                        .styleTags,
                                                                      tag
                                                                    ),
                                                                    filter: ""
                                                                  },
                                                                  on: {
                                                                    click: toggle
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        tag.displayName
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.localDish.itemCategory.categoryType.name !==
                          "OTHER"
                            ? _c(
                                "v-row",
                                { attrs: { align: "start" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "flex-wrap": "nowrap"
                                          },
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _vm.service.serviceType.name !==
                                          "FOOD_TRUCK"
                                            ? _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "12px"
                                                      }
                                                    },
                                                    [_vm._v("Equipment Tags")]
                                                  ),
                                                  _c(
                                                    "v-item-group",
                                                    {
                                                      attrs: { multiple: "" },
                                                      model: {
                                                        value:
                                                          _vm.localDish
                                                            .equipments,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.localDish,
                                                            "equipments",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "localDish.equipments"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.equipments,
                                                      function(tag) {
                                                        return _c("v-item", {
                                                          key: tag.name,
                                                          attrs: {
                                                            value: tag,
                                                            color: "primary"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var active =
                                                                    ref.active
                                                                  var toggle =
                                                                    ref.toggle
                                                                  return [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            tag.name,
                                                                          "active-class":
                                                                            "primary--text",
                                                                          "input-value": _vm.checkChip(
                                                                            _vm
                                                                              .localDish
                                                                              .equipments,
                                                                            tag
                                                                          ),
                                                                          filter:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: toggle
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              tag.displayName
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { id: "btn_dish_close", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeAction()
                            }
                          }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "btn_dish_action",
                            type: "submit",
                            color: "primary",
                            disabled: !_vm.validDish
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.localDish.edit === true ? "Edit" : "Add")
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }