var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "margin-bottom" },
            [_c("h2", [_vm._v("Documents")]), _c("v-spacer")],
            1
          ),
          _vm.userLocal.documents !== undefined
            ? _c(
                "div",
                _vm._l(_vm.userLocal.documents, function(item) {
                  return _c(
                    "v-row",
                    { key: item.id },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "5" } }, [
                        _vm._v(
                          " " + _vm._s(item.documentType.displayName) + " "
                        )
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "5" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "btn_download",
                                icon: "",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openLink(
                                    "/api/user/document/" +
                                      _vm.user.id +
                                      "/" +
                                      item.filename
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-download")])],
                            1
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/api/user/document/" +
                                  _vm.user.id +
                                  "/" +
                                  item.filename,
                                target: "_blank"
                              }
                            },
                            [_vm._v("Open (" + _vm._s(item.filename) + ")")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2", align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "btn_delete",
                                outlined: "",
                                small: "",
                                disabled:
                                  _vm.userLocal.profile != undefined &&
                                  _vm.userLocal.profile.status != undefined &&
                                  _vm.userLocal.profile.status.name ===
                                    "APPROVED",
                                loading: item.loadingDelete
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteDocument(item)
                                }
                              }
                            },
                            [_vm._v("Delete")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "row", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "5" } },
                    [
                      _vm.documentTypes
                        ? _c("v-select", {
                            attrs: {
                              id: "type",
                              items: _vm.documentTypes,
                              "item-text": "displayName",
                              "item-value": "name",
                              label: "Document",
                              rules: [
                                function(v) {
                                  return (
                                    (!!v && v.length !== 0) ||
                                    "You must select one Document Type!"
                                  )
                                }
                              ],
                              required: ""
                            },
                            model: {
                              value: _vm.document.type,
                              callback: function($$v) {
                                _vm.$set(_vm.document, "type", $$v)
                              },
                              expression: "document.type"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "5" } },
                    [
                      _c("v-file-input", {
                        attrs: {
                          id: "file",
                          label: "File input",
                          "show-size": true,
                          accept: "image/*,.pdf,.doc,.docx",
                          rules: _vm.inputFileRule,
                          counter: ""
                        },
                        model: {
                          value: _vm.document.file,
                          callback: function($$v) {
                            _vm.$set(_vm.document, "file", $$v)
                          },
                          expression: "document.file"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "btn_upload",
                            outlined: "",
                            small: "",
                            disabled: !_vm.valid,
                            loading: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              return _vm.upload()
                            }
                          }
                        },
                        [_vm._v("Upload")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "caption" },
            [
              _c("v-icon", [_vm._v("mdi-help-circle-outline")]),
              _vm._v(" For security reason we ask for an Id document")
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }