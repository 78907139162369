import { render, staticRenderFns } from "./SignupChef.vue?vue&type=template&id=faa4a090&"
import script from "./SignupChef.vue?vue&type=script&lang=js&"
export * from "./SignupChef.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VCard,VCardText,VCol,VContainer,VImg,VRow,VTimeline,VTimelineItem})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Dev\\projects\\kootto\\kootto-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('faa4a090')) {
      api.createRecord('faa4a090', component.options)
    } else {
      api.reload('faa4a090', component.options)
    }
    module.hot.accept("./SignupChef.vue?vue&type=template&id=faa4a090&", function () {
      api.rerender('faa4a090', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/SignupChef.vue"
export default component.exports