var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "margin-bottom" },
        [_c("h2", [_vm._v("Overview")]), _c("v-spacer")],
        1
      ),
      _c("v-divider", { staticClass: "margin-top-bottom" }),
      _c(
        "v-row",
        [
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#b3efcb" },
              attrs: { id: "confirmed" },
              on: {
                click: function($event) {
                  return _vm.goToBookings("CONFIRMED")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.confirmations))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Booking")
              ])
            ]
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "margin-top-bottom" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }