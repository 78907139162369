var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h2", [_vm._v("Chefs")]),
      _c(
        "v-row",
        {
          staticClass: "no-margin-top-bottom",
          staticStyle: { "align-items": "baseline" }
        },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "no-margin-top-bottom",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  "hide-details": "",
                  dense: ""
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchChefs($event)
                  }
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _vm.$store.state.statusProfile
                ? _c("v-select", {
                    staticClass: "no-margin-top-bottom",
                    attrs: {
                      id: "status",
                      items: _vm.$store.state.statusProfile,
                      "item-text": "displayName",
                      "return-object": true,
                      label: "Status",
                      dense: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "margin-left-right-default",
              attrs: { align: "center", outlined: "" },
              on: { click: _vm.searchChefs }
            },
            [_vm._v("Search")]
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 pointer",
        attrs: {
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.chefs,
          "multi-sort": true,
          "hide-default-footer": false,
          expanded: _vm.expanded,
          "item-key": "id",
          "show-expand": false,
          "server-items-length": _vm.totalItems,
          options: _vm.options,
          "footer-props": { "items-per-page-options": [20, 30, 40, 50] }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          },
          "click:row": _vm.openChef
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-col",
                  { staticStyle: { padding: "0px" } },
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" } },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openChef(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-pencil ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: item.loading,
                          disabled: item.loading,
                          icon: ""
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteUser(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-delete ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "text--secondary" }, [
        _vm._v("Click on a row to see the chef")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }