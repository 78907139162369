var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chef
    ? _c(
        "div",
        { staticClass: "menu" },
        [
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.gallery,
                callback: function($$v) {
                  _vm.gallery = $$v
                },
                expression: "gallery"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.gallery = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "gallery" },
                    _vm._l(_vm.chef.photos, function(src, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "pic",
                          on: {
                            click: function($event) {
                              _vm.photoDetails = true
                              _vm.photoIndex = index
                              _vm.$log(_vm.photoIndex)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "gallery-pic",
                            attrs: { src: src.photoPath + src.photoFilename }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                "content-class": "caroussel-dialog-fullscreen",
                "hide-overlay": ""
              },
              model: {
                value: _vm.photoDetails,
                callback: function($$v) {
                  _vm.photoDetails = $$v
                },
                expression: "photoDetails"
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { position: "absolute", "z-index": "1" },
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.photoDetails = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _vm.chef
                ? _c(
                    "v-carousel",
                    {
                      staticClass: "caroussel-fullscreen",
                      attrs: {
                        "show-arrows": "",
                        "hide-delimiters": "",
                        height: "100%"
                      },
                      model: {
                        value: _vm.photoIndex,
                        callback: function($$v) {
                          _vm.photoIndex = $$v
                        },
                        expression: "photoIndex"
                      }
                    },
                    _vm._l(_vm.chef.photos, function(photo, index) {
                      return _c("v-carousel-item", {
                        key: index,
                        attrs: {
                          contain: "",
                          src: photo.photoPath + photo.photoFilename
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.chef
            ? _c(
                "v-carousel",
                {
                  staticClass: "caroussel",
                  attrs: {
                    "show-arrows": "",
                    "hide-delimiters": "",
                    height: "250px"
                  }
                },
                [
                  _vm._l(_vm.chef.photos, function(photo, index) {
                    return _c("v-carousel-item", {
                      key: index,
                      attrs: { src: photo.photoPath + photo.photoFilename },
                      on: {
                        click: function($event) {
                          _vm.gallery = true
                        }
                      }
                    })
                  }),
                  _c(
                    "v-row",
                    {
                      staticClass: "chefSearchBottom ",
                      staticStyle: { padding: "0px" },
                      attrs: { align: "end", justify: "end", "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-black-30",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  _vm.gallery = true
                                }
                              }
                            },
                            [_vm._v(" Gallery ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-container",
            { staticStyle: { "margin-top": "-48px" }, attrs: { fuild: "" } },
            [
              _vm.chef
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "margin-bottom margin-left-right-default"
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "col-auto",
                              attrs: { align: "start" }
                            },
                            [
                              _vm.chef.profile.photoPath &&
                              _vm.chef.profile.photoFilename
                                ? _c("v-avatar", { attrs: { size: "124" } }, [
                                    _c("img", {
                                      staticStyle: { "object-fit": "cover" },
                                      attrs: {
                                        src:
                                          _vm.chef.profile.photoPath +
                                          _vm.chef.profile.photoFilename,
                                        alt: _vm.chef.profile.profileName
                                      }
                                    })
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                "align-self": "center",
                                justify: "start"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row",
                                  staticStyle: {
                                    "white-space": "nowrap",
                                    "font-size": "x-large"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.chef.profile.profileName) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row",
                                  staticStyle: {
                                    "white-space": "nowrap",
                                    "font-size": "large"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.chef.location) + " ")]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "col-auto",
                              attrs: { align: "end", "align-self": "center" }
                            },
                            [
                              _c("star-rating", {
                                attrs: {
                                  "read-only": "",
                                  increment: 0.5,
                                  "star-size": 35,
                                  align: "end",
                                  "align-self": "center",
                                  inline: ""
                                },
                                model: {
                                  value: _vm.rating,
                                  callback: function($$v) {
                                    _vm.rating = $$v
                                  },
                                  expression: "rating"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass:
                          "margin-left-right-default margin-top-bottom"
                      }),
                      _vm.chef && _vm.aboutChef
                        ? _c("about-chef", { attrs: { chef: _vm.chef } })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-container",
            [_c("services", { attrs: { chefselected: _vm.chef } })],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "" } },
                [
                  _vm.events.length === 0
                    ? _c("div", [_vm._v("No Availability")])
                    : _c("div", [_vm._v("Availability")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.calendar.prev()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _vm.$refs.calendar
                    ? _c(
                        "v-toolbar-title",
                        {
                          staticClass: "no-margin-padding",
                          staticStyle: {
                            display: "inline",
                            "padding-left": "0px"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.calendar.next()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "margin-left-right-default",
                      staticStyle: { padding: "2px 5px 2px 5px" }
                    },
                    [_vm._v("Legend: ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "confirmed margin-left-right-default",
                      staticStyle: { padding: "2px 5px 2px 5px" }
                    },
                    [_vm._v("Available")]
                  )
                ],
                1
              ),
              _c("v-calendar", {
                ref: "calendar",
                staticClass: "test",
                attrs: {
                  weekdays: _vm.weekday,
                  events: _vm.events,
                  "event-color": _vm.getEventColor,
                  "event-text-color": _vm.getEventTextColor,
                  type: "month",
                  "event-overlap-mode": "column",
                  "event-overlap-threshold": 30,
                  color: "primary"
                },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          ),
          _vm.chef
            ? _c(
                "v-container",
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        fullscreen: "",
                        "hide-overlay": "",
                        transition: "dialog-bottom-transition"
                      },
                      model: {
                        value: _vm.gallery,
                        callback: function($$v) {
                          _vm.gallery = $$v
                        },
                        expression: "gallery"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.gallery = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "gallery" },
                            _vm._l(_vm.chef.photos, function(photo, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "pic",
                                  on: {
                                    click: function($event) {
                                      _vm.photoDetails = true
                                      _vm.photoIndex = index
                                      _vm.$log(_vm.photoIndex)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "gallery-pic",
                                    attrs: {
                                      src:
                                        photo.photoPath +
                                        "medium." +
                                        photo.photoFilename
                                    }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        fullscreen: "",
                        "content-class": "caroussel-dialog-fullscreen",
                        "hide-overlay": ""
                      },
                      model: {
                        value: _vm.photoDetails,
                        callback: function($$v) {
                          _vm.photoDetails = $$v
                        },
                        expression: "photoDetails"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { position: "absolute", "z-index": "1" },
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              _vm.photoDetails = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        "v-carousel",
                        {
                          staticClass: "caroussel-fullscreen",
                          attrs: {
                            "show-arrows": "",
                            "hide-delimiters": "",
                            height: "100%"
                          },
                          model: {
                            value: _vm.photoIndex,
                            callback: function($$v) {
                              _vm.photoIndex = $$v
                            },
                            expression: "photoIndex"
                          }
                        },
                        _vm._l(_vm.chef.photos, function(photo, index) {
                          return _c("v-carousel-item", {
                            key: index,
                            attrs: {
                              contain: "",
                              src:
                                photo.photoPath + "high." + photo.photoFilename
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-carousel",
                    {
                      staticClass: "caroussel",
                      staticStyle: { "margin-top": "12px" },
                      attrs: {
                        "show-arrows": "",
                        "hide-delimiters": "",
                        height: "250px"
                      }
                    },
                    [
                      _vm._l(_vm.chef.photos, function(item, i) {
                        return _c("v-carousel-item", {
                          key: i,
                          attrs: {
                            src: item.photoPath + "medium." + item.photoFilename
                          }
                        })
                      }),
                      _c(
                        "v-row",
                        {
                          staticClass: "rowGalleryMenuOverlay ",
                          staticStyle: { padding: "0px" },
                          attrs: {
                            align: "end",
                            justify: "end",
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-black-30",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.gallery = true
                                    }
                                  }
                                },
                                [_vm._v(" Gallery ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticStyle: { height: "0px" } },
          [
            _vm.isLoading
              ? _c("v-progress-linear", {
                  attrs: { indeterminate: "", height: "6" }
                })
              : _vm._e(),
            _vm.isLoading
              ? _c("h1", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("We are loading the profile please wait ...")
                ])
              : _vm._e()
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }