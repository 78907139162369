var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h2", [_vm._v("Overview Admin")]),
      _c(
        "v-row",
        [
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#bfe9fc" },
              attrs: { id: "pending" },
              on: {
                click: function($event) {
                  return _vm.goToChefs("PENDING")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.pendings))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Pendings")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#bfe9fc" },
              attrs: { id: "pending" },
              on: {
                click: function($event) {
                  return _vm.goToChefs("PENDING")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.overpaids))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Overpaid")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#bfe9fc" },
              attrs: { id: "pending" },
              on: {
                click: function($event) {
                  return _vm.goToChefs("PENDING")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.underpaids))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Underpaid")
              ])
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "margin-default padding",
              staticStyle: { background: "#bfe9fc" },
              attrs: { id: "pending" },
              on: {
                click: function($event) {
                  return _vm.goToChefs("PENDING")
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline",
                    "font-size": "55px",
                    "line-height": "55px"
                  }
                },
                [_vm._v(_vm._s(_vm.unpaids))]
              ),
              _c("div", { staticStyle: { display: "inline" } }, [
                _vm._v("Unpaid")
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }