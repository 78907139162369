var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticStyle: { "margin-left": "0px", "margin-right": "0px" } },
    [
      _vm.$vuetify.breakpoint.smAndUp ? _c("v-col") : _vm._e(),
      _vm.chefselected
        ? _c(
            "v-col",
            { attrs: { xs: "12", sm: "10" } },
            [
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "theme--dark",
                      staticStyle: {
                        color: "white",
                        "margin-left": "0px",
                        "margin-right": "0px"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "start", justify: "center" } },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "align-content": "center",
                                align: "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "col-auto",
                                  attrs: { align: "start" }
                                },
                                [
                                  _vm.chefselected.profile.photoPath &&
                                  _vm.chefselected.profile.photoFilename
                                    ? _c(
                                        "v-avatar",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { size: "64" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.aboutChef.scrollIntoView(
                                                { behavior: "smooth" }
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              "object-fit": "cover"
                                            },
                                            attrs: {
                                              src:
                                                _vm.chefselected.profile
                                                  .photoPath +
                                                "low." +
                                                _vm.chefselected.profile
                                                  .photoFilename,
                                              alt:
                                                _vm.chefselected.profile
                                                  .profileName
                                            }
                                          })
                                        ]
                                      )
                                    : _c(
                                        "v-avatar",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                            "background-color": "white"
                                          },
                                          attrs: { align: "start" }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              "object-fit": "cover"
                                            },
                                            attrs: {
                                              src: require("../assets/logo.png")
                                            }
                                          })
                                        ]
                                      )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "padding-top": "0px",
                                    "padding-bottom": "0px",
                                    cursor: "pointer"
                                  },
                                  attrs: { "align-self": "center" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.aboutChef.scrollIntoView(
                                        { behavior: "smooth" }
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-row",
                                      staticStyle: {
                                        color: "white",
                                        "white-space": "nowrap",
                                        "font-size": "x-large"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.chefselected.profile.profileName
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        " v-application primary--text flex-row",
                                      staticStyle: {
                                        "text-decoration": "underline",
                                        "white-space": "nowrap",
                                        "font-size": "small"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.aboutChef.scrollIntoView(
                                            { behavior: "smooth" }
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" about the chef ")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "auto",
                            align: "end",
                            "align-self": "center"
                          }
                        },
                        [
                          _c("star-rating", {
                            attrs: {
                              "read-only": "",
                              increment: 0.5,
                              "star-size": 15,
                              align: "end",
                              "align-self": "center",
                              inline: ""
                            },
                            model: {
                              value: _vm.rating,
                              callback: function($$v) {
                                _vm.rating = $$v
                              },
                              expression: "rating"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("services", {
                    attrs: {
                      chefselected: _vm.chefselected,
                      "first-service": _vm.serviceOrdered[0]
                    }
                  })
                ],
                1
              ),
              _c("v-divider", {
                staticStyle: { "margin-top": "12px", "margin-bottom": "12px" },
                attrs: { dark: "" }
              }),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    "hide-overlay": "",
                    transition: "dialog-bottom-transition"
                  },
                  model: {
                    value: _vm.gallery,
                    callback: function($$v) {
                      _vm.gallery = $$v
                    },
                    expression: "gallery"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.gallery = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "gallery" },
                        _vm._l(_vm.chefselected.photos, function(photo, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "pic",
                              on: {
                                click: function($event) {
                                  _vm.photoDetails = true
                                  _vm.photoIndex = index
                                  _vm.$log(_vm.photoIndex)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "gallery-pic",
                                attrs: {
                                  src:
                                    photo.photoPath +
                                    "medium." +
                                    photo.photoFilename
                                }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    "content-class": "caroussel-dialog-fullscreen",
                    "hide-overlay": ""
                  },
                  model: {
                    value: _vm.photoDetails,
                    callback: function($$v) {
                      _vm.photoDetails = $$v
                    },
                    expression: "photoDetails"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { position: "absolute", "z-index": "1" },
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.photoDetails = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _vm.chefselected
                    ? _c(
                        "v-carousel",
                        {
                          staticClass: "caroussel-fullscreen",
                          attrs: {
                            "show-arrows": "",
                            "hide-delimiters": "",
                            height: "100%"
                          },
                          model: {
                            value: _vm.photoIndex,
                            callback: function($$v) {
                              _vm.photoIndex = $$v
                            },
                            expression: "photoIndex"
                          }
                        },
                        _vm._l(_vm.chefselected.photos, function(photo, index) {
                          return _c("v-carousel-item", {
                            key: index,
                            attrs: {
                              contain: "",
                              src:
                                photo.photoPath + "high." + photo.photoFilename
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-carousel",
                {
                  staticClass: "caroussel",
                  staticStyle: { "margin-top": "12px" },
                  attrs: {
                    "show-arrows": "",
                    "hide-delimiters": "",
                    height: "250px"
                  }
                },
                [
                  _vm._l(_vm.chefselected.photos, function(item, i) {
                    return _c("v-carousel-item", {
                      key: i,
                      attrs: {
                        src: item.photoPath + "medium." + item.photoFilename
                      }
                    })
                  }),
                  _c(
                    "v-row",
                    {
                      staticClass: "rowGalleryMenuOverlay ",
                      staticStyle: { padding: "0px" },
                      attrs: { align: "end", justify: "end", "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-black-30",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  _vm.gallery = true
                                }
                              }
                            },
                            [_vm._v(" Gallery ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "div",
                {
                  ref: "aboutChef",
                  staticStyle: { color: "white" },
                  attrs: { id: "aboutChef" }
                },
                [_c("about-chef", { attrs: { chef: _vm.chefselected } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndUp ? _c("v-col") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }