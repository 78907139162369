var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chefSearch" },
    [
      _c(
        "v-card",
        { staticClass: "chefCard" },
        [
          _c(
            "v-carousel",
            {
              staticClass: "caroussel",
              attrs: {
                "show-arrows": _vm.servicePhotos.length > 0,
                "hide-delimiters": "",
                height: "250px"
              }
            },
            [
              _vm.servicePhotos.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.servicePhotos, function(item, i) {
                      return _c("v-carousel-item", {
                        key: i,
                        attrs: {
                          src: item.photoPath + "medium." + item.photoFilename
                        }
                      })
                    }),
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-img", {
                        staticStyle: { "object-fit": "cover" },
                        attrs: {
                          src: _vm.servicePhotoDefault,
                          "lazy-src": require("../assets/logo.png")
                        }
                      })
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "container chefSearchTop" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "col-auto",
                          staticStyle: { padding: "0px" },
                          attrs: { align: "start" }
                        },
                        [
                          _vm.chefService.chef.profile &&
                          _vm.chefService.chef.profile.photoPath &&
                          _vm.chefService.chef.profile.photoFilename
                            ? _c(
                                "v-avatar",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { align: "start" }
                                },
                                [
                                  _vm.chefService.chef.profile &&
                                  _vm.chefService.chef.profile.photoPath &&
                                  _vm.chefService.chef.profile.photoFilename
                                    ? _c("img", {
                                        staticStyle: { "object-fit": "cover" },
                                        attrs: {
                                          alt:
                                            _vm.chefService.chef.profile
                                              .profileName,
                                          src:
                                            _vm.chefService.chef.profile
                                              .photoPath +
                                            "low." +
                                            _vm.chefService.chef.profile
                                              .photoFilename,
                                          "lazy-src": "../assets/logo.png"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            : _c(
                                "v-avatar",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "background-color": "white"
                                  },
                                  attrs: { align: "start" }
                                },
                                [
                                  _c("img", {
                                    staticStyle: { "object-fit": "cover" },
                                    attrs: {
                                      src: require("../assets/logo.png")
                                    }
                                  })
                                ]
                              )
                        ],
                        1
                      ),
                      _vm.chefService.chef.profile
                        ? _c(
                            "v-col",
                            {
                              staticStyle: {
                                padding: "0px",
                                "white-space": "nowrap"
                              },
                              attrs: {
                                "align-self": "center",
                                justify: "start",
                                align: "start"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.chefService.chef.profile.profileName
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticStyle: {
                            padding: "0",
                            "text-shadow": "1px 1px rgba(0, 0, 0, .75)"
                          },
                          attrs: { align: "center" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.chefService.serviceType.displayName) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticStyle: {
                            padding: "0",
                            "text-shadow": "1px 1px rgba(0, 0, 0, .75)"
                          },
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-black-30",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("openMenu")
                                }
                              }
                            },
                            [_vm._v("Menu")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "v-row",
            {
              staticStyle: { "text-align": "left" },
              attrs: { "no-gutters": "", wrap: "", align: "center" }
            },
            [
              _c("v-col", { attrs: { justify: "start", cols: "auto" } }, [
                _vm.averagePrice
                  ? _c("div", { staticStyle: { margin: "0 12px 0 12px" } }, [
                      _vm._v("Average dish price: $" + _vm._s(_vm.averagePrice))
                    ])
                  : _vm._e(),
                _vm.lowestPackagePrice
                  ? _c("div", { staticStyle: { margin: "0 12px 0 12px" } }, [
                      _vm._v(
                        "Packages from: $" + _vm._s(_vm.lowestPackagePrice)
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "v-col",
                {
                  staticClass: "margin-default",
                  staticStyle: { "text-align": "end" },
                  attrs: { cols: "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "book ",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "book",
                            _vm.chefService.serviceType,
                            _vm.chefService.chef
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.chefService.available ? "Book" : "Request a Date"
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }