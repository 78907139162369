var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.service && _vm.generateExample
    ? _c(
        "v-card",
        { staticClass: "margin-card-default", attrs: { elevation: "4" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { align: "center", "no-gutters": "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "primary--text margin-right-default",
                      on: {
                        click: function($event) {
                          _vm.expandExample = !_vm.expandExample
                        }
                      }
                    },
                    [_vm._v("Example order")]
                  ),
                  _c("v-select", {
                    attrs: {
                      items: _vm.dishTypesAvailable,
                      "item-text": "name",
                      attach: "",
                      chips: "",
                      label: "Categories to include",
                      multiple: "",
                      color: "#00695C  ",
                      rules: [
                        function(v) {
                          return (
                            (!!v && v.length !== 0) ||
                            "You must select one Category!"
                          )
                        }
                      ],
                      required: "",
                      "return-object": true
                    },
                    model: {
                      value: _vm.dishTypesSelected,
                      callback: function($$v) {
                        _vm.dishTypesSelected = $$v
                      },
                      expression: "dishTypesSelected"
                    }
                  }),
                  _c("v-spacer", {
                    on: {
                      click: function($event) {
                        _vm.expandExample = !_vm.expandExample
                      }
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.expandExample = !_vm.expandExample
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(
                          _vm._s(
                            _vm.expandExample
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.expandExample
                ? _c(
                    "div",
                    [
                      _vm.generateExample
                        ? _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.generateExample.headers,
                              items: _vm.generateExample.orders,
                              "disable-sort": true,
                              "hide-default-footer": true,
                              expanded: _vm.generateExample.expanded,
                              "item-key": "title",
                              "show-expand": ""
                            },
                            on: {
                              "update:expanded": function($event) {
                                return _vm.$set(
                                  _vm.generateExample,
                                  "expanded",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "expanded-item",
                                  fn: function(ref) {
                                    var headers = ref.headers
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "  caption",
                                          staticStyle: {
                                            "white-space": "pre-wrap"
                                          },
                                          attrs: { colspan: headers.length }
                                        },
                                        [
                                          _vm._v(_vm._s(item.details)),
                                          _c("br"),
                                          _vm._v(_vm._s(item.details2))
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1434193608
                            )
                          })
                        : _vm._e(),
                      _vm.generateExample.underMinPrice
                        ? _c("div", { staticClass: "caption" }, [
                            _vm._v("(*) Your minimum service price")
                          ])
                        : _vm._e(),
                      _vm.generateExample.waiter
                        ? _c("div", { staticClass: "caption" }, [
                            _vm._v("(**) Includes the waiter price")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }