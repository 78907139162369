import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=7cbffb3a&scoped=true&"
import script from "./Account.vue?vue&type=script&lang=js&"
export * from "./Account.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cbffb3a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Dev\\projects\\kootto\\kootto-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cbffb3a')) {
      api.createRecord('7cbffb3a', component.options)
    } else {
      api.reload('7cbffb3a', component.options)
    }
    module.hot.accept("./Account.vue?vue&type=template&id=7cbffb3a&scoped=true&", function () {
      api.rerender('7cbffb3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Account.vue"
export default component.exports